export default {
	balance: {
		getGameBalance: { method: 'get', url: 'balance/', auth: true },
		getSendTokensTtl: { method: 'get', url: 'send_tokens/vrs_ttl/', auth: true },
		getSendTokensVrs: { method: 'post', url: 'send_tokens/', auth: true },
		spotTransferGetVrs: { method: 'post', url: 'spot_transfer/', auth: true },
	},
	staking: {
		getStakingCharacters: { method: 'get', url: 'staking/nfts/', auth: true },
		getVrs: { method: 'post', url: 'staking/', auth: true },
		getStakeSkills: { method: 'post', url: 'staking/stake/skill/', auth: true },
		getTierUpVrs: { method: 'post', url: 'tier_up/', auth: true },
		getBalance: { method: 'get', url: 'staking/balance/', auth: true },
		getStakingInfo: { method: 'get', url: 'staking/info/' }
	},
	shop: {
		getShopCharacters: { method: 'get', url: 'marketplace/', auth: true },
		getShopCharacter: { method: 'get', url: 'marketplace/', auth: true },
		buyBoosterGetVrs: { method: 'post', url: 'marketplace/items/buy_booster/', auth: true },
	},
	mint: {
		getVrs: { method: 'get', url: 'mint/', auth: true }
	},
	characters: {
		getDefaults: { method: 'get', url: 'characters/defaults/', auth: true },
		getCharacter: { method: 'get', url: 'characters/defaults/', auth: true },
		getMyCharacters: { method: 'get', url: 'nft/', auth: true },
		getMyCharacter: { method: 'get', url: 'nft/', auth: true },
		statsLevelUp: { method: 'post', url: 'stats/level_up/', auth: true },
		freeTierUpTierUp: { method: 'post', url: 'characters/tier_up/', auth: true }
	},
	auth: {
		login: { method: 'post', url: 'auth/login/' },
		logout: { method: 'post', url: 'auth/logout/', auth: true },
		logoutAll: { method: 'post', url: 'auth/logout_all/', auth: true }
	},
	boosters: {
		get: { method: 'get', url: 'boosters/' },
		rules: { method: 'get', url: 'boosters/rules/' },
		myBoosters: { method: 'get', url: 'user-boosters/', auth: true },
		activateBooster: { method: 'post', url: 'user-boosters/activate/', auth: true }
	},
	events: {
		getInfoBanner: { method: 'get', url: 'information-banners/' },
		getEventsBanners: { method: 'get', url: 'game_events/' }
	},
	leaderboards: {
		getLeaderboardNFT: { method: 'get', url: 'leaderboard/nft/', auth: true },
		getLeaderboardWallet: { method: 'get', url: 'leaderboard/wallet/', auth: true },
		getLeaderboardEvents: { method: 'get', url: 'game_events/' },
		getLeaderboardEventTable: { method: 'get', url: 'leaderboard/event/', auth: true }
	},
	stakes: {
		getStakes: { method: 'get', url: 'staking/user_stakes/', auth: true }
	}
}
