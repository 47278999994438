<template>
  <div class="fonts-loader">
    <div
      v-for="(font, i) in fonts"
      :key="i"
      :style="{ 'font-family': font.fontFamily }"
    >
      <div
        v-for="(weight, j) in font.weights"
        :key="j"
        :style="{ 'font-weight': weight}"
      >{{ font }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FontsLoader',
  data() {
    return {
      fonts: [
        {
          fontFamily: 'Tektur',
          weights: [ 400, 500, 600, 700, 800, 900 ],
        },
        {
          fontFamily: 'Raleway',
          weights: [ 400, 800, 900 ],
        },
      ],
    }
  },

  async mounted() {
    document.fonts.ready.then(() => this.$emit('onload'))
  },
}

</script>

<style lang="scss">

.fonts-loader {
  position: fixed;
  left: 100%;
  bottom: 100%;
  opacity: 0;
}

</style>
