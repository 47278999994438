var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "portal",
    { attrs: { to: "main-popup" } },
    [
      _c(
        "popup",
        {
          attrs: { "content-class": "approve-popup" },
          on: { input: _vm.close },
          model: {
            value: _vm.popup,
            callback: function ($$v) {
              _vm.popup = $$v
            },
            expression: "popup",
          },
        },
        [
          _c(
            "div",
            [
              _vm.loading
                ? _c("preloader", {
                    attrs: {
                      dark: "",
                      text: "Wait for the network to confirm the transaction",
                    },
                  })
                : _vm._e(),
              _c("div", { staticClass: "approve-popup__content" }, [
                _c("div", { staticClass: "approve-popup__desc" }, [
                  _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } }),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "approve-popup__buttons" },
                [
                  _c("btn", {
                    staticClass: "approve-popup__buttons-item",
                    attrs: {
                      type:
                        _vm.currentAllowance >= _vm.amount
                          ? "mint-dark"
                          : "mint",
                      text: "APPROVE",
                      disabled: _vm.currentAllowance >= _vm.amount,
                    },
                    on: { click: _vm.onClickApprove },
                  }),
                  _c("btn", {
                    staticClass: "approve-popup__buttons-item",
                    attrs: {
                      type: "mint-dark",
                      text: _vm.actionBtnText,
                      disabled: _vm.currentAllowance < _vm.amount,
                    },
                    on: { click: _vm.onActionBtnClick },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }