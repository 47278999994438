<template>
    <transition name="fade" mode="out-in">
        <div
            v-show="isSocialsVisible"
            class="socials"
            :class="{
                [`socials--${type}`]: type,
                'socials--background': type === 'fixed' && isBackground,
            }"
        >
            <a
                v-for="(social, i) in socials"
                :key="i"
                :href="social.link"
                target="_blank"
                class="socials__link"
                data-aos="fade-up"
                :data-aos-delay="i * 100"
                data-aos-offset="0"
            >
                <img
                    :src="require(`@/assets/img/icons/${social.icon}.png`)"
                    alt="social icon"
                    class="socials__icon"
                >
            </a>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Socials",
    props: {
        type: { type: String, default: 'cubic' },
    },
    data() {
        return {
            isSocialsVisible: true,
            isBackground: false,
            socials: [
                {
                    icon: 'tw',
                    link: 'https://twitter.com/MetaFighter_NFT',
                },
                {
                    icon: 'yt',
                    link: 'https://www.youtube.com/channel/UCUTWg6UO9PT2Za9DEYtt98A/featured',
                },
                {
                    icon: 'twitch',
                    link: 'https://www.twitch.tv/metafighter_nft',
                },
                {
                    icon: 'telegram',
                    link: 'https://t.me/metafighter_official',
                },
                {
                    icon: 'discord',
                    link: 'https://discord.com/invite/metafighter',
                },
                {
                    icon: 'medium',
                    link: 'https://medium.com/@metafighter/',
                },                
            ],
        }
    },
    mounted() {
        if (this.type === 'fixed') {
            window.addEventListener('scroll', this.handleScroll);
        }
    },
    beforeDestroy() {
        if (this.type === 'fixed') {
            window.removeEventListener('scroll', this.handleScroll);
        }
    },
    methods: {
        handleScroll() {
            const footer = document.getElementsByClassName('footer')[0];

            if (!footer) {
                return;
            }

            if ((window.innerHeight + window.scrollY) >= footer.offsetTop) {
                this.isSocialsVisible = false;
            } else {
                this.isSocialsVisible = true;
            }

            this.isBackground = window.scrollY > 110;
        }
    }
}
</script>

<style lang="scss">

.socials {
  display: flex;
  align-items: center;
}
.socials__link {
  margin-right: 10px;

  transition: 300ms;

  &:last-child {
    margin-right: 0;
  }
}

.socials--cubic .socials__link {
  position: relative;

  width: 59px;
  height: 51px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid;

  color: #fff;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;

    border: 5px solid;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }

  &:hover {
    filter: brightness(0.25);
  }
}

.socials--tiny .socials__link {
  margin-right: 20px;

  filter: brightness(0.5);

  &:hover {
    filter: brightness(1);
  }

  &:last-child {
    margin-right: 0;
  }
}

.socials__icon {
  max-width: 21px;
  max-height: 23px;
  width: auto;
  height: auto;
}

.socials--fixed {
    position: fixed;
    right: 0;
    top: 35%;
    flex-direction: column;
    gap: 2em;
    z-index: 4;
    padding: 1.8em 1em;
    transition: background-color 0.25s;

    @media screen and (max-width: $bp_mb) {
        display: none;
    }
}

.socials--background {
    background-color: #2d303f6e;
}

.socials--fixed .socials__link {
    margin: auto;

    &:hover {
        filter: brightness(0.25);
    }
}

</style>
