import {ethers} from "ethers";
import Vesting from "@/utils/smartContract/Vesting.json";
import MFToken from "@/utils/smartContract/MFToken.json";
import Web3 from "web3";
import {EthereumProvider as WalletConnectProvider} from "@walletconnect/ethereum-provider";

//import { provider } from "@/utils/providerOptions";
const {ethereum} = window;

// const options = { rpc: { 1:'http://-' } }
// const networkInt = parseInt(process.env.VUE_APP_NETWORK_ID_INT)
// options.rpc[networkInt] = process.env.VUE_APP_NETWORK_RPC
// const provider = new WalletConnectProvider(options);

export default {
    state: {
        networkId: process.env.VUE_APP_NETWORK_ID,
        account: null,
        error: null,
        contract_address: process.env.VUE_APP_CONTRACT_ADDRESS,
        mftoken: process.env.VUE_APP_TOKEN_ADDRESS,
        isLoggedIn: false,
        balance: 0,
        loading: false,
        investorArray: [],
        networkInt: parseInt(process.env.VUE_APP_NETWORK_ID_INT),
        networkRpc: process.env.VUE_APP_NETWORK_RPC,
        metamaskconnect: false,
        walletconnect: false
    },
    getters: {
        account: (state) => state.account,
        isLoggedIn: (state, getters, rootState) => !!state.account && !!rootState.auth.token,
        error: (state) => state.error,
        contract: (state) => state.contract_address,
        token: (state) => state.mftoken,
        balance: (state) => state.balance,
        loading: (state) => state.loading,
        investorArray: (state) => state.investorArray,
        metamaskconnect: (state) => state.metamaskconnect,
        walletconnect: (state) => state.walletconnect
    },
    mutations: {
        setAccount(state, account) {
            state.account = account;
        },
        setError(state, error) {
            state.error = error;
        },
        setBalance(state, payload) {
            state.balance = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setInvestorArray(state, payload) {
            state.investorArray = payload
        },
        setMetamaskconnect(state, payload) {
            state.metamaskconnect = payload
        },
        setWalletconnect(state, payload) {
            state.walletconnect = payload
        }

    },
    actions: {
        async connectWallet({commit, dispatch}) {
            try {
                const networkInt = parseInt(process.env.VUE_APP_NETWORK_ID_INT)
                const options = {
                    projectId: process.env.VUE_APP_PROJECT_ID,
                    chains: [networkInt],
                    showQrModal: true
                }

                const provider = await WalletConnectProvider.init(options);
                await provider.disconnect()
                await provider.connect()
                await provider.enable()


                const web3 = new Web3(provider);

                const accounts = await web3.eth.getAccounts()
                if (accounts) {
                    commit("setWalletconnect", true)
                    commit("setMetamaskconnect", false)
                    commit('setAccount', accounts[0])
                    dispatch('getBalance')
                }
            } catch (err) {
                console.log(err)
            }
        },
        async loadPage({commit}, data) {
            try {
                commit("setLoading", data);
                return true;
            } catch (switchError) {
                return false;
            }
        },
        async disconnectWallet({state, commit, dispatch}) {
            try {
                dispatch('auth/logout', null, {root: true});
                commit(state.metamaskconnect ? "setMetamaskconnect" : "setWalletconnect", false)
                commit('setAccount', null)
                localStorage.setItem('wc@2:client:0.3//session', JSON.stringify([]));
                window.location.reload()
                return true;
            } catch (switchError) {
                return false;
            }
        },
        async getNetwork() {
            try {
                let web3
                // eslint-disable-next-line no-unused-vars
                const res = await web3.eth.request({
                    method: "wallet_switchEthereumChain",
                    params: [{chainId: "0x4"}],
                });
                return 1;
            } catch (switchError) {
                return 0;
            }
        },
        async networkId({state}) {
            let networkId
            let web3
            if (state.metamaskconnect) {
                networkId = await ethereum.request({method: 'eth_chainId'})
                if (networkId === state.networkId) {
                    return true
                } else {
                    return false
                }
            } else if (state.walletconnect) {
                if (localStorage.getItem('wc@2:client:0.3//session')) {
                    const networkInt = parseInt(process.env.VUE_APP_NETWORK_ID_INT)
                    const options = {
                        projectId: process.env.VUE_APP_PROJECT_ID,
                        chains: [networkInt],
                        showQrModal: false
                    }

                    const provider = await WalletConnectProvider.init(options);
                    await provider.enable()

                    web3 = new Web3(provider);
                }
                networkId = await web3.eth.net.getId()  //currentProvider.wc.chainId
                if (`0x${networkId.toString(16)}` === state.networkId) {
                    return true
                } else {
                    return false
                }
            }
        },
        async getInvestorRoundsArray({state, dispatch}) {
            try {
                const Vesting = await dispatch("getSmartContract");
                let investorArray
                if (state.metamaskconnect) {
                    investorArray = await Vesting.getScheduleArrayByInvestor(state.account)
                } else if (state.walletconnect) {
                    if (localStorage.getItem('wc@2:client:0.3//session')) {
                        const networkInt = parseInt(process.env.VUE_APP_NETWORK_ID_INT)
                        const options = {
                            projectId: process.env.VUE_APP_PROJECT_ID,
                            chains: [networkInt],
                            showQrModal: false
                        }

                        const provider = await WalletConnectProvider.init(options);
                        await provider.enable()
                        // eslint-disable-next-line no-unused-vars
                        let web3 = new Web3(provider)
                    }
                    investorArray = await Vesting.methods.getScheduleArrayByInvestor(state.account).call()
                }
                return investorArray
            } catch (error) {
                console.log(error)
                return {err: error};
            }
        },
        async getBalance({state, commit, dispatch}) {
            try {
                const MFToken = await dispatch("getMFToken");

                let balance
                if (state.metamaskconnect) {
                    const balanceOfInvestor = await MFToken.balanceOf(state.account)
                    const investorBalance = parseFloat(balanceOfInvestor) / 1000000000000000000
                    balance = +investorBalance.toFixed(2)
                } else if (state.walletconnect) {
                    if (localStorage.getItem('wc@2:client:0.3//session')) {

                        const networkInt = parseInt(process.env.VUE_APP_NETWORK_ID_INT)
                        const options = {
                            projectId: process.env.VUE_APP_PROJECT_ID,
                            chains: [networkInt],
                            showQrModal: false
                        }

                        const provider = await WalletConnectProvider.init(options);
                        await provider.enable()


                        // eslint-disable-next-line no-unused-vars
                        const web3 = new Web3(provider);
                    }
                    const balanceOfInvestor = await MFToken.methods.balanceOf(state.account).call()
                    const investorBalance = parseFloat(balanceOfInvestor) / 1000000000000000000
                    balance = +investorBalance.toFixed(2)
                }
                commit("setBalance", balance);
                return balance;
            } catch (error) {
                commit("setBalance", 0);
                console.log("connected contract not found")
                return error;
            }
        },
        async connect({commit, dispatch}) {
            try {
                //if (!(await dispatch("checkIfConnected"))) {
                await ethereum.request({
                    method: 'wallet_requestPermissions', params: [
                        {
                            'eth_accounts': {
                                requiredMethods: ['signTypedData_v4']
                            }
                        }
                    ]
                }).then((permissions) => {
                    const granted = permissions.find(
                        (permission) => permission.parentCapability === 'eth_accounts'
                    );
                    if (granted) {
                        commit("setAccount", granted.caveats[0].value[0]);
                        commit("setWalletconnect", false)
                        commit("setMetamaskconnect", true)
                        return true
                    }
                })
                    .catch((error) => {
                        if (error.code === 4001) {
                            //
                        }
                    })

                // if (process.env.NODE_ENV === 'main') {
                //   dispatch('switchNetwork')
                //   // нормальная функция перехода на BSC Mainnet
                //   // пока отключена
                //   // dispatch('setBinanceSmartChainNetwork')
                // }
                await dispatch('getBalance')
                return true
            } catch (error) {
                console.log(error);
                commit("setError", "Account request refused.");
                return false
            }
        },
        async switchNetwork({state}) {
            try {
                await ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{chainId: state.networkId}],
                });
                return 1;
            } catch (switchError) {
                return 0;
            }
        },
        async setBinanceSmartChainNetwork({state}) {
            try {
                // check if the chain to connect to is installed
                await ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{chainId: state.networkId}],
                });
            } catch (error) {
                if (error.code === 4902) {
                    try {
                        await ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: state.networkId,
                                    rpcUrls: [state.networkRpc],
                                    chainName: 'BSC Mainnet'
                                }
                            ]
                        });
                    } catch (addError) {
                        console.error(addError)
                        return
                    }
                } else {
                    console.error(error)
                    return
                }
            }
        },
        async checkConnection({state, commit, dispatch}) {
            if (state.metamaskconnect) {
                try {
                    const accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
                    if (accounts[0]) {
                        commit("setAccount", accounts[0]);
                    }
                } catch (error) {
                    if (error.code === 4001) {
                        console.log('User rejected metamask connection')
                    } else {
                        console.log(error.message)
                    }

                    dispatch('disconnectWallet')
                }
            } else if (state.walletconnect) {
                const isConnected = await dispatch('checkIfConnected');

                if (!isConnected) {
                    dispatch('disconnectWallet');
                }
            }
        },
        async checkIfConnected({commit, state}) {
            if (state.account) {
                const networkInt = parseInt(process.env.VUE_APP_NETWORK_ID_INT)
                const options = {
                    projectId: process.env.VUE_APP_PROJECT_ID,
                    chains: [networkInt],
                    showQrModal: false
                }

                const provider = await WalletConnectProvider.init(options);
                await provider.enable()

                const web3 = new Web3(provider);

                const accounts = await web3.eth.getAccounts();
                if (accounts.length !== 0) {
                    commit("setAccount", accounts[0]);
                    return true;
                }
            } else {
                return false;
            }
        },
        async checkAccountHas({state}) {
            if (state.account) {
                return true;
            } else {
                return false;
            }
        },
        async requestAccess({commit}) {
            let web3 = new Web3()
            const accounts = await web3.eth.request({
                method: "eth_requestAccounts",
            });
            commit("setAccount", accounts[0]);
        },
        async getSmartContract({state}) {
            try {
                let connectedContract
                let web3
                if (state.metamaskconnect) {
                    const provider = new ethers.providers.Web3Provider(ethereum);
                    const signer = provider.getSigner();
                    connectedContract = new ethers.Contract(
                        state.contract_address,
                        Vesting.abi,
                        signer
                    );
                } else if (state.walletconnect) {
                    if (localStorage.getItem('wc@2:client:0.3//session')) {
                        const networkInt = parseInt(process.env.VUE_APP_NETWORK_ID_INT)
                        const options = {
                            projectId: process.env.VUE_APP_PROJECT_ID,
                            chains: [networkInt],
                            showQrModal: false
                        }

                        const provider = await WalletConnectProvider.init(options);
                        await provider.enable()

                        web3 = new Web3(provider)
                    }
                    connectedContract = new web3.eth.Contract(Vesting.abi, state.contract_address)
                }
                return connectedContract;
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        async getMFToken({state}) {
            try {
                let connectedContract
                let web3
                if (state.metamaskconnect) {
                    const provider = new ethers.providers.Web3Provider(ethereum);
                    const signer = provider.getSigner();
                    connectedContract = new ethers.Contract(
                        state.mftoken,
                        MFToken.abi,
                        signer
                    );
                } else if (state.walletconnect) {
                    if (localStorage.getItem('wc@2:client:0.3//session')) {
                        const networkInt = parseInt(process.env.VUE_APP_NETWORK_ID_INT)
                        const options = {
                            projectId: process.env.VUE_APP_PROJECT_ID,
                            chains: [networkInt],
                            showQrModal: false
                        }

                        const provider = await WalletConnectProvider.init(options);
                        await provider.enable()

                        web3 = new Web3(provider);
                    }


                    connectedContract = new web3.eth.Contract(MFToken.abi, state.mftoken)
                }
                return connectedContract;
            } catch (error) {
                console.log("connected contract not found");
                return error;
            }
        },
    },
}
