// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".app {\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n.app__content {\n  flex-grow: 1;\n}\n.app--top-paddings {\n  padding-top: 110px;\n  background: linear-gradient(180deg, #1F213A 0%, #000000 100%), #1F213A;\n}\n@media screen and (max-width: 1820px) {\n.app--top-paddings {\n    padding-top: 100px;\n}\n}\n@media screen and (max-width: 1679px) {\n.app--top-paddings {\n    padding-top: 90px;\n}\n}\n@media screen and (max-width: 1439px) {\n.app--top-paddings {\n    padding-top: 80px;\n}\n}\n@media screen and (max-width: 1023px) {\n.app--top-paddings {\n    padding-top: 75px;\n}\n}\n@media screen and (max-width: 991px) {\n.app--top-paddings {\n    padding-top: 80px;\n}\n}\n@media screen and (max-width: 639px) {\n.app--top-paddings {\n    padding-top: 60px;\n}\n}\n#WEB3_CONNECT_MODAL_ID {\n  position: relative;\n  z-index: 999;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
