var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popup",
    {
      attrs: { "content-class": "transaction-success-popup" },
      on: { input: _vm.close },
      model: {
        value: _vm.popup,
        callback: function ($$v) {
          _vm.popup = $$v
        },
        expression: "popup",
      },
    },
    [
      _c("div", [
        _c("div", { staticClass: "transaction-success-popup__content" }, [
          _c("img", {
            staticClass: "transaction-success-popup-chackmark",
            attrs: {
              loading: "lazy",
              src: require("@/assets/img/checkmark.svg?inline"),
              alt: "bg",
            },
          }),
          _vm.content.title
            ? _c("div", { staticClass: "transaction-success-popup__title" }, [
                _vm._v(_vm._s(_vm.content.title)),
              ])
            : _vm._e(),
          _vm.content.text
            ? _c("div", { staticClass: "transaction-success-popup__desc" }, [
                _vm.content.isHtml
                  ? _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.content.text) },
                    })
                  : _c("p", [_vm._v(_vm._s(_vm.content.text))]),
                _c("p", [
                  _vm._v(" The transaction hash: "),
                  _c(
                    "span",
                    {
                      staticClass: "hash",
                      on: {
                        click: function ($event) {
                          return _vm.copyLink(_vm.hash)
                        },
                      },
                    },
                    [
                      _vm._v("#" + _vm._s(_vm.hash) + " "),
                      _c("img", {
                        staticClass: "hash__img",
                        attrs: {
                          loading: "lazy",
                          src: require("@/assets/img/claiming/copy.svg?inline"),
                        },
                      }),
                    ]
                  ),
                ]),
              ])
            : _c("div", { staticClass: "transaction-success-popup__desc" }, [
                _vm._v(
                  " The transaction has been successfully created (Hash: "
                ),
                _c(
                  "span",
                  {
                    staticClass: "hash",
                    on: {
                      click: function ($event) {
                        return _vm.copyLink(_vm.hash)
                      },
                    },
                  },
                  [
                    _vm._v("#" + _vm._s(_vm.hash) + " "),
                    _c("img", {
                      staticClass: "hash__img",
                      attrs: {
                        loading: "lazy",
                        src: require("@/assets/img/claiming/copy.svg?inline"),
                      },
                    }),
                  ]
                ),
                _vm._v(") and will be confirmed within a few minutes "),
              ]),
        ]),
        _c(
          "div",
          { staticClass: "transaction-success-popup__buttons" },
          [
            _c("btn", {
              staticClass: "transaction-success-popup__buttons-item",
              attrs: { type: "mint-dark", text: "OK" },
              on: { click: _vm.close },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }