import VESTING_CONTRACT from './Vesting';
import MF_TOKEN_CONTRACT from './MFToken';
import MFNFT_CONTRACT from './MFNFT';
import STAKING_CONTRACT from './Staking';
import SPOT_TOKEN_MANAGEMENT_CONTRACT from './SpotTokenManagement';
import MARKETPLACE_CONTRACT from './Marketplace';
import TOKEN_MANAGEMENT_CONTRACT from "@/service/smart-contract/contracts/TokenManagement";

const CONTRACTS_LIST = [
  VESTING_CONTRACT,
  MF_TOKEN_CONTRACT,
  MFNFT_CONTRACT,
  STAKING_CONTRACT,
  SPOT_TOKEN_MANAGEMENT_CONTRACT,
  MARKETPLACE_CONTRACT,
  TOKEN_MANAGEMENT_CONTRACT,
];

export default CONTRACTS_LIST;
