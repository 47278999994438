// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".preloader {\n  position: absolute;\n  z-index: 999;\n}\n.preloader--fixed {\n  position: fixed;\n}\n.preloader,\n.preloader__bg,\n.preloader__circle {\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n.preloader__bg {\n  position: absolute;\n  background: linear-gradient(to right, #FFC812 20%, #FF9509);\n  z-index: -1;\n}\n.preloader__bg.translucent {\n  opacity: 0.9;\n}\n.preloader__bg.dark {\n  background: rgba(0, 0, 0, 0.75);\n}\n.preloader__bg.black {\n  background: linear-gradient(180deg, #1F213A 0%, #000000 100%), #1F213A;\n}\n.preloader__circle {\n  position: absolute;\n  margin: auto;\n  width: 80px;\n  height: 80px;\n  border: 5px solid #000000;\n  border-radius: 50%;\n  border-right-color: transparent !important;\n  animation: 1s spin infinite linear;\n}\n.preloader__circle.dark {\n  border-color: #fff;\n}\n.preloader__circle.black {\n  border-color: #ffffffb0;\n}\n.preloader__text {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  margin-top: -70px;\n  color: #fff;\n  font-weight: bold;\n  text-transform: uppercase;\n}\n@keyframes spin {\nto {\n    transform: rotate(360deg);\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
