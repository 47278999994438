var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fonts-loader" },
    _vm._l(_vm.fonts, function (font, i) {
      return _c(
        "div",
        { key: i, style: { "font-family": font.fontFamily } },
        _vm._l(font.weights, function (weight, j) {
          return _c("div", { key: j, style: { "font-weight": weight } }, [
            _vm._v(_vm._s(font)),
          ])
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }