<template>
  <portal to="main-popup">
      <popup
          v-model="isShown"
          content-class="error-popup"
          @input="$emit('close')"
      >
          <div>
              <div class="error-popup__title">
                  {{ title }}
              </div>
              <div class="error-popup__content">
                  <div class="error-popup__desc" v-html="errorText" />
              </div>

              <slot />
          </div>
      </popup>
  </portal>
</template>

<script>
export default {
    name: "ErrorPopup",
    props: {
        title: { type: String, default: '' },
        errorText: { type: String, default: '' },
    },
    data() {
      return {
          isShown: false
      }
    },
    mounted() {
        this.isShown = true;
    }
}
</script>

<style lang="scss" scoped>

.error-popup {
  max-width: 570px;
  padding-bottom: 15px;
}
.error-popup__content {
  text-transform: uppercase;
  text-align: center;
  margin-top: 1em;
  text-align: center;
}
.error-popup__title {
  font: 800 21px Raleway;
  margin-top: 1em;
  text-align: center;
}
.error-popup__desc {
  font-size: 14px;
}
.error-popup__slot {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

</style>
