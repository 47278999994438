var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: [
        "button",
        {
          [`button--${_vm.type}`]: _vm.type,
          [`button--has-icon`]: _vm.icon,
          [`button--disabled`]: _vm.isDisabled,
        },
      ],
      attrs: { title: _vm.altText, type: "button" },
      on: {
        mouseover: function ($event) {
          return _vm.hoverHandler(true)
        },
        mouseleave: function ($event) {
          return _vm.hoverHandler(false)
        },
        click: function ($event) {
          $event.stopPropagation()
          return _vm.click.apply(null, arguments)
        },
      },
    },
    [
      _vm.canShowArrows ? _c("i", { staticClass: "button__arrows" }) : _vm._e(),
      _vm.icon
        ? _c("img", {
            staticClass: "button__icon",
            attrs: {
              src: require("@/assets/img/" + _vm.icon),
              alt: "button icon",
            },
          })
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "button__content",
          class: [
            { "button__content--arrow": _vm.arrow },
            { "button__content--flex": _vm.svgIcon },
          ],
        },
        [
          _vm.title
            ? _c(
                "span",
                {
                  staticClass: "button__title",
                  style: { fontSize: `${_vm.titleSize}px` },
                },
                [_vm._v(_vm._s(_vm.title))]
              )
            : _vm._e(),
          !!_vm.hoverText && !_vm.isHovered && !_vm.GET_IS_MOBILE
            ? _c(
                "span",
                {
                  staticClass: "button__text",
                  style: { fontSize: `${_vm.textSize}px` },
                },
                [_vm._v(" " + _vm._s(_vm.hoverText) + " ")]
              )
            : _vm.getText
            ? _c("span", {
                staticClass: "button__text",
                style: { fontSize: `${_vm.textSize}px` },
                domProps: { innerHTML: _vm._s(_vm.getText) },
              })
            : _vm._e(),
          _vm.svgIcon
            ? _c("BaseIcon", { attrs: { name: _vm.svgIcon } })
            : _vm._e(),
        ],
        1
      ),
      _vm.subText
        ? _c(
            "span",
            { staticClass: "button__subtext", style: { fontSize: `${12}px` } },
            [_vm._v(_vm._s(_vm.subText))]
          )
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }