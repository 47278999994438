export default class Characters {
    constructor(params = { count: 0, next: null, previous: null, results: [] }) {
        this.count = params.count;
        this.next = params.next;
        this.previous = params.previous;
        this.results = params.results;
    }

    static produceCharacters(rawData) {
        return new this(rawData);
    }
}
