// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".menu-btn {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 40px;\n  height: 40px;\n  padding: 7px 4px;\n  transition: 0.3s;\n}\n.menu-btn__inner {\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 26px;\n  transition: 0.3s;\n}\n.menu-btn__element {\n  width: 32px;\n  height: 3px;\n  transition: 0.3s;\n}\n.menu-btn.opened .menu-btn__inner {\n  justify-content: center;\n  transform: rotate(45deg);\n}\n.menu-btn.opened .menu-btn__element:nth-of-type(1) {\n  transform: translateY(100%);\n}\n.menu-btn.opened .menu-btn__element:nth-of-type(3) {\n  transform-origin: center center;\n  transform: translateY(-100%) rotate(90deg);\n}\n.menu-btn.opened .menu-btn__element:nth-of-type(2) {\n  opacity: 0;\n}\n.menu-btn--dark .menu-btn__element, .menu-btn--dark.opened .menu-btn__element {\n  background-color: #fff;\n}\n.menu-btn--dark, .menu-btn--dark.opened {\n  background-color: #000;\n}\n@media screen and (max-width: 639px) {\n.menu-btn {\n    width: 28px;\n    height: 28px;\n    padding: 5px 3px;\n}\n.menu-btn__inner {\n    height: 18px;\n}\n.menu-btn__element {\n    width: 22px;\n    height: 2px;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
