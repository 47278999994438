var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "footer" },
    [
      _c("div", { staticClass: "footer__top" }, [
        _c(
          "div",
          { staticClass: "footer__top-inner container" },
          [
            _c("logo", {
              staticClass: "footer__logo",
              on: {
                click: function ($event) {
                  return _vm.goTo("/")
                },
              },
            }),
            _c("div", { staticClass: "footer__buttons" }, [
              !_vm.isLoggedIn
                ? _c(
                    "div",
                    { staticClass: "footer__buttons-item" },
                    [
                      _c("btn", {
                        attrs: { type: "mint-white", text: "CONNECT" },
                        on: { click: _vm.connectToMeta },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("socials", { attrs: { type: "tiny" } }),
          ],
          1
        ),
      ]),
      _vm._m(0),
      _c("MetamaskPopup", { ref: "metamaskPopup" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer__bottom" }, [
      _c("div", { staticClass: "footer__bottom-inner container" }, [
        _c("div", { staticClass: "footer__info" }, [
          _c("div", { staticClass: "footer__info-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Privacy Policy")]),
          ]),
          _c("div", { staticClass: "footer__info-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Terms & Conditions")]),
          ]),
          _c("div", { staticClass: "footer__info-item" }, [
            _vm._v(" Contact us: "),
            _c("a", { attrs: { href: "mailto:contact@metafighter.com" } }, [
              _vm._v("Contact@Metafighter.Com"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "footer__copy" }, [
          _vm._v(" All rights reserved 2022 © "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }