<template>
  <div
    :class="[
        'preloader',
        { 'preloader--fixed': fixed },
    ]"
  >
    <i :class="['preloader__bg', { translucent, dark, black }]" />
    <i :class="['preloader__circle', { dark, black }]" />
    <div class="preloader__text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "Preloader",
  props: {
    fixed: { type: Boolean, default: true },
    translucent: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    text: { type: String, default: '' },
    black: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss">

.preloader {
  position: absolute;
  z-index: 999;

  &--fixed {
    position: fixed;
  }
}

.preloader,
.preloader__bg,
.preloader__circle {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.preloader__bg {
  position: absolute;
  background: linear-gradient(to right, #FFC812 20%, #FF9509);
  z-index: -1;

  &.translucent {
    opacity: .9;
  }
  &.dark {
    background: rgba(0,0,0,.75);
  }
  &.black {
    background: linear-gradient(180deg, #1F213A 0%, #000000 100%), #1F213A;
  }
}

.preloader__circle {
  position: absolute;
  margin: auto;

  width: 80px;
  height: 80px;
  border: 5px solid #000000;
  border-radius: 50%;
  border-right-color: transparent !important;

  animation: 1s spin infinite linear;

  &.dark {
    border-color: #fff;
  }
  &.black {
    border-color: #ffffffb0;
  }
}

.preloader__text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: -70px;

  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

@keyframes spin {
  to { transform: rotate(360deg) }
}

</style>
