// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tokens-popup {\n  width: 100%;\n  max-width: 645px;\n  flex-direction: column;\n  background-color: #C6CCD7 !important;\n  padding: 2em 3em !important;\n  border-radius: 15px;\n}\n.tokens-popup .popup__close-btn {\n  background-color: transparent;\n}\n.tokens-popup .popup__close-btn .close-btn__element {\n  background-color: black;\n  height: 6px;\n  width: 25px;\n}\n.tokens-popup__title {\n  text-align: center;\n  text-transform: uppercase;\n  font: 800 48px Raleway;\n}\n.tokens-popup__text {\n  margin: 1.5em 0;\n  text-align: center;\n  font: 400 16px Tektur;\n}\n.tokens-popup__text--amount {\n  font: 800 24px Raleway;\n}\n.tokens-popup__content {\n  padding: 20px;\n}\n.tokens-popup__radios {\n  margin-top: 15px;\n  background-color: #3B3D41;\n  color: white;\n  padding: 40px 72px;\n  display: flex;\n  gap: 2em;\n  justify-content: center;\n}\n@media screen and (max-width: 640px) {\n.tokens-popup__radios {\n    flex-direction: column;\n    padding: 20px 35px;\n}\n.tokens-popup__radios .list-separator--arrow {\n    display: none;\n}\n}\n.tokens-popup__radios-item {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font: 600 18px Tektur;\n  gap: 1em;\n}\n.tokens-popup__buttons {\n  display: flex;\n  padding: 10px 20px;\n}\n.tokens-popup__buttons-item {\n  width: 50%;\n}\n.tokens-popup__buttons-item--amount {\n  padding: 8px;\n  background: transparent;\n  border: 2px solid black;\n  border-right: none;\n  font: 500 14px Tektur;\n}\n.tokens-popup__buttons-item--amount:disabled {\n  border-color: gray;\n}\n@media screen and (max-width: 640px) {\n.tokens-popup {\n    padding: 0.6em 1em !important;\n}\n.tokens-popup__title {\n    font-size: 24px;\n}\n.tokens-popup__text {\n    font-size: 12px;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
