import Web3 from 'web3';

class SmartContractService {
  #contracts = [];
  #web3 = null;

  constructor(contracts, provider) {
    this.#contracts = contracts;
    this._provider = provider;
    this.#web3 = new Web3(provider);
  }

  get web3() {
    return this.#web3;
  }

  set defaultAccount(address) {
    this.#web3.defaultAccount = address;
  }

  get defaultAccount() {
    return this.#web3.defaultAccount;
  }

  contract(name, gasValue) {
    const contract = this.#contracts.find((x) => x.name === name);
    if (typeof contract === 'undefined') {
      throw new Error('Smart contract not found');
    }

    const web3Contract = new this.#web3.eth.Contract(
      contract.ABI,
      contract.address,
      { gasPrice: gasValue }
    );

    //web3Contract.defaultAccount = this.defaultAccount;

    return web3Contract;
  }
}

class SmartContract {
  #name = '';
  #address = '';
  #ABI = [];

  constructor(name, abi, address) {
    this.#name = name;
    this.#address = address;
    this.#ABI = abi;
  }

  get name() {
    return this.#name;
  }

  get ABI() {
    return this.#ABI;
  }

  get address() {
    return this.#address;
  }
}

class SmartContractABI {
  #ABI = [];
  constructor() {}
  add(abiEntity) {
    const hasTypeProperty = Object.prototype.hasOwnProperty.call(
      abiEntity,
      'type'
    );
    const abiEntityTypes = [
      'function',
      'event',
      'receive',
      'constructor',
      'fallback',
    ];

    if (!hasTypeProperty || !abiEntityTypes.includes(abiEntity.type)) {
      throw new Error(`Invalid entity. Supported types ${abiEntityTypes}`);
    }

    this.#ABI.push(abiEntity);
  }

  get ABI() {
    return this.#ABI;
  }
}

export { SmartContractService, SmartContract, SmartContractABI };
