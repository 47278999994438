// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".checkbox {\n  display: flex;\n  gap: 0.75em;\n  cursor: pointer;\n  font: 500 12px Tektur;\n  align-items: center;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  user-select: none;\n}\n.checkbox-input {\n  position: absolute;\n  opacity: 0;\n  cursor: pointer;\n  height: 0;\n  width: 0;\n}\n.checkbox-input:checked ~ .checkbox-checkmark {\n  background-color: black;\n}\n.checkbox-input:checked ~ .checkbox-checkmark:after {\n  display: block;\n}\n.checkbox-checkmark {\n  position: relative;\n  height: 1em;\n  width: 1em;\n  border: 2px solid black;\n}\n.checkbox-checkmark:after {\n  content: \"\";\n  position: absolute;\n  display: none;\n  left: 1px;\n  top: -1px;\n  width: 5px;\n  height: 8px;\n  border: solid white;\n  border-width: 0 2px 2px 0;\n  transform: rotate(45deg);\n}\n.checkbox:hover .checkbox-label {\n  color: gray;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
