<template>
  <popup v-model="popup" @input="close" content-class="mint-success-popup">
    <div>
      <div class="mint-success-popup__content">
        <img loading="lazy" src="@/assets/img/checkmark.svg?inline" alt="bg" class="mint-success-popup-chackmark">
        <div class="mint-success-popup__desc">
          The transaction has been successfully created (Hash:
          <span class="hash" @click="copyLink(hash)">#{{ hash }}
            <img loading="lazy" src="@/assets/img/claiming/copy.svg?inline" class="hash__img">
          </span>)
          and will be confirmed within a few minutes
          <template v-if="!!textMsg">
              {{textMsg}}
          </template>
        </div>
      </div>
      <div class="mint-success-popup__buttons">
        <btn
          type="mint-dark"
          class="mint-success-popup__buttons-item"
          text="OK"
          @click="close"
        />
      </div>
    </div>
  </popup>
</template>

<script>
export default {
  name: "UnstakeSuccessPopup",
  data() {
    return {
      popup: false,
      hash: '',
      textMsg: '',
    }
  },
  methods: {
    open(hash, textMsg){
      this.textMsg = textMsg;
      this.popup = true
      this.hash = hash
    },
    close() {
      this.textMsg = '';
      this.popup = false
      this.hash = ''
      this.$emit('close')
    },

    copyLink(hash) {
      this.copyText(hash)
      window.open(`https://bscscan.com/tx/${hash}`, '_blank')
    },
  }
}
</script>

<style lang="scss">

.mint-success-popup {
  max-width: 570px;
  padding-bottom: 15px;

  &-chackmark {
      position: absolute;
      top: 1em;
      left: calc(50% - 23px);
      width: 36px;
  }
}
.mint-success-popup__content {
  text-transform: uppercase;
  text-align: center;
}
.mint-success-popup__title {
  font: 800 21px Raleway;
  margin-bottom: 10px;
}
.mint-success-popup__desc {
  font-size: 14px;
}
.mint-success-popup__buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  &-item {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.hash {
  font-size: 12px;

  // padding: 10px;
  word-break: break-all;
  color: rgb(26, 82, 238);

  cursor: pointer;
}
.hash__img {
  height: 1em;
  margin-left: 5px;
}

</style>
