var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loader", class: { "loader--xs": _vm.xs } }, [
    _c("i", {
      staticClass: "loader__circle",
      class: { "loader__circle--xs": _vm.xs },
    }),
    !_vm.xs ? _c("div", { staticClass: "loader__text" }) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }