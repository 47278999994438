import Vue from 'vue'
import router from "./router";
import store from "./store/";
import App from './App.vue'
import Preloader from './components/common/preloader.vue'
import Popup from './components/common/popup.vue'
import Btn from './components/common/btn.vue'
import Radio from './components/common/radio.vue'
import HintBtn from './components/common/hint-btn.vue'
import BaseIcon from '@/components/common/base-icon';
import './assets/scss/main.scss'
import Popover from 'vue-js-popover'
import AOS from 'aos'
import VueSlickCarousel from 'vue-slick-carousel'
import GlobalMixins from './mixins/global'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'aos/dist/aos.css'
import VueGtag from 'vue-gtag';
import VueSmoothScroll from 'vue2-smooth-scroll'
import PortalVue from 'portal-vue'
import * as Sentry from "@sentry/vue";
import {eventBusPlugin} from "@/plugins/eventBus";
import Paginate from 'vuejs-paginate';
import Verte from 'verte';
import 'verte/dist/verte.css';

AOS.init()

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.config.debug = process.env.NODE_ENV === 'development'
Vue.config.silent = process.env.NODE_ENV !== 'development'

Vue.component(Verte.name, Verte);
Vue.component("VueSlickCarousel", VueSlickCarousel)
Vue.component("Preloader", Preloader)
Vue.component("HintBtn", HintBtn)
Vue.component("Popup", Popup)
Vue.component("Radio", Radio)
Vue.component("Btn", Btn)
Vue.component('Paginate', Paginate)
Vue.component('BaseIcon', BaseIcon)
Vue.mixin(GlobalMixins)
Vue.use(Popover, { tooltip: true })
Vue.use(PortalVue)
Vue.use(VueSmoothScroll)
Vue.use(eventBusPlugin);

if(process.env.NODE_ENV === 'main'){
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GTAG_ID }
  },router)
}

if (process.env.VUE_APP_SENTRY_URL) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_URL,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [window.location.origin, /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
    
  });
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
