import Vue from 'vue';

export const eventBus = new Vue();

export const eventBusPlugin = {
    install(vue) {
        Object.defineProperty(vue.prototype, '$eventBus', {
            writable: false,
            configurable: false,
            enumerable: false,
            value: eventBus,
        });
    },
};
