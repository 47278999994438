import api from '@/utils/BaseApi';
import store from "@/store";

const apiCall = (endpoint, params, urlParam = '') => {
	let config = {};

	if (endpoint.auth && store.state.auth?.token) {
		config.headers = {
			Authorization: `Token ${store.state.auth.token}`,
		}
	}

	let url = `${endpoint.url}${urlParam}`;

	if (url.slice(-1) !== '/') {
		url = url + '/';
	}

	if (endpoint.method === 'post') {
		return api.post(url, params, config);
	} else if (endpoint.method === 'get') {
		return api.get(url, {...params, ...config});
	}
}

export default apiCall;
