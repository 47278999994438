<template>
  <label class="radio">
    <input
      type="radio"
      :name="name"
      :value="value"
      :checked="val == value"
      @change="$emit('input', val)"
      class="radio__field"
    >
    <i class="radio__bg" />
  </label>
</template>

<script>
export default {
  name: "Radio",
  props: {
    name: { type: String, default: '' },
    val: { type: String, default: '' },

    value: { type: String, default: '' },
  },
}
</script>

<style lang="scss">

.radio {
  position: relative;
  display: inline-block;
  width: 27px;
  min-width: 27px;
  height: 27px;
}
.radio__field {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.radio__bg {
  display: block;
  height: 100%;
  overflow: hidden;
  background: white;
  border-radius: 50%;
  padding: 7px;

  &:after {
    content: "";
    display: block;
    height: 100%;
    background: #FF9509;
    border-radius: 50%;
  }
}

.radio__icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: center center no-repeat;
}

.radio__bg:after,
.radio__icon {
  opacity: 0;
  transition: opacity 300ms;
}
.radio__field:checked {
  & ~ .radio__bg:before {
    opacity: 0;
  }
  & ~ .radio__bg:after,
  & ~ .radio__icon {
    opacity: 1;
  }
}

</style>
