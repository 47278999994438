var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { staticClass: "checkbox" }, [
    _c("input", {
      staticClass: "checkbox-input",
      attrs: { id: _vm.name, type: "checkbox" },
      domProps: { checked: _vm.checked },
      on: { change: _vm.change },
    }),
    _c("span", { staticClass: "checkbox-checkmark" }),
    _c("span", { staticClass: "checkbox-label" }, [_vm._v(_vm._s(_vm.label))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }