import { SmartContract, SmartContractABI } from '..';

const contractName = 'Marketplace';
const contractABI = new SmartContractABI();
const contractAddress = process.env.VUE_APP_MARKETPLACE_ADDRESS;

const createListing = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_tokenId',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_price',
      type: 'uint256',
    },
  ],
  name: 'createListing',
  type: 'function',
};

const cancelListing = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_tokenId',
      type: 'uint256',
    },
  ],
  name: 'cancelListing',
  type: 'function',
};

const buy = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_tokenId',
      type: 'uint256',
    },
  ],
  name: 'buy',
  type: 'function',
};

const tradingFee = {
  inputs: [],
  name: 'tradingFee',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256'
    }
  ],
  stateMutability: 'view',
  type: 'function',
  constant: true
}

contractABI.add(createListing);
contractABI.add(cancelListing);
contractABI.add(buy);
contractABI.add(tradingFee);

const MARKETPLACE_CONTRACT = new SmartContract(
  contractName,
  contractABI.ABI,
  contractAddress
);

export default MARKETPLACE_CONTRACT;
