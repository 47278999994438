var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "close-btn" }, [
    _c(
      "div",
      {
        staticClass: "close-btn__inner",
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      },
      [
        _c("div", { staticClass: "close-btn__element" }),
        _c("div", { staticClass: "close-btn__element" }),
        _c("div", { staticClass: "close-btn__element" }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }