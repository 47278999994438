import endpoints from '@/constants/Endpoints';
import apiCall from '@/utils/apiCall';

export default class ApiStaking {
  static stakingCharacters(account) {
    return apiCall(endpoints.staking.getStakingCharacters, { params: { owner: account } });
  }

  static async getVrs(id, stakeType) {
    const formData = new FormData();

    formData.append("id", id);
    formData.append("stake_type", stakeType);

    return apiCall(endpoints.staking.getVrs, formData);
  }

  static async getStakingSkills(id, stakeType, tokenId) {
    const formData = new FormData();

    formData.append("id", id);
    formData.append("stake_type", stakeType);
    formData.append("token_id", tokenId);

    return apiCall(endpoints.staking.getStakeSkills, formData);
  }

  static async getTierUpVrs(tokenId, tier) {
    const formData = new FormData();

    formData.append("token_id", tokenId);
    formData.append("tier", tier);

    return apiCall(endpoints.staking.getTierUpVrs, formData);
  }

  static async getBalance(address) {
    return apiCall(endpoints.staking.getBalance, { params: { address } });
  }

  static async getStakingInfo() {
    return apiCall(endpoints.staking.getStakingInfo);
  }
}
