import ApiLeaderboards from '@/service/ApiLeaderboards';
import router from '@/router/index';

export default {
  namespaced: true,
  state() {
    return {
      leaderboardNFT: {},
      leaderboardWallet: {},
      leaderboardEvents: {},
      leaderboardEventTable: {},
      isLoading: false
    }
  },

  getters: {
    leaderboardNFT: (state) => state.leaderboardNFT,
    leaderboardWallet: (state) => state.leaderboardWallet,
    leaderboardEvents: (state) => state.leaderboardEvents,
    leaderboardEventTable: (state) => state.leaderboardEventTable,
    leaderboardEvent: (state) => (id) => {
      return state.leaderboardEvents.game_events?.find(element => element.id === +id)
    },
    isLoading: (state) => state.isLoading,
  },

  mutations: {
    setLeaderboardNFT(state, payload) {
      state.leaderboardNFT = payload;
    },
    setLeaderboardWallet(state, payload) {
      state.leaderboardWallet = payload;
    },
    setLeaderboardEvents(state, payload) {
      state.leaderboardEvents = payload;
    },
    setLeaderboardEventTable(state, payload) {
      state.leaderboardEventTable = payload;
    },
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
  },

  actions: {
    async getLeaderboardNFT({commit}) {
      try {
        commit('setIsLoading', true);

        const { data } = await ApiLeaderboards.getLeaderboardNFT();

        commit('setLeaderboardNFT', data);
      } catch (e) {
        return e;
      } finally {
        commit('setIsLoading', false);
      }
		},
    async getLeaderboardWallet({commit}) {
      try {
        commit('setIsLoading', true);
        const { data } = await ApiLeaderboards.getLeaderboardWallet();

        commit('setLeaderboardWallet', data);
      } catch (e) {
        return e;
      } finally {
        commit('setIsLoading', false);
      }
		},
    async getLeaderboardEvents({commit}) {
      try {
        commit('setIsLoading', true);

        const { data } = await ApiLeaderboards.getLeaderboardEvents();

        commit('setLeaderboardEvents', data);
      } catch (e) {
        return e;
      } finally {
        commit('setIsLoading', false);
      } 
		},
    async getLeaderboardEventTable({ commit }, id) {
      try {
        commit('setIsLoading', true);

        const { data } = await ApiLeaderboards.getLeaderboardEventTable(id);

        commit('setLeaderboardEventTable', data)
      } catch (e) {
        router.push('/404')

        return e;
      } finally {
        commit('setIsLoading', false);
      }
    },
  },

}