export const nftsAttributesNormalizer = (nfts) => {
  nfts.forEach(nft => {
    nft.stats = Object.keys(nft.stats).map(key => {
      return {
        title: key.substring(0, 3),
        number: nft.stats[key] || 0,
      }
    })
  })
}

export const myNftStatsNormalizer = (stats) => {
  return Object.keys(stats).map(key => {
    return {
      title: key,
      number: stats[key] || 0,
    }
  })
}

export const calculatePagesCount = (pageSize, totalCount) => {
  return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
};

export const createPromise = () => {
  const obj = {};

  const promise = new Promise((resolve, reject) => {
    obj.resolve = resolve;
    obj.reject = reject;
  });

  promise.resolve = obj.resolve;
  promise.reject = obj.reject;

  return promise;
};

export const isStringsEquals = (str1, str2) => {
    return str1
        && str2
        && String(str1).toLowerCase() === String(str2).toLowerCase();
}

export const convertToTime = (seconds = 0) => {
  const days = Math.floor(parseInt(seconds, 10) / (3600*24));

  if (days > 0) {
    return `${days} days`;
  }

  const hours = Math.floor(parseInt(seconds, 10) / 3600);

  if (hours > 0) {
    return `${hours} hours`;
  }

  const minutes = Math.floor(parseInt(seconds, 10) / 60);

  if (minutes > 0) {
    return `${minutes} minutes`;
  }

  return `${seconds} seconds`;
};
