<template>
  <div class="logo" @click="$emit('click')">
    <picture>
      <source srcset="@/assets/img/logo.webp" type="image/webp">
      <source srcset="@/assets/img/logo.png" type="image/png">
      <img src="@/assets/img/logo.png" alt="metafighter logo">
    </picture>
  </div>
</template>

<script>
export default {
  name: "Logo",
}
</script>

<style lang="scss">

.logo {
  font-size: 0;
  cursor: pointer;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  &:hover {
    -webkit-animation-name: wobble-to-top-right;
    animation-name: wobble-to-top-right;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
}
@-webkit-keyframes wobble-to-top-right {
	16.65% {
		-ms-transform: translate(8px, -8px);
		-webkit-transform: translate(8px, -8px);
    transform: translate(8px, -8px);
	}
  33.3% {
    -ms-transform: translate(-6px, 6px);
    -webkit-transform: translate(-6px, 6px);
    transform: translate(-6px, 6px);
  }
	49.95% {
    -ms-transform: translate(4px, -4px);
    -webkit-transform: translate(4px, -4px);
    transform: translate(4px, -4px);
  }
  66.6% {
    -ms-transform: translate(-2px, 2px);
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
	83.25% {
    -ms-transform: translate(1px, -1px);
    -webkit-transform: translate(1px, -1px);
		transform: translate(1px, -1px);
	}
	100% {
		-ms-transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}
@keyframes wobble-to-top-right {
  16.65% {
    -ms-transform: translate(8px, -8px);
    -webkit-transform: translate(8px, -8px);
    transform: translate(8px, -8px);
  }
  33.3% {
    -ms-transform: translate(-6px, 6px);
    -webkit-transform: translate(-6px, 6px);
    transform: translate(-6px, 6px);
  }
  49.95% {
    -ms-transform: translate(4px, -4px);
    -webkit-transform: translate(4px, -4px);
    transform: translate(4px, -4px);
  }
  66.6% {
    -ms-transform: translate(-2px, 2px);
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  83.25% {
    -ms-transform: translate(1px, -1px);
    -webkit-transform: translate(1px, -1px);
    transform: translate(1px, -1px);
  }
  100% {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

</style>
