import { SmartContract, SmartContractABI } from '..';

const contractName = 'Vesting';
const contractABI = new SmartContractABI();
const contractAddress = process.env.VUE_APP_VESTING_CONTRACT_ADDRESS;

const getScheduleArrayByInvestorABI = {
  inputs: [
    {
      internalType: 'address',
      name: '_investor',
      type: 'address',
    },
  ],
  name: 'getScheduleArrayByInvestor',
  outputs: [
    {
      components: [
        {
          internalType: 'bool',
          name: 'cliffPaid',
          type: 'bool',
        },
        {
          internalType: 'address',
          name: 'investor',
          type: 'address',
        },
        {
          internalType: 'uint256',
          name: 'cliff',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'cliffPercent',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'amountAfterCliff',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'start',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'duration',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'slicePeriodSeconds',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'released',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'releasedTotal',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'releasableAmount',
          type: 'uint256',
        },
        {
          internalType: 'uint256',
          name: 'phaseID',
          type: 'uint256',
        },
        {
          internalType: 'string',
          name: 'phaseName',
          type: 'string',
        },
      ],
      internalType: 'struct Vesting.VestingSchedule[]',
      name: '',
      type: 'tuple[]',
    },
  ],
  stateMutability: 'view',
  type: 'function',
  constant: true,
};

contractABI.add(getScheduleArrayByInvestorABI);

const VESTING_CONTRACT = new SmartContract(
  contractName,
  contractABI.ABI,
  contractAddress
);

export default VESTING_CONTRACT;
