var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popup",
    {
      attrs: { "content-class": "tokens-popup" },
      on: { input: _vm.close },
      model: {
        value: _vm.popup,
        callback: function ($$v) {
          _vm.popup = $$v
        },
        expression: "popup",
      },
    },
    [
      _c(
        "div",
        { staticClass: "tokens-popup__content" },
        [
          _c("div", { staticClass: "tokens-popup__title" }, [
            _vm._v("Get MF-Credits"),
          ]),
          !_vm.isLoggedIn
            ? _c("div", { staticClass: "tokens-popup__text" }, [
                _c("p", [_vm._v("You have to be logged in to get credits")]),
              ])
            : _vm._e(),
          _vm.isLoggedIn
            ? [
                _c("div", { staticClass: "tokens-popup__text" }, [
                  _c("p", [_vm._v("Get game token for participate in")]),
                  _c("p", [_vm._v("Tournament and fight to earn modes")]),
                ]),
                _c("div", { staticClass: "tokens-popup__text" }, [
                  _vm._v(" Available "),
                  _vm.isSendTokensAction
                    ? _c("p", { staticClass: "tokens-popup__text--amount" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.toLocaleFormat(_vm.formattedGameBalance)
                            ) +
                            " MF "
                        ),
                      ])
                    : _c("p", { staticClass: "tokens-popup__text--amount" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.toLocaleFormat(_vm.formattedBalance)) +
                            " MF "
                        ),
                      ]),
                ]),
                _c("div", { staticClass: "tokens-popup__radios" }, [
                  _c(
                    "div",
                    { staticClass: "tokens-popup__radios-item" },
                    [
                      _c("span", [_vm._v("MF-Credits")]),
                      _c("radio", {
                        attrs: { name: "Game MF", val: "0" },
                        model: {
                          value: _vm.action,
                          callback: function ($$v) {
                            _vm.action = $$v
                          },
                          expression: "action",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("hr", {
                    staticClass: "list-separator--arrow",
                    class: {
                      "list-separator--arrow--left": !_vm.isSendTokensAction,
                      "list-separator--arrow--right": _vm.isSendTokensAction,
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "tokens-popup__radios-item" },
                    [
                      _c("span", [_vm._v("MF")]),
                      _c("radio", {
                        attrs: { name: "MF", val: "1" },
                        model: {
                          value: _vm.action,
                          callback: function ($$v) {
                            _vm.action = $$v
                          },
                          expression: "action",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.isLoggedIn
        ? [
            _c(
              "div",
              { staticClass: "tokens-popup__buttons" },
              [
                _vm.isAllAmount
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.allAmount,
                          expression: "allAmount",
                        },
                      ],
                      staticClass:
                        "tokens-popup__buttons-item tokens-popup__buttons-item--amount",
                      attrs: {
                        type: "number",
                        placeholder: "enter desirable amount",
                        disabled: "",
                      },
                      domProps: { value: _vm.allAmount },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.allAmount = $event.target.value
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.amount,
                          expression: "amount",
                        },
                      ],
                      staticClass:
                        "tokens-popup__buttons-item tokens-popup__buttons-item--amount",
                      attrs: {
                        type: "number",
                        placeholder: "enter desirable amount",
                      },
                      domProps: { value: _vm.amount },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.amount = $event.target.value
                        },
                      },
                    }),
                _c("btn", {
                  staticClass: "tokens-popup__buttons-item",
                  attrs: { type: "icon-gradient", text: "SWAP" },
                  on: { click: _vm.onSwapClick },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tokens-popup__buttons" },
              [
                _c("CheckBox", {
                  attrs: { name: "isAllAmount", label: "put all amount" },
                  model: {
                    value: _vm.isAllAmount,
                    callback: function ($$v) {
                      _vm.isAllAmount = $$v
                    },
                    expression: "isAllAmount",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.isProcessing
        ? _c("preloader", { attrs: { translucent: "", black: "" } })
        : _vm._e(),
      _c("transaction-success-popup", {
        ref: "transactionSuccessPopup",
        on: { close: _vm.close },
      }),
      _c("approve-popup", {
        ref: "approvePopup",
        attrs: {
          "action-btn-text": "SWAP",
          successMsg:
            "You have approved a sufficient number of MF tokens. Now you can transfer your tokens.",
        },
        on: { "action-btn-click": _vm.spotTransfer },
      }),
      _c("question-withdraw-popup", {
        ref: "questionWithdrawPopup",
        on: { confirm: _vm.swapProccess },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }