import { SmartContract, SmartContractABI } from '..';

const contractName = 'MFNFT';
const contractABI = new SmartContractABI();
const contractAddress = process.env.VUE_APP_MFNFT_CONTRACT_ADDRESS;

const safeMintABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_characterId',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_characterPrice',
      type: 'uint256'
    },
    {
      internalType: 'bytes32',
      name: '_salt',
      type: 'bytes32'
    },
    {
      internalType: 'bytes',
      name: 'vrs',
      type: 'bytes'
    }
  ],

  name: 'safeMint',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

const approve = {
  inputs: [
    {
      internalType: "address",
      name: "to",
      type: "address"
    },
    {
      internalType: "uint256",
      name: "tokenId",
      type: "uint256"
    }
  ],
  name: "approve",
  outputs: [],
  stateMutability: "nonpayable",
  type: "function"
};


const getApproved ={
  inputs: [
    {
      "internalType": "uint256",
      "name": "tokenId",
      "type": "uint256"
    }
  ],
  name: "getApproved",
  outputs: [
    {
      internalType: "address",
      name: "",
      type: "address"
    }
  ],
  stateMutability: "view",
  type: "function"
};

contractABI.add(safeMintABI);
contractABI.add(approve);
contractABI.add(getApproved);

const MFNFT_CONTRACT = new SmartContract(
  contractName,
  contractABI.ABI,
  contractAddress
);

export default MFNFT_CONTRACT;
