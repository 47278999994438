<template>
  <div
      id="app"
      :class="['app', { 'app--top-paddings': canAddTopPaddings && $route.name !== 'GameBody' }]"

  >
    <template v-if="$route.name !== 'GameBody'">
      <Header :scrollY="scrollY"/>
      <fonts-loader @onload="onFontsLoad"/>
      <Preloader v-if="loading" :black="isBlack"/>
      <template v-else>
        <Popovers/>
        <cookie-alert/>
        <tooltip/>
        <router-view class="app__content"/>
        <Footer/>
        <all-popovers/>
        <portal-target name="main-popup" multiple/>

        <ErrorPopup
            v-if="isErrorShown"
            @close="errorPopupClose"
            :title="errorTitle"
            :errorText="errorText"
        >

        </ErrorPopup>
      </template>
    </template>

    <router-view v-else/>

  </div>
</template>
<script>
import Header from '@/components/common/header';
import CookieAlert from '@/components/common/cookie-alert';
import Footer from '@/components/common/footer';
import FontsLoader from '@/components/common/fonts-loader';
import AllPopovers from '@/components/common/all-popovers';
import ErrorPopup from '@/components/popups/error-popup.vue';
import Popovers from '@/components/common/popovers';
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'App',
  components: {
    Header,
    CookieAlert,
    Footer,
    FontsLoader,
    AllPopovers,
    ErrorPopup,
    Popovers,
  },
  data() {
    return {
      scrollY: 0,
      loading: true,
      isErrorShown: false,
      errorTitle: '',
      errorText: '',
      isBlack: false,
    }
  },
  computed: {
    ...mapGetters(['metamaskconnect', 'walletconnect', 'GET_IS_DEVELOPMENT', 'account']),
    ...mapGetters('contract', ['contractService', 'chainId']),
    ...mapGetters('auth', ['token']),

    canAddTopPaddings() {
      return this.$route.path !== '/'
    },
    isConnected() {
      return this.metamaskconnect || this.walletconnect;
    },
  },
  watch: {
    '$route.name': {
      handler() {
        this.loading = true
        this.$nextTick()
        document.fonts.ready.then(() => this.loading = false)
      },
      deep: true,
      immediate: true
    },
    isConnected: {
      async handler(val) {
        if (!val) {
          this.contractServiceProcess(null);
          return;
        }

        await this.contractServiceHandler();

        if (!this.token) {
          await this.login();
        }
        // Если оставить условия, то не сбрасыват акк после закрытия браузера
        else if(!this.metamaskconnect && !this.chainId) {
             await this.checkAccountsAccessHandler();
        }

        if(this.chainId) {
          await this.getBalance();
        }

      },
      immediate: true,
    }
  },
  created() {
    this.loaderColorProcess();
  },
  mounted() {
    this.$eventBus.$on('errorPopup', this.errorPopupOpen);

    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.setActualDevice);
    this.setActualDevice();

    if (this.GET_IS_DEVELOPMENT) {
      window.sign = this.sign;
    }
  },
  methods: {
    ...mapActions('contract', ['contractServiceProcess', 'checkAccountsAccess', 'contractServiceHandler', 'getBalance']),
    ...mapActions('auth', ['login', 'logout']),

    async checkAccountsAccessHandler() {
      try {
        const accounts = await this.checkAccountsAccess();

        if (!accounts?.length) {
          await this.logout();
        }
      } catch (e) {
        await this.logout();
      }
    },
    onScroll() {
      this.scrollY = window.scrollY
    },

    setActualDevice() {
      this.$store.commit('UPDATE_DEVICE_SETTINGS')
    },

    onFontsLoad() {
      this.loading = false
    },

    errorPopupOpen(title, text) {
      this.isErrorShown = true;
      this.errorTitle = title;
      this.errorText = text;
    },

    errorPopupClose() {
      this.isErrorShown = false;
      this.errorTitle = '';
      this.errorText = '';
    },
    async setWalletSettings() {

    },
    loaderColorProcess() {
      const path = window.location.pathname.split('/')[1];
      const currentRoute = this.$router.options.routes.find((route) => route.path === `/${path}`);

      if (currentRoute?.props?.dark) {
        this.isBlack = true;
      }
    },
    async sign(rawMessage) {
      if (!this.account) {
        console.log('there is no metamask account in the store');
        return;
      }

      try {
        const message = this.contractService.web3.utils.toHex(rawMessage);
        const signature = await this.contractService.web3.eth.personal.sign(message, this.account);

        console.log('signature:  ', signature)
      } catch (e) {
        this.$eventBus.$emit('errorPopup', '', e.message || 'unexpected error');
      }
    },
  }
}

</script>

<style lang="scss">
.app {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

}

.app__content {
  flex-grow: 1;
}

.app--top-paddings {
  padding-top: 110px;
  background: linear-gradient(180deg, #1F213A 0%, #000000 100%), #1F213A;

  @media screen and (max-width: 1820px) {
    padding-top: 100px;
  }

  @media screen and (max-width: 1679px) {
    padding-top: 90px;
  }

  @media screen and (max-width: 1439px) {
    padding-top: 80px;
  }

  @media screen and (max-width: 1023px) {
    padding-top: 75px;
  }

  @media screen and (max-width: ($bp_desktop - 1px)) {
    padding-top: 80px;
  }

  @media screen and (max-width: ($bp_mb - 1px)) {
    padding-top: 60px;
  }
}

#WEB3_CONNECT_MODAL_ID {
  position: relative;
  z-index: 999;
}
</style>
