// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".logo {\n  font-size: 0;\n  cursor: pointer;\n}\n.logo img {\n  display: block;\n  max-width: 100%;\n  max-height: 100%;\n}\n.logo:hover {\n  animation-name: wobble-to-top-right;\n  animation-duration: 1s;\n  animation-timing-function: ease-in-out;\n  animation-iteration-count: 1;\n}\n@keyframes wobble-to-top-right {\n16.65% {\n    transform: translate(8px, -8px);\n}\n33.3% {\n    transform: translate(-6px, 6px);\n}\n49.95% {\n    transform: translate(4px, -4px);\n}\n66.6% {\n    transform: translate(-2px, 2px);\n}\n83.25% {\n    transform: translate(1px, -1px);\n}\n100% {\n    transform: translate(0, 0);\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
