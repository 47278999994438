import endpoints from '@/constants/Endpoints';
import apiCall from '@/utils/apiCall';

export default class ApiAuth {
    static async login(wallet_address, sign, message) {
        const formData = new FormData();

        formData.append("wallet_address", wallet_address);
        formData.append("sign", sign);
        formData.append("message", message);

        return apiCall(endpoints.auth.login, formData);
    }
    static async logout() {
        return apiCall(endpoints.auth.logout);
    }
    static async logoutAll() {
        return apiCall(endpoints.auth.logoutAll);
    }
}
