var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "menu" }, [
    _c(
      "div",
      { staticClass: "menu__list" },
      [
        _vm.links.length
          ? _vm._l(_vm.links, function (link, i) {
              return _c(
                "a",
                {
                  key: i,
                  staticClass: "menu__link link",
                  attrs: { href: link.href, target: "_blank" },
                },
                [_vm._v(" " + _vm._s(link.label) + " ")]
              )
            })
          : [
              _vm._l(_vm.getMenu, function (menuItem) {
                return [
                  menuItem.children
                    ? _c(
                        "div",
                        {
                          key: menuItem.name,
                          staticClass: "menu__link menu__link--submenu link",
                          class: {
                            "menu__link--active": menuItem.isActive,
                            active: _vm.getActiveClasses(menuItem),
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.setActiveSubMenu(menuItem)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "menu__link-inner" },
                            [
                              _c("span", [_vm._v(_vm._s(menuItem.label))]),
                              _c("BaseIcon", {
                                staticClass: "menu__submenu-icon",
                                attrs: { name: "arrow-down" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "menu__submenu",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _vm._l(menuItem.children, function (child) {
                                return [
                                  child.externalUrl
                                    ? _c(
                                        "a",
                                        {
                                          key: child.name,
                                          staticClass: "menu__link link",
                                          attrs: {
                                            href: child.externalUrl,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(child.label) + " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "router-link",
                                        {
                                          key: child.label,
                                          staticClass: "menu__link link",
                                          attrs: {
                                            to: child.path,
                                            "active-class": "active",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(child.label) + " "
                                          ),
                                        ]
                                      ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]
                      )
                    : menuItem.externalUrl
                    ? _c(
                        "a",
                        {
                          key: menuItem.name,
                          staticClass: "menu__link link",
                          attrs: {
                            href: menuItem.externalUrl,
                            target: "_blank",
                          },
                        },
                        [_vm._v(" " + _vm._s(menuItem.name) + " ")]
                      )
                    : _c(
                        "router-link",
                        {
                          directives: [
                            {
                              name: "popover",
                              rawName: "v-popover",
                              value: { name: menuItem.popover },
                              expression: "{ name: menuItem.popover }",
                            },
                          ],
                          key: menuItem.name,
                          staticClass: "menu__link link",
                          class: { active: _vm.getActiveClasses(menuItem) },
                          attrs: { to: menuItem.disabled ? "" : menuItem.path },
                          on: {
                            click: function ($event) {
                              !menuItem.disabled && _vm.$emit("link-clicked")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(menuItem.label) + " ")]
                      ),
                ]
              }),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }