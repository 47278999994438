import {mapActions, mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters('generalData', [
            'boosters',
            'boostersRules',
            'stakingInfo'
        ]),
    },
    mounted() {
        if (!this.boosters?.length) {
            this.getBoosters();
        }
        if (!this.boostersRules.passive_boosters) {
            this.getBoostersRules();
        }
    },
    methods: {
        ...mapActions('generalData', ['getBoosters', 'getBoostersRules']),

        toDays(seconds) {
            return Math.floor(parseInt(seconds, 10) / (3600*24));
        }
    },
}
