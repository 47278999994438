import Vue from "vue";
import VueRouter from "vue-router";

const loadComponent = path => () => import(`@/views/${path}.vue`);

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: loadComponent("main/Main")
  },
  {
    path: "/game",
    name: "Game",
    component: loadComponent("game/GameWrapper")
  },
  {
    path: "/game-body",
    name: "GameBody",
    component: loadComponent("game/GameBody")
  },
  {
    path: "/claim",
    name: "Claiming",
    component: loadComponent("claiming/Claiming")
  },
  {
    path: "/marketplace",
    // name: "Market",
    component: loadComponent("market/MarketPlace"),
    props: { dark: true },
    children: [
      {
        path: "/",
        name: "Market",
        redirect: '/marketplace/shop',
        component: loadComponent("market/Home"),
      },
      {
        path: "mint",
        name: "Mint",
        component: loadComponent("market/mint/list"),
      },
      {
        props: true,
        path: "mint/:person",
        name: "mintingSingle",
        component: loadComponent("market/mint/mintingSingle"),
      },
      {
        path: "my_nfts",
        name: "MyNfts",
        component: loadComponent("market/myNft/list"),
      },
      {
        props: true,
        path: "my_nfts/:id",
        name: "MyNftSingle",
        component: loadComponent("market/myNft/myNftSingle"),
      },
      {
        path: "shop",
        name: "Shop",
        component: loadComponent("market/shop/list"),
      },
      {
        props: true,
        path: ":person",
        name: "shopSingle",
        component: loadComponent("market/shop/shopSingle"),
      },
    ],
  },
  {
    path: "/staking",
    name: "Staking",
    component: loadComponent("staking/list"),
    props: { dark: true },
  },
  {
    path: "/user_stakes",
    name: "MyStake",
    component: loadComponent("staking/myStake/stake"),
    redirect: '/user_stakes/staked',
    children: [
      {
        path: "staked",
        name: "Staked",
        component: loadComponent("staking/myStake/MyStakeTable"),
      },
      {
        path: "unstake",
        name: "Unstake",
        component: loadComponent("staking/myStake/MyUnstakeTable"),
      },
      {
        path: "history",
        name: "History",
        component: loadComponent("staking/myStake/MyHistoryTable"),
      }
    ],
  },
  {
    props: true,
    path: "/staking/:person",
    name: "StakingSingle",
    component: loadComponent("staking/stakingSingle"),
  },
  {
    path: "/auth",
    name: "auth",
    component: loadComponent("auth/Auth")
  },
  {
    path: "/leaderboard/:tab",
    name: "leaderboard",
    component: loadComponent("leaderboard/Leaderboard")
  },
  {
    path: "/leaderboard/event/:tab",
    name: "event",
    component: loadComponent("leaderboard/Leaderboard")
  },
  {
    path: "*",
    name: '404',
    component: loadComponent("errors/404")
  }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
  base: process.env.BASE_URL,
  routes
});

export default router;
