import endpoints from '@/constants/Endpoints';
import apiCall from '@/utils/apiCall';

export default class ApiShop {
  static shopCharacters(props) {
    return apiCall(endpoints.shop.getShopCharacters, {
      params: {
        ...props,
        page: props.page,
      }
    });
  }

  static shopCharacter(id) {
    return apiCall(endpoints.shop.getShopCharacter, {}, `${id}/`);
  }

  static buyBoosterGetVrs(id) {
    const formData = new FormData();

    formData.append("booster_id", id);

    return apiCall(endpoints.shop.buyBoosterGetVrs, formData);
  }
}
