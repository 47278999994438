var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isSocialsVisible,
            expression: "isSocialsVisible",
          },
        ],
        staticClass: "socials",
        class: {
          [`socials--${_vm.type}`]: _vm.type,
          "socials--background": _vm.type === "fixed" && _vm.isBackground,
        },
      },
      _vm._l(_vm.socials, function (social, i) {
        return _c(
          "a",
          {
            key: i,
            staticClass: "socials__link",
            attrs: {
              href: social.link,
              target: "_blank",
              "data-aos": "fade-up",
              "data-aos-delay": i * 100,
              "data-aos-offset": "0",
            },
          },
          [
            _c("img", {
              staticClass: "socials__icon",
              attrs: {
                src: require(`@/assets/img/icons/${social.icon}.png`),
                alt: "social icon",
              },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }