<template>
    <popup v-model="popup" @input="close" content-class="tokens-popup">
        <div class="tokens-popup__content">
            <div class="tokens-popup__title">Get MF-Credits</div>

            <div v-if="!isLoggedIn" class="tokens-popup__text">
                <p>You have to be logged in to get credits</p>
            </div>

            <template v-if="isLoggedIn">
                <div class="tokens-popup__text">
                    <p>Get game token for participate in</p>
                    <p>Tournament and fight to earn modes</p>
                </div>
    
                <div class="tokens-popup__text">
                    Available
                    <p
                        v-if="isSendTokensAction"
                        class="tokens-popup__text--amount"
                    >
                        {{toLocaleFormat(formattedGameBalance)}} MF
                    </p>
                    <p
                        v-else
                        class="tokens-popup__text--amount"
                    >
                        {{toLocaleFormat(formattedBalance)}} MF
                    </p>
                </div>
    
                <div class="tokens-popup__radios">
                    <div class="tokens-popup__radios-item">
                        <span>MF-Credits</span>
    
                        <radio
                            name="Game MF"
                            val="0"
                            v-model="action"
                        />
                    </div>
    
                    <hr
                        :class="{
                            'list-separator--arrow--left': !isSendTokensAction,
                            'list-separator--arrow--right': isSendTokensAction
                        }"
                        class="list-separator--arrow"
                    >
    
                    <div class="tokens-popup__radios-item">
                        <span>MF</span>
    
                        <radio
                            name="MF"
                            val="1"
                            v-model="action"
                        />
                    </div>
                </div>
            </template>
        </div>

        <template v-if="isLoggedIn">
            <div class="tokens-popup__buttons">
                <input
                    v-if="isAllAmount"
                    class="tokens-popup__buttons-item tokens-popup__buttons-item--amount"
                    type="number"
                    placeholder="enter desirable amount"
                    v-model="allAmount"
                    disabled
                />
                <input
                    v-else
                    class="tokens-popup__buttons-item tokens-popup__buttons-item--amount"
                    type="number"
                    placeholder="enter desirable amount"
                    v-model="amount"
                />
    
                <btn
                    class="tokens-popup__buttons-item"
                    type="icon-gradient"
                    text="SWAP"
                    @click="onSwapClick"
                />
            </div>
    
            <div class="tokens-popup__buttons">
                <CheckBox
                    name="isAllAmount"
                    label="put all amount"
                    v-model="isAllAmount"
                />
            </div>
        </template>

        <preloader translucent black v-if="isProcessing" />
        <transaction-success-popup ref="transactionSuccessPopup" @close="close"/>
        <approve-popup
            ref="approvePopup"
            action-btn-text="SWAP"
            successMsg="You have approved a sufficient number of MF tokens. Now you can transfer your tokens."
            @action-btn-click="spotTransfer"
        />
        <question-withdraw-popup ref="questionWithdrawPopup" @confirm="swapProccess"/>
    </popup>
</template>

<script>
import CheckBox from "@/components/common/checkbox";
import ApiWallet from "@/service/ApiWallet";
import {mapActions, mapGetters} from "vuex";
import TransactionSuccessPopup from "@/components/popups/transaction-success-popup";
import ApprovePopup from '@/components/popups/approve-popup.vue'
import QuestionWithdrawPopup from "@/components/popups/question-withdraw-popup";

export default {
    name: "GetTokensPopup",
    components: {
        QuestionWithdrawPopup,
        TransactionSuccessPopup,
        CheckBox,
        ApprovePopup,
    },
    props: {},
    computed: {
        ...mapGetters(['account', 'isLoggedIn']),
        ...mapGetters('contract', ['balance']),
        ...mapGetters('wallet', ['gameBalance']),

        isSendTokensAction() {
            return this.action === '1';
        },
        formattedBalance() {
            return this.fromWei(this.balance).toFixed(2)
        },
        allAmount() {
            return this.isSendTokensAction ? this.formattedGameBalance : this.formattedBalance;
        },
        currentAmount() {
            return this.isAllAmount ? this.allAmount : this.amount;
        },
        maxAmount() {
            return this.isSendTokensAction ? this.formattedGameBalance : this.formattedBalance;
        },
        formattedGameBalance() {
            return Number(this.gameBalance || 0).toFixed(2)
        }
    },
    watch: {
        popup(val) {
            if (val) {
                this.fetchGameBalance();
            }
        },
        amount(val) {
            if (val > Number(this.maxAmount)) {
                this.amount = this.allAmount;
            }
        },
        action() {
            this.amount = null;
        },
    },
    data() {
        return {
            popup: false,
            amount: null,
            isAllAmount: false,
            action: '0',
            isProcessing: false,
        }
    },
    methods: {
        ...mapActions('contract', [
            'allowance',
            'sendTokensToUser',
            'spotTransferFromUser',
            'getBalance',
        ]),
        ...mapActions('wallet', ['fetchGameBalance']),

        open(){
            this.popup = true;
            this.amount = null;
        },
        close() {
            this.$emit('close')
            this.popup = false
        },
        onSwapClick() {
            if (!Number(this.currentAmount)) {
                return;
            }
            if (this.isSendTokensAction) {
                this.$refs.questionWithdrawPopup.open();
            } else {
                this.swapProccess();
            }
        },
        async swapProccess() {
            this.isProcessing = true;

            if (this.isSendTokensAction) {
                await this.sendTokens();
            } else {
                await this.spotTransferHandler();
            }

            await this.getBalance();
            this.isProcessing = false;
        },
        async sendTokens() {
            try {
                const vrsData = await ApiWallet.sendTokensGetVrs(this.account, this.web3ToWei(this.currentAmount));

                const result = await this.sendTokensToUser(vrsData.data);

                this.$refs.transactionSuccessPopup.open(result.transactionHash);
            } catch (error) {
                this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
                this.close();
            }
        },
        async spotTransferHandler() {
            const allowance = await this.allowance(true);

            if (allowance === 'error') {
                return;
            }

            if (this.fromWei(allowance) < Number(this.currentAmount)) {
                this.$refs.approvePopup.open(this.currentAmount, { isSpotWalletTransfer: true });
            } else {
                await this.spotTransfer();
            }
        },
        async spotTransfer() {
            this.isProcessing = true;

            try {
                const vrsData = await ApiWallet.spotTransferGetVrs(this.web3ToWei(this.currentAmount));

                const result = await this.spotTransferFromUser(vrsData.data);

                this.$refs.transactionSuccessPopup.open(result.transactionHash);
            } catch (error) {
                this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
                this.close();
            }

            this.isProcessing = false;
        }
    }
}
</script>

<style lang="scss">

.tokens-popup {
    width: 100%;
    max-width: 645px;
    flex-direction: column;
    background-color: #C6CCD7!important;
    padding: 2em 3em!important;
    border-radius: 15px;

    .popup__close-btn {
        background-color: transparent;

        .close-btn__element {
            background-color: black;
            height: 6px;
            width: 25px;
        }
    }
}

.tokens-popup__title {
    text-align: center;
    text-transform: uppercase;
    font: 800 48px Raleway;
}

.tokens-popup__text {
    margin: 1.5em 0;
    text-align: center;
    font: 400 16px Tektur;

    &--amount {
        font: 800 24px Raleway;
    }
}

.tokens-popup__content {
    padding: 20px;
}

.tokens-popup__radios {
    margin-top: 15px;
    background-color: #3B3D41;
    color: white;
    padding: 40px 72px;
    display: flex;
    gap: 2em;
    justify-content: center;

    @media screen and (max-width: $bp_mb) {
        flex-direction: column;
        padding: 20px 35px;

        .list-separator--arrow {
            display: none;
        }
    }
}

.tokens-popup__radios-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font: 600 18px Tektur;
    gap: 1em;
}

.tokens-popup__buttons {
    display: flex;
    padding: 10px 20px;
}

.tokens-popup__buttons-item {
    width: 50%;

    &--amount {
        padding: 8px;
        background: transparent;
        border: 2px solid black;
        border-right: none;
        font: 500 14px Tektur;

        &:disabled {
            border-color: gray;
        }
    }
}

@media screen and (max-width: $bp_mb) {
    .tokens-popup {
        padding: 0.6em 1em!important;
    }

    .tokens-popup__title {
        font-size: 24px;
    }

    .tokens-popup__text {
        font-size: 12px;
    }
}
</style>
