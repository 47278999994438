<template>
    <div
        :class="{'loader--xs': xs}"
        class="loader"
    >
        <i
            :class="{'loader__circle--xs': xs}"
            class="loader__circle"
        />
        <div
            v-if="!xs"
            class="loader__text"
        >
<!--            Loading-->
        </div>
    </div>
</template>

<script>
export default {
    name: "Loader",
    props: {
        xs: { type: Boolean, default: false },
    },
}
</script>

<style lang="scss" scoped>

.loader {
    margin-top: 10%;
    margin-left: calc(50% - 40px);

    &__circle {
        position: absolute;
        margin: auto;
        width: 80px;
        height: 80px;
        border: 5px solid #c0c0c0;
        border-radius: 50%;
        border-right-color: transparent !important;
        animation: 1s spin infinite linear;

        &--xs {
            width: 15px;
            height: 15px;
            border: 3px solid #c0c0c0;
        }
    }

    &__text {
        padding-top: 15%;
        margin-left: 5px;
        color: silver;
    }

    &--xs {
        text-align: center;
        margin: auto;
        height: 100%;
    }
}

</style>
