import store from "@/store";

export const initWalletEvents = (provider) => {
    if (!provider) {
        console.log('initWalletEvents error: provider was not found')
        return;
    }

    provider.on('disconnect', () => {
        console.log('disconnected');
    });

    // provider.on('message', (message) => console.log('message', message));

    provider.on('connect', ({ chainId }) => {
        console.log('connect', chainId);

        store.commit('contract/setChainId', chainId);

        if (store.getters.isLoggedIn) {
            store.dispatch('contract/getBalance');
        }

        store.dispatch('contract/getFee');
    });

    provider.on('accountsChanged', (accounts) => {
        console.log('accountsChanged', accounts)

        // разлогинились - очищаем токен
        // store.dispatch('auth/logout');

        store.commit('setAccount', accounts[0]);

        if (store.getters['contract/isCorrectNetwork'] && accounts.length) {
            store.dispatch('contract/getFee');
        }

        if (accounts.length) {
            // получаем токен заного
            store.dispatch('auth/login');
        }
    });

    provider.on('chainChanged', (chainId) => {
        console.log('chainId changed', chainId);

        store.commit('contract/setChainId', chainId);
        if (store.getters.isLoggedIn) {
            store.dispatch('contract/getBalance');
        }

        store.dispatch('contract/getFee');
    });
}
