import {myNftStatsNormalizer} from "@/utils/helpers"

export default class Character {
    constructor(params) {
        Object.keys(params).forEach((param) => this[param] = params[param]);
    }

    static produceCharacter(rawData) {
        const formattedStats = myNftStatsNormalizer(rawData.mf_attributes.stats);

        return new this({
            ...rawData,
            mf_attributes: {
                ...rawData.mf_attributes,
                stats: formattedStats,
            },
        });
    }
}
