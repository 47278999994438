// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".socials {\n  display: flex;\n  align-items: center;\n}\n.socials__link {\n  margin-right: 10px;\n  transition: 300ms;\n}\n.socials__link:last-child {\n  margin-right: 0;\n}\n.socials--cubic .socials__link {\n  position: relative;\n  width: 59px;\n  height: 51px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid;\n  color: #fff;\n}\n.socials--cubic .socials__link:after {\n  content: \"\";\n  position: absolute;\n  left: 50%;\n  transform: translateX(-50%);\n  top: 0;\n  border: 5px solid;\n  border-left-color: transparent;\n  border-right-color: transparent;\n  border-bottom-color: transparent;\n}\n.socials--cubic .socials__link:hover {\n  filter: brightness(0.25);\n}\n.socials--tiny .socials__link {\n  margin-right: 20px;\n  filter: brightness(0.5);\n}\n.socials--tiny .socials__link:hover {\n  filter: brightness(1);\n}\n.socials--tiny .socials__link:last-child {\n  margin-right: 0;\n}\n.socials__icon {\n  max-width: 21px;\n  max-height: 23px;\n  width: auto;\n  height: auto;\n}\n.socials--fixed {\n  position: fixed;\n  right: 0;\n  top: 35%;\n  flex-direction: column;\n  gap: 2em;\n  z-index: 4;\n  padding: 1.8em 1em;\n  transition: background-color 0.25s;\n}\n@media screen and (max-width: 640px) {\n.socials--fixed {\n    display: none;\n}\n}\n.socials--background {\n  background-color: #2d303f6e;\n}\n.socials--fixed .socials__link {\n  margin: auto;\n}\n.socials--fixed .socials__link:hover {\n  filter: brightness(0.25);\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
