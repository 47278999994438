import ApiAuth from "@/service/ApiAuth";

export default {
	namespaced: true,
    state() {
        return {
			token: null,
			user: null,
			wallet: null,
		}
    },

	mutations: {
		setToken(state, payload) {
			state.token = payload;
		},
		setUser(state, payload) {
			state.user = payload;
		},
		setWallet(state, payload) {
			state.wallet = payload;
		},
	},

	getters: {
		token: (state) => state.token,
		user: (state) => state.user,
		wallet: (state) => state.wallet,
	},

    actions: {
		clearData({commit}) {
			commit('setToken', null);
			commit('setUser', null);
			commit('setWallet', null);

			// очищаем account из smartcontract
			// лезть туда ненадо потомучто работает на соплях
			// поэтому меняем отсюда
			commit('setAccount', null, {root: true});
			commit('setMetamaskconnect', null, {root: true});
			commit('setWalletconnect', null, {root: true});
		},
		async login({commit, dispatch, rootGetters}) {
			const contract = rootGetters["contract/contractService"];

			if (!contract) {
				console.error('contract doesnt found');

				return;
			}

			try {
				const message = contract.web3.utils.toHex(`${window.location.origin} wants you to sign in with your account: ${rootGetters.account}`);
				const signature = await contract.web3.eth.personal.sign(message, rootGetters.account, '');
				const response = await ApiAuth.login(rootGetters.account, signature, message);

				if (!response.data.token) {
					return;
				}

				commit('setToken', response.data.token);
				commit('setUser', response.data.user);
				commit('setWallet', response.data.wallet);

				dispatch('contract/getBalance', null, { root: true });
			} catch (e) {
				if (e.code === 4001) {
					// юзер отклонил подпись - разлогиниваем метамаск
					dispatch('clearData');
				}

				console.error(e.message || 'unexpected error');

				// if (state.token) {
				// 	dispatch('logout');
				// }
			}
		},
		async logout({dispatch}) {
			dispatch('clearData');

			await ApiAuth.logout();
		}
    },
}

