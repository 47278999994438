import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

import MetaModule from './modules/meta'
import smartContract from './modules/smartContract'
import Contract from './modules/contract'
import WalletModule from './modules/wallet'
import CharactersModule from './modules/characters'
import CoachMarksModule from './modules/coachMarks'
import Tournament from './modules/tournament'
import Auth from './modules/auth'
import GeneralData from './modules/generalData'
import Filters from './modules/filters'
import Events from './modules/events'
import Leaderboards from './modules/leaderboards'
import Stakes from './modules/stakes'

export default new Vuex.Store({
  modules: {
    meta: MetaModule,
    smartContract: smartContract,
    contract: Contract,
    wallet: WalletModule,
    characters: CharactersModule,
    coachMarks: CoachMarksModule,
    tournament: Tournament,
    auth: Auth,
    generalData: GeneralData,
    filters: Filters,
    events: Events,
    leaderboards: Leaderboards,
    stakes: Stakes
  },
  plugins: [createPersistedState({
    key: 'user',
    paths: ['auth', 'smartContract', 'coachMarks', 'tournament'],
  })]
})
