var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "header",
        {
          "header--is-menu-show": _vm.isMenuShow,
        },
      ],
    },
    [
      _c(
        "div",
        { staticClass: "header__mob container" },
        [
          _c("menu-btn", {
            staticClass: "header__menu-btn",
            attrs: { color: "dark" },
            model: {
              value: _vm.isMenuShow,
              callback: function ($$v) {
                _vm.isMenuShow = $$v
              },
              expression: "isMenuShow",
            },
          }),
          _c("logo", {
            staticClass: "header__logo",
            on: {
              click: function ($event) {
                return _vm.goTo("/")
              },
            },
          }),
          _c("div", { staticClass: "header__buttons" }, [
            !_vm.isConnectBtnShow
              ? _c(
                  "div",
                  {
                    staticClass:
                      "header__buttons-item header__buttons-item--icon",
                  },
                  [
                    _c(
                      "btn",
                      {
                        attrs: {
                          type: "button button--mint-white",
                          text: "Get Credits",
                        },
                        on: { click: _vm.onClickGetMfTokens },
                      },
                      [
                        _c("BaseIcon", {
                          attrs: { name: "get-credits-btn-mob" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isConnectBtnShow
              ? _c(
                  "div",
                  {
                    staticClass:
                      "header__buttons-item header__buttons-item--icon",
                  },
                  [
                    _c(
                      "btn",
                      {
                        attrs: {
                          type: "mint-white",
                          text: "CONNECT",
                          "data-coach-mark": "main-connect-btn",
                        },
                        on: { click: _vm.connectToMeta },
                      },
                      [_c("BaseIcon", { attrs: { name: "connect-btn-mob" } })],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "header__inner container" },
        [
          _c("logo", {
            staticClass: "header__logo",
            on: {
              click: function ($event) {
                return _vm.goTo("/")
              },
            },
          }),
          _c("Menu", {
            staticClass: "header__nav",
            on: {
              "link-clicked": function ($event) {
                _vm.isMenuShow = false
              },
            },
          }),
          _c("div", { staticClass: "header__buttons header__buttons--nav" }, [
            !_vm.isConnectBtnShow
              ? _c(
                  "div",
                  { staticClass: "header__buttons-item" },
                  [
                    _c("btn", {
                      attrs: {
                        type: "button button--mint-white",
                        text: "Get Credits",
                      },
                      on: { click: _vm.onClickGetMfTokens },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isConnectBtnShow
              ? _c(
                  "div",
                  { staticClass: "header__buttons-item" },
                  [
                    _c("btn", {
                      attrs: {
                        type: "mint-white",
                        text: "CONNECT",
                        "data-coach-mark": "main-connect-btn",
                      },
                      on: { click: _vm.connectToMeta },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm.canShowBalanceInDesktop
            ? _c("balance", {
                staticClass: "header__balance",
                on: { click: _vm.disconnect },
              })
            : _vm._e(),
          !_vm.isConnectBtnShow
            ? _c("div", { staticClass: "header__actions" }, [
                _c(
                  "button",
                  { staticClass: "link", on: { click: _vm.disconnectWallet } },
                  [_vm._v("Disconnect")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "link",
                    class: { "link--unstake": _vm.canShowHeaderBg },
                    on: { click: _vm.unstakeAllClick },
                  },
                  [_vm._v("Unstake " + _vm._s(_vm.getReadyForUnstake) + " MF")]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.isProcessing
        ? _c("preloader", { attrs: { translucent: "", black: "" } })
        : _vm._e(),
      _c("MetamaskPopup", { ref: "metamaskPopup" }),
      _c("GetTokensPopup", { ref: "getTokensPopup" }),
      _c("unstake-success-popup", { ref: "unstakeSuccessPopup" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }