import ApiEvents from "../../service/ApiEvents";


export default {
    namespaced: true,
    state() {
        return {
            infoBanner: null,
            eventsBanners: null
        }
    },

    getters: {
        GET_INFO_BANNER: state => state.infoBanner,
        GET_EVENTS_BANNERS: state => state.eventsBanners
    },

    mutations: {
        SET_INFO_BANNER(state, payload) {
            state.infoBanner = payload;
        },
        SET_EVENTS_BANNERS(state, payload) {
            state.eventsBanners = payload;
        }
    },

    actions: {
        async REQUEST_INFO_BANNER({ commit }) {
            const { data } = await ApiEvents.getInfoBanner();
            
            commit('SET_INFO_BANNER', data);
        },
        async REQUEST_EVENTS_BANNERS({ commit }) {
            const { data } = await ApiEvents.getEventsBanners();
            
            commit('SET_EVENTS_BANNERS', data.game_events);
        }
    }
}