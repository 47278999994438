import ApiStakes from '@/service/ApiStakes';

export default {
    namespaced: true,
    state() {
        return {
            stakes: [],
            count: 0,
            isLoading: false
        };
    },

    getters: {
        stakes: (state) => state.stakes,
        isLoading: (state) => state.isLoading,
        count: (state) => state.count
    },

    mutations: {
        setStakes(state, { stakes, count }) {
            state.stakes = stakes;
            state.count = count;
        },
        setIsLoading(state, payload) {
            state.isLoading = payload;
        }
    },

    actions: {
        async getStakes({commit}, { page, pageSize, type }) {
            try {
                commit('setIsLoading', true);
                const { data } = await ApiStakes.getStakes(page, pageSize, type);
                commit('setStakes', {
                    stakes: data.results,
                    count: data.count
                });
            } catch (e) {
                return e;
            } finally {
                commit('setIsLoading', false);
            }
        }
    }
}