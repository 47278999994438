import ApiWallet from "@/service/ApiWallet";

export default {
    namespaced: true,
    state() {
        return {
            gameBalance: 0,
            sendTokensTtl: 0,
        }
    },

    getters: {
        gameBalance: (state) => state.gameBalance,
        sendTokensTtl: (state) => state.sendTokensTtl,
    },

    mutations: {
        setGameBalance(state, payload) {
            state.gameBalance = payload;
        },
        setSetTokensTtl(state, payload) {
            state.sendTokensTtl = payload;
        }
    },

    actions: {
        async fetchGameBalance({commit, state, rootGetters}) {
            const {data: balanceData} = await ApiWallet.getGameBalance(rootGetters.account);

            commit('setGameBalance', balanceData.wallet.available_balance);

            if (!state.sendTokensTtl) {
                const {data: sendTokensTtlData} = await ApiWallet.getSendTokensTtl();

                commit('setSetTokensTtl', sendTokensTtlData.vrs_ttl);
            }
        }
    },
}
