import { SmartContract, SmartContractABI } from '..';

const contractName = 'SpotTokenManagement';
const contractABI = new SmartContractABI();
const contractAddress = process.env.VUE_APP_SPOT_TOKEN_MANAGEMENT_ADDRESS;

const sendTokensABI = {
  inputs: [
    {
      internalType: 'address',
      name: '_user',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_expireAt',
      type: 'uint256',
    },
    {
      internalType: 'bytes32',
      name: '_salt',
      type: 'bytes32',
    },
    {
      internalType: 'bytes',
      name: 'vrs',
      type: 'bytes',
    },
  ],
  name: 'sendTokens',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

const spotTransferABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256',
    },
    {
      internalType: 'bytes32',
      name: '_salt',
      type: 'bytes32',
    },
    {
      internalType: 'bytes',
      name: 'vrs',
      type: 'bytes',
    },
  ],
  name: 'spotTransfer',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

const buyItemABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_type',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256',
    },
    {
      internalType: 'bytes32',
      name: '_salt',
      type: 'bytes32',
    },
    {
      internalType: 'bytes',
      name: 'vrs',
      type: 'bytes',
    },
  ],
  name: 'buyItem',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

contractABI.add(sendTokensABI);
contractABI.add(spotTransferABI);
contractABI.add(buyItemABI);

const SPOT_TOKEN_MANAGEMENT_CONTRACT = new SmartContract(
  contractName,
  contractABI.ABI,
  contractAddress
);

export default SPOT_TOKEN_MANAGEMENT_CONTRACT;
