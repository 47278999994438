import {mapActions, mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters(['isLoggedIn', 'GET_IS_MOBILE', 'GET_IS_TABLET', 'account', 'GET_IS_DEVELOPMENT']),
        ...mapGetters('auth', ['token']),
        ...mapGetters('contract', ['contractService']),

        isNeedToLogin() {
            return !this.isLoggedIn && this.account;
        },
    },
    methods: {
        ...mapActions('auth', ['login']),
        ...mapActions(['connectWallet']),

        async connectToMeta() {
            if (this.isNeedToLogin && this.contractService?._provider) {
                this.login();

                return;
            }

            if(!this.GET_IS_MOBILE) {
                this.$refs.metamaskPopup.open();
            } else {
                this.connectWallet();
            }
        },
    }
}
