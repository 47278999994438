const BP_MB = 640;
const BP_TB = 991;

export default {
  state() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      isMobile: BP_MB >= window.innerWidth,
      isTablet: window.innerWidth > BP_MB && window.innerWidth <= BP_TB,
      isDesktop: BP_MB < window.innerWidth,
      isCookieAlertShow: JSON.parse(localStorage.cookieAlert || sessionStorage.cookieAlert || 'true'),
      isDevelopment: process.env.NODE_ENV === 'development',
    }
  },

  getters: {
    GET_WINDOW_WIDTH: (state) => state.windowWidth,
    GET_WINDOW_HEIGHT: (state) => state.windowHeight,
    GET_IS_MOBILE: (state) => state.isMobile,
    GET_IS_TABLET: (state) => state.isTablet,
    GET_IS_COOKIE_ALERT_SHOW: (state) => state.isCookieAlertShow,
    GET_IS_DEVELOPMENT: (state) => state.isDevelopment,
  },

  mutations: {
    UPDATE_DEVICE_SETTINGS(state) {
      state.windowWidth = window.innerWidth
      state.windowHeight = window.innerHeight

      state.isMobile = BP_MB >= window.innerWidth
      state.isDesktop = BP_MB < window.innerWidth
      state.isTablet = window.innerWidth > BP_MB && window.innerWidth <= BP_TB
    },

    SET_IS_COOKIE_ALERT_SHOW(state, type = 'hide') {
      const storage = type === 'accept' ? localStorage : sessionStorage
      storage.cookieAlert = state.isCookieAlertShow = false
    },
  },
}
