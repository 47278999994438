<template>
    <div>
        <popover
            name="what-is-passive"
            :width="365"
            event="hover"
        >
            <div class="passive">
                <h2 class="passive-title">WHAT IS PASSIVE</h2>

                <div class="passive__content">
                    <div class="passive__content-text">
                        <p>
                            It is activated by default, depending on how much MF-Credits you have in your balance.
                        </p>
                    </div>
                    <div class="passive__content-block">
                        <p v-for="rule in boostersRules.passive_boosters" :key="rule.rate">
                            {{toLocaleFormat(rule.tokens_amount)}} mf = {{rule.rate}}X
                        </p>
                    </div>
                </div>
            </div>
        </popover>

        <popover
            name="new-nft"
            :width="GET_IS_MOBILE ? 200 : 365"
            event="hover"
            class="new-nft"
            :class="{ 'new-nft--mobile': GET_IS_MOBILE }"
        >
            <div>
                Get a FREE character by staking your $MF tokens.
                After {{convertToTime(stakingInfo.random_nft?.duration)}}, find your minted character in the "MY NFTs" section and withdraw the amount staked.
            </div>
        </popover>

        <popover
            name="exp-boosters"
            :width="GET_IS_MOBILE ? 200 : 365"
            event="hover"
            class="new-nft"
            :class="{ 'new-nft--mobile': GET_IS_MOBILE }"
        >
            <div>
                Get a FREE experience booster by staking your $MF tokens.
                The booster will be available for
                <span v-for="(booster, index) in boosters" :key="booster.id">
                    {{convertToTime(booster.duration)}} ({{booster.name}})
                    {{boosters.length > index + 1  ? ' or ' : ','}}
                </span>
                after which you will be able to get your tokens back.
            </div>
        </popover>

        <popover
            name="skills"
            :width="GET_IS_MOBILE ? 200 : 365"
            event="hover"
            class="new-nft"
            :class="{ 'new-nft--mobile': GET_IS_MOBILE }"
        >
            <div>
                Get a FREE Active or Passive skill by staking your $MF tokens.
                The skill will be available for {{convertToTime(stakingInfo.skill_active?.duration)}}, after which you will be able to get your tokens back.
            </div>
        </popover>

        <popover
            name="tier-common"
            event="hover"
            class="tier-popover"
        >
            <span>
                COMMON
            </span>
        </popover>

        <popover
            name="tier-fighter"
            event="hover"
            class="tier-popover"
        >
            <span>
                FIGHTER
            </span>
        </popover>

        <popover
            name="tier-ultimate"
            event="hover"
            class="tier-popover"
        >
            <span>
                ULTIMATE
            </span>
        </popover>

        <popover
            name="tier-epic"
            event="hover"
            class="tier-popover"
        >
            <span>
                EPIC
            </span>
        </popover>

        <popover
            name="tier-legendary"
            event="hover"
            class="tier-popover"
        >
            <span>
                LEGENDARY
            </span>
        </popover>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {convertToTime} from '@/utils/helpers'

export default {
    name: "popovers",
    computed: {
        ...mapGetters(['GET_IS_MOBILE']),
        ...mapGetters('generalData', ['boosters', 'boostersRules', 'stakingInfo']),
    },
    methods: {
        convertToTime,
    }
}
</script>

<style lang="scss" scoped>
.vue-popover {
    padding: 0;
    transform: skewX(-4deg);

    &[data-popover="what-is-passive"] {
        background: #CBD3E1;
        border-radius: 0;
        margin-left: 150px;

        &:before {
            border-top: 10px solid #CBD3E1;
            bottom: -9px;
            margin-left: -140px;
        }

        @media screen and (max-width: $bp_mb) {
            margin-left: 20px;

            &:before {
                margin-left: -5px;
            }
        }
    }
}

.passive {
    padding: 20px;
    transform: skewX(4deg);

    &-title {
        font-weight: 800;
        font-size: 24px;
        line-height: 28px;
    }

    &__content {
        font-weight: 500;
        display: flex;
        margin-top: 22px;
        gap: 10px;
        font-size: 14px;

        &-text {
            width: 100%;
        }

        &-block {
            padding: 7px 14px;
            background: white;
            width: 90%;
        }
    }
}

.new-nft {
    padding: 1em;
    background: #CBD3E1;
    margin-top: 8px;

    &.vue-popover.dropdown-position-bottom:before {
        border-bottom: 6px solid #CBD3E1!important;
    }
    &.vue-popover.dropdown-position-right:before {
        border-right: 6px solid #CBD3E1!important;
    }

    &--mobile {
        margin-left: -80px;

        &:before {
            left: calc(87%)!important;
        }
    }
}

.tier-popover {
    width: 120px!important;
    margin-left: 50px;
    padding: 15px;
    border-radius: 0;

    &:before {
        margin-left: -40px;
    }
}

</style>
