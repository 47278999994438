// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".hint-btn {\n  position: relative;\n  z-index: 1;\n  margin: 10px;\n  min-width: 36px;\n  min-height: 31px;\n  border: 2px solid #000;\n  color: #000;\n}\n.hint-btn.active, .hint-btn:hover {\n  background-color: #000;\n  color: #fff;\n}\n.hint-btn__content {\n  display: block;\n  text-transform: uppercase;\n  font: 800 25px/1 \"Tektur\";\n}\n.hint-btn__arrows {\n  position: absolute;\n  z-index: -1;\n  top: -7px;\n  left: -7px;\n  right: -7px;\n  bottom: -7px;\n  border: 1px solid white;\n  color: #000;\n  border: 1px solid;\n  pointer-events: none;\n}\n.hint-btn__arrows:before, .hint-btn__arrows:after {\n  content: \"\";\n  position: absolute;\n  z-index: -1;\n  top: 50%;\n  left: -1px;\n  margin-top: -5px;\n  border: 4px solid;\n  border-right-color: transparent;\n  border-top-color: transparent;\n  border-bottom-color: transparent;\n}\n.hint-btn__arrows:after {\n  left: unset;\n  right: -1px;\n  transform: scaleX(-1);\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
