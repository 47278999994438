export default {
  state() {
    return {
      watchedMarks: JSON.parse(localStorage.marks || '[]'),
    }
  },

  getters: {
    GET_WATCHED_MARKS: (state) => state.watchedMarks,
    IS_MARK_WATCHED: (state) => (element) => state.watchedMarks.includes(element),
  },

  mutations: {
    SAVE_WATCHED_MARKS(state, marks) {
      marks = Array.isArray(marks) ? marks : [marks]
      state.watchedMarks.push(...marks)
      localStorage.marks = JSON.stringify(state.watchedMarks)
    },
  },
}
