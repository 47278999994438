// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cookie-alert {\n  position: fixed;\n  z-index: 999999999;\n  left: 15px;\n  right: 15px;\n  bottom: 30px;\n  margin: 0 auto;\n  max-width: 860px;\n  padding: 15px 30px;\n  border: 2px solid #000;\n  background-color: #fff;\n  color: #000;\n  display: flex;\n  align-items: center;\n}\n.cookie-alert__text {\n  flex-grow: 1;\n  margin-right: 15px;\n  font-size: 14px;\n}\n.cookie-alert__buttons {\n  display: flex;\n  align-items: center;\n}\n.cookie-alert__buttons-item {\n  margin-right: 15px;\n  min-width: 100px;\n}\n.cookie-alert__buttons-item:last-child {\n  margin-right: 0;\n}\n@media screen and (max-width: 777px) {\n.cookie-alert {\n    left: 5px;\n    right: 5px;\n    bottom: 5px;\n    padding: 10px;\n    flex-direction: column;\n    align-items: stretch;\n}\n.cookie-alert__text {\n    margin: 0;\n}\n.cookie-alert__buttons {\n    margin-top: 10px;\n}\n.cookie-alert__buttons-item {\n    flex-grow: 1;\n    max-width: 100%;\n}\n.cookie-alert__buttons-item:last-child {\n    margin-right: 0;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
