var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "popover",
          rawName: "v-popover.bottom",
          value: { name: "balance" },
          expression: "{ name: 'balance' }",
          modifiers: { bottom: true },
        },
      ],
      staticClass: "balance",
    },
    [
      _c(
        "div",
        { staticClass: "balance--selected" },
        [
          _c("span", { staticClass: "balance-label balance-label--arrow" }, [
            _vm._v("Balance"),
          ]),
          _vm.isBalanceLoading
            ? _c("Loader", { staticClass: "balance-loader", attrs: { xs: "" } })
            : [
                _vm.isCorrectNetwork
                  ? _c("span", { staticClass: "balance-span" }, [
                      _vm._v(_vm._s(_vm.formattedBalance) + " mf"),
                    ])
                  : _c(
                      "span",
                      {
                        staticClass: "balance-btn",
                        on: { click: _vm.switchNetwork },
                      },
                      [_vm._v("Incorrect network")]
                    ),
              ],
        ],
        2
      ),
      !_vm.isBalanceLoading
        ? _c(
            "portal",
            { attrs: { to: "main-popup" } },
            [
              _vm.isCorrectNetwork
                ? _c(
                    "popover",
                    {
                      staticClass: "balance-popover",
                      attrs: { name: "balance", event: "hover" },
                    },
                    [
                      _c("div", {}, [
                        _c("span", { staticClass: "balance-label" }, [
                          _vm._v("Staked: "),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.getHoldFt) + " mf")]),
                      ]),
                      _c("div", {}, [
                        _c("span", { staticClass: "balance-label" }, [
                          _vm._v("Credits: "),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formattedGameBalance) + " mf"),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }