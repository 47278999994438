import CharactersConfig from "@/constants/CharactersConfig";
import apiCall from '@/utils/apiCall';
import endpoints from '@/constants/Endpoints';

export default class ApiAuth {
  static characters() {
    return apiCall(endpoints.characters.getDefaults);
  }

  static character(type) {
    return apiCall(endpoints.characters.getCharacter, {}, `${type}/`);
  }

  static myCharacters(account, page, pageSize = null) {
    return apiCall(endpoints.characters.getMyCharacters, { params: { owner: account, page_size: pageSize || CharactersConfig.pageSize, page} });
  }

  static myCharacter(id) {
    return apiCall(endpoints.characters.getMyCharacter, {}, `${id}/`);
  }

  static statsLevelUp(tokenId, stat) {
    const formData = new FormData();

    formData.append('token_id', tokenId);
    formData.append('stat', stat);

    return apiCall(endpoints.characters.statsLevelUp, formData);
  }

  static freeTierUp(tokenId) {
    const formData = new FormData();

    formData.append('token_id', tokenId);

    return apiCall(endpoints.characters.freeTierUpTierUp, formData);
  }
}
