var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.GET_IS_COOKIE_ALERT_SHOW
    ? _c(
        "div",
        { staticClass: "cookie-alert", attrs: { "data-name": "cookie-alert" } },
        [
          _c("div", { staticClass: "cookie-alert__text" }, [
            _vm._v(
              ' By clicking "Accept", you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. '
            ),
          ]),
          _c(
            "div",
            { staticClass: "cookie-alert__buttons" },
            [
              _c("btn", {
                staticClass: "cookie-alert__buttons-item",
                attrs: { type: "icon", text: "Hide" },
                on: { click: _vm.onHide },
              }),
              _c("btn", {
                staticClass: "cookie-alert__buttons-item",
                attrs: { type: "icon-gradient", text: "Accept" },
                on: { click: _vm.onAccept },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }