import ApiBoosters from "@/service/ApiBoosters";
import ApiStaking from "@/service/ApiStaking";

export default {
	namespaced: true,
    state() {
        return {
			boosters: [],
			myBoosters: [],
			stakingInfo: {},
			boostersRules: {},
		}
    },

	mutations: {
		setBoosters(state, payload) {
			state.boosters = payload;
		},
		setMyBoosters(state, payload) {
			state.myBoosters = payload;
		},
		setStakingInfo(state, payload) {
			state.stakingInfo = payload;
		},
		setBoostersRules(state, payload) {
			state.boostersRules = payload;
		},
	},

	getters: {
		boosters: (state) => {
			// TODO: лютый костыль - удалить как можно скорее - получить от бэка id бустера в stakingInfo
			state.boosters.forEach((booster) => {
				Object.entries(state.stakingInfo).forEach(([key, value]) => {
					if (!key.includes('exp_booster')) {
						return;
					}

					if (key.includes(booster.name.toLowerCase())) {
						booster.stakingPrice = value.price;
						booster.stakingUsdPrice = value.usd_price;
					}
				});
			});

			return state.boosters;
		},
		myBoosters: (state) => state.myBoosters,
		stakingInfo: (state) => state.stakingInfo,
		boostersRules: (state) => state.boostersRules,
	},

    actions: {
		async getBoosters({commit}) {
			const { data } = await ApiBoosters.getBoosters();

			commit('setBoosters', data);
		},

		async getBoostersRules({commit}) {
			const { data } = await ApiBoosters.getBoostersRules();

			commit('setBoostersRules', data);
		},

		async getMyBoosters({commit, rootGetters}) {
			const { data } = await ApiBoosters.getMyBoosters(rootGetters.account);

			commit('setMyBoosters', data);
		},

		async getStakingInfo({commit}) {
			const { data } = await ApiStaking.getStakingInfo();

			commit('setStakingInfo', data);
		}
    },
}

