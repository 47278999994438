<template>
  <popup v-model="popup" @input="close" content-class="stake-skill-popup">
    <div>
      <div class="stake-skill-popup__content">
        <h3 class="stake-skill-popup__title">Stake {{ isActiveSkill ? 'active' : 'passive' }} skill</h3>
        <div class="stake-skill-popup__desc">
          <template v-if="isActiveSkill">
            You can stake your active skill for <b>{{ currentSkillPrice }}</b> MF.
          </template>

          <template v-else>
            You can stake your passive skill for <b>{{ currentSkillPrice }}</b> MF.
            Choose which characteristic will be affected by the passive skill.

            <div class="stake-skill-popup__radios">
              <div class="stake-skill-popup__radios-side">
                <div class="stake-skill-popup__radios-item">
                  <span>Attack</span>
                  <radio
                      name="Attack"
                      val="Attack"
                      v-model="passiveType.value"
                  />
                </div>
                <div class="stake-skill-popup__radios-item">
                  <span>Defense</span>
                  <radio
                      name="Defense"
                      val="Defense"
                      v-model="passiveType.value"
                  />
                </div>
              </div>

              <div class="stake-skill-popup__radios-side">
                <div class="stake-skill-popup__radios-item">
                  <span>Stamina</span>
                  <radio
                      name="Stamina"
                      val="Stamina"
                      v-model="passiveType.value"
                  />
                </div>
                <div class="stake-skill-popup__radios-item">
                  <span>Speed</span>
                  <radio
                      name="Speed"
                      val="Speed"
                      v-model="passiveType.value"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="stake-skill-popup__buttons">
        <btn
            type="bg-white"
            class="stake-skill-popup__buttons-item"
            text="Cancel"
            @click="close"
        />
        <btn
            class="stake-skill-popup__buttons-item"
            type="icon-gradient"
            text=" ? MF"
            :selected="`${currentSkillPrice}`"
            :rightText="`${currentSkillPrice} MF`"
            :hoverText="`stake ${currentSkillPriceUsd} $`"
            :altText="`stake ${currentSkillPrice} MF`"
            @click="stakeSkill"
        />
      </div>
      <p class="stake-skill-popup__warning-text">If you cancel staking, the next attempt can only be made after <b>15 minutes</b>.</p>
    </div>
  </popup>
</template>

<script>
import {mapGetters} from "vuex";

const passiveSkillTypes = {
  Attack: 0,
  Defense: 1,
  Speed: 2,
  Stamina: 3,
}

export default {
  name: "StakeSkillPopup",
  computed: {
    ...mapGetters('generalData', ['stakingInfo']),

    activeSkillPrice() {
      return {
        price: this.stakingInfo.skill_active?.price || 0,
        usd_price: this.stakingInfo.skill_active?.usd_price || 0,
      }
    },
    passiveSkillPrice() {
      return {
        price: this.stakingInfo.skill_passive?.price || 0,
        usd_price: this.stakingInfo.skill_passive?.usd_price || 0,
      }
    },
    currentSkillPrice() {
      return this.isActiveSkill ? this.activeSkillPrice.price : this.passiveSkillPrice.price;
    },
    currentSkillPriceUsd() {
      return this.isActiveSkill ? this.activeSkillPrice.usd_price : this.passiveSkillPrice.usd_price;
    }
  },
  data() {
    return {
      popup: false,
      isActiveSkill: null,
      token_id: null,
      passiveType: {
        value: 'Attack',
        options: [
          'Attack',
          'Defense',
          'Speed',
          'Stamina',
        ]
      },
    }
  },
  methods: {
    open(isActiveSkill, token_id) {
      this.isActiveSkill = isActiveSkill;
      this.token_id = token_id;
      this.popup = true;
    },
    close() {
      this.$emit('close');
      this.isActiveSkill = null;
      this.popup = false;
    },
    stakeSkill() {
      this.$emit('stakeSkillProcess', this.isActiveSkill, this.token_id, passiveSkillTypes[this.passiveType.value]);
      this.close();
    },
  }
}
</script>

<style lang="scss">

.stake-skill-popup {
  width: 100% !important;
  max-width: 645px !important;
  flex-direction: column;
  background-color: #C6CCD7 !important;
  padding: 2em 3em !important;
  border-radius: 15px !important;

  .popup__close-btn {
    background-color: transparent;

    .close-btn__element {
      background-color: black;
      height: 6px;
      width: 25px;
    }
  }
}

.stake-skill-popup__content {
  text-transform: uppercase;
  text-align: center;
  padding: 20px 20px 0 20px;
  margin-bottom: 20px;
}

.stake-skill-popup__title {
  text-align: center;
  text-transform: uppercase;
  font: 800 48px Raleway;
}

.stake-skill-popup__desc {
  text-transform: initial;
  margin: 22px 0 30px;
}

.stake-skill-popup__radios {
  margin-top: 15px;
  background-color: #3B3D41;
  color: white;
  padding: 40px 72px;
  display: flex;
  gap: 5em;

  &-side {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 50%;
    position: relative;

    &:first-child:after {
      content: '';
      position: absolute;
      width: 1px;
      background: white;
      top: 0;
      bottom: 0;
      right: -2.5em;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font: 600 18px Tektur;
  }
}

.stake-skill-popup__buttons {
  display: flex;
  gap: 40px;
  margin-bottom: 1em;

  &-item {
    width: 50%;
  }
}


.stake-skill-popup__warning-text {
  font-size: 0.8em;
  text-align: center;
}

@media screen and (max-width: $bp_mb) {
  .stake-skill-popup__title {
    font-size: 24px;
  }

  .stake-skill-popup__text {
    font-size: 12px;
  }

  .stake-skill-popup__buttons {
    gap: 10px;
    flex-direction: column;

    &-item {
      width: 100%;
    }
  }

  .stake-skill-popup__radios {
    padding: 20px 35px;
    flex-direction: column;
    gap: 1em;

    &-separator {
      display: none;
    }

    &-side {
      width: 100%;

      &:first-child:after {
        display: none;
      }
    }
  }

  .stake-skill-popup__content {
    padding: 0;
  }
}
</style>
