import {mapActions, mapGetters} from "vuex";
import StakeSuccessPopup from '@/components/popups/stake-success-popup.vue'
import UnstakeSuccessPopup from '@/components/popups/unstake-success-popup'
import TierUpSuccessPopup from '@/components/popups/tierup-success-popup.vue'
import StakeSkillPopup from '@/components/popups/stake-skill-popup.vue'
import ApprovePopup from '@/components/popups/approve-popup.vue'
import ApiStaking from "@/service/ApiStaking";
import BoostersMixin from "@/mixins/boostersMixin";

export default {
    mixins: [BoostersMixin],
    components: {
        StakeSuccessPopup,
        ApprovePopup,
        TierUpSuccessPopup,
        UnstakeSuccessPopup,
        StakeSkillPopup,
    },
    data() {
        return {
            isProcessing: false,
        }
    },
    computed: {
        ...mapGetters(['isLoggedIn', 'GET_IS_DEVELOPMENT']),
        ...mapGetters('generalData', ['stakingInfo']),
    },
    mounted() {
        // test functions
        window.stakeBooster = this.stakeBooster;
        window.stakeSkill = this.stakeSkill;

        if (Object.keys(this.stakingInfo).length === 0) {
            this.getStakingInfo();
        }
    },
    methods: {
        ...mapActions('contract', [
            'allowance',
            'approve',
            'stakeWithoutNFTOwnerChecking',
            'stakeWithNftOwnerChecking',
            'unstakeNft',
            'getBalance',
            'tierUpNft',
            'unstakeAll',
        ]),
        ...mapActions('generalData', ['getStakingInfo']),

        // stakeType: 2 - passive; 3 - active
        async stakeSkill(stakeType, tokenId, passiveSkillType = 0) {
            if (!this.isLoggedIn) {
                this.$eventBus.$emit('errorPopup', '', 'Please connect to the Metamask through the Metafighter website');

                return;
            }

            this.isProcessing = true;

            try {
                const vrsData = await ApiStaking.getStakingSkills(passiveSkillType, stakeType, tokenId);
                const result = await this.stakeWithNftOwnerChecking({...vrsData.data, idNft: tokenId});

                await this.getBalance();

                this.$refs.stakeSuccessPopup.open(result.transactionHash, 'successful')
            } catch (error) {
                let message = error.message
                if(message === 'Request failed with status code 403') {
                    message = 'You have exhausted available all the staking attempts. Please try again in 15 minutes.'
                }
                this.$eventBus.$emit('errorPopup', '', message);
            }

            this.isProcessing = false;
        },

        // stakeType - 4-2x_booster; 5-4x_booster
        async stakeBooster(stakingTypeId) {
            if (!this.isLoggedIn) {
                this.$eventBus.$emit('errorPopup', '', 'Please connect to the Metamask through the Metafighter website');

                return;
            }

            switch (stakingTypeId) {
                case 1: {
                    stakingTypeId = 4;
                    break;
                }
                case 2: {
                    stakingTypeId = 5;
                    break;
                }
                default: {
                    stakingTypeId = null;
                }
            }

            if (!stakingTypeId) {
                this.$eventBus.$emit('errorPopup', '', 'Incorrect booster data');
                return;
            }

            this.isProcessing = true;

            try {
                const vrsData = await ApiStaking.getVrs(0, stakingTypeId);
                const result = await this.stakeWithoutNFTOwnerChecking(vrsData.data);

                await this.getBalance();

                this.$refs.stakeSuccessPopup.open(result.transactionHash, 'successful')
            } catch (error) {
                this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
            }

            this.isProcessing = false;
        },
        async onNftStakeClick(character) {
            if (!this.isLoggedIn) {
                this.$eventBus.$emit('errorPopup', '', 'Please connect to the Metamask through the Metafighter website');

                return;
            }
            this.isProcessing = true;

            const allowance = await this.allowance();

            if (allowance === 'error') {
                return;
            }

            if (this.fromWei(allowance) < Number(character.staking_price)) {
                // лимит на снятия средств меньше чем стоимость (показываем попап с описанием)
                this.$refs.approvePopup.open(character.staking_price, character);
            } else {
                // можно минтить
                await this.stakeCharacter(character);
            }

            this.isProcessing = false;
        },
        async stakeCharacter(character, stakeType = '1') {
            this.isProcessing = true;

            try {
                const vrsData = await ApiStaking.getVrs(character.character_id, stakeType);
                const result = await this.stakeWithoutNFTOwnerChecking(vrsData.data);

                await this.getBalance();

                this.$refs.stakeSuccessPopup.open(result.transactionHash);
            } catch (error) {
                this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
            }

            this.isProcessing = false;
        },
        async onUnstakeNftClick(character) {
            this.isProcessing = true;

            try {
                const result = await this.unstakeNft(character.stake_id);

                await this.getBalance();

                this.$refs.unstakeSuccessPopup.open(result.transactionHash)
            } catch (error) {
                this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
            }

            this.isProcessing = false;
        },
        async onUnstakeAllClick() {
            this.isProcessing = true;

            try {
                const result = await this.unstakeAll();

                await this.getBalance();

                this.$refs.unstakeSuccessPopup.open(result.transactionHash)
            } catch (error) {
                this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
            }

            this.isProcessing = false;
        },
        async onTierUpClick(character) {
            if (!this.isLoggedIn) {
                this.$eventBus.$emit('errorPopup', '', 'Please connect to the Metamask through the Metafighter website');

                return;
            }

            this.isProcessing = true;

            const allowance = await this.allowance();

            this.isProcessing = false;

            if (allowance === 'error') {
                return;
            }

            if (this.fromWei(allowance) < Number(character.staking_price)) {
                // лимит на снятия средств меньше чем стоимость (показываем попап с описанием)
                this.$refs.approvePopup.open(character.staking_price, character);
            } else {
                // можно минтить
                this.tierUp(character);
            }
        },
        async tierUp(character) {
            this.isProcessing = true;

            try {
                const vrsData = await ApiStaking.getTierUpVrs(
                    character.mf_attributes.token_id,
                    character.mf_attributes.tier + 1,
                );
                const result = await this.tierUpNft(vrsData.data);

                await this.getBalance();

                this.$refs.tierUpSuccessPopup.open(result.transactionHash)
            } catch (error) {
                this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
            }

            this.isProcessing = false;
        },
        stakeSkillPopup(type, token_id) {
            if (!this.GET_IS_DEVELOPMENT) {
                return;
            }
            
            if (!this.isLoggedIn) {
                this.$eventBus.$emit('errorPopup', '', 'Please connect to the Metamask through the Metafighter website');

                return;
            }

            this.$refs.stakeSkillPopup?.open(type === 'active', token_id);
        },
        stakeSkillProcess(isActiveSkill, token_id, passiveSkillType) {
            this.stakeSkill(isActiveSkill ? 3 : 2, token_id, passiveSkillType);
        },
    },
}
