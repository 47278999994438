var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { staticClass: "radio" }, [
    _c("input", {
      staticClass: "radio__field",
      attrs: { type: "radio", name: _vm.name },
      domProps: { value: _vm.value, checked: _vm.val == _vm.value },
      on: {
        change: function ($event) {
          return _vm.$emit("input", _vm.val)
        },
      },
    }),
    _c("i", { staticClass: "radio__bg" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }