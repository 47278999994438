// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".popup {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  overflow-y: auto;\n  z-index: 999;\n}\n.popup__inner {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 20px 15px;\n  min-height: 100%;\n}\n.popup__view {\n  position: relative;\n  z-index: 2;\n  display: flex;\n  justify-content: center;\n  padding: 70px 25px 70px 25px;\n  min-width: 60px;\n  width: -moz-max-content;\n  width: max-content;\n  max-width: 100%;\n  background-color: #FFFFFF;\n  color: #000;\n}\n.popup__view.error-popup {\n  max-width: 75%;\n  overflow: hidden;\n  word-break: break-word;\n}\n.popup__bg {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 0;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n          user-select: none;\n  background-color: rgba(0, 0, 0, 0.75);\n  cursor: pointer;\n}\n.popup__close-btn {\n  position: absolute;\n  top: 0;\n  right: 0;\n}\n.popup--trailer .popup__view {\n  padding: 40px 20px;\n  background-color: transparent;\n}\n@media screen and (max-width: 639px) {\n.popup--trailer .popup__close-btn {\n    width: 40px;\n    height: 40px;\n}\n.popup--trailer .popup__view {\n    padding: 40px 0;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
