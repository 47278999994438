var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "popover",
        { attrs: { name: "what-is-passive", width: 365, event: "hover" } },
        [
          _c("div", { staticClass: "passive" }, [
            _c("h2", { staticClass: "passive-title" }, [
              _vm._v("WHAT IS PASSIVE"),
            ]),
            _c("div", { staticClass: "passive__content" }, [
              _c("div", { staticClass: "passive__content-text" }, [
                _c("p", [
                  _vm._v(
                    " It is activated by default, depending on how much MF-Credits you have in your balance. "
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "passive__content-block" },
                _vm._l(_vm.boostersRules.passive_boosters, function (rule) {
                  return _c("p", { key: rule.rate }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.toLocaleFormat(rule.tokens_amount)) +
                        " mf = " +
                        _vm._s(rule.rate) +
                        "X "
                    ),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "popover",
        {
          staticClass: "new-nft",
          class: { "new-nft--mobile": _vm.GET_IS_MOBILE },
          attrs: {
            name: "new-nft",
            width: _vm.GET_IS_MOBILE ? 200 : 365,
            event: "hover",
          },
        },
        [
          _c("div", [
            _vm._v(
              " Get a FREE character by staking your $MF tokens. After " +
                _vm._s(
                  _vm.convertToTime(_vm.stakingInfo.random_nft?.duration)
                ) +
                ', find your minted character in the "MY NFTs" section and withdraw the amount staked. '
            ),
          ]),
        ]
      ),
      _c(
        "popover",
        {
          staticClass: "new-nft",
          class: { "new-nft--mobile": _vm.GET_IS_MOBILE },
          attrs: {
            name: "exp-boosters",
            width: _vm.GET_IS_MOBILE ? 200 : 365,
            event: "hover",
          },
        },
        [
          _c(
            "div",
            [
              _vm._v(
                " Get a FREE experience booster by staking your $MF tokens. The booster will be available for "
              ),
              _vm._l(_vm.boosters, function (booster, index) {
                return _c("span", { key: booster.id }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.convertToTime(booster.duration)) +
                      " (" +
                      _vm._s(booster.name) +
                      ") " +
                      _vm._s(_vm.boosters.length > index + 1 ? " or " : ",") +
                      " "
                  ),
                ])
              }),
              _vm._v(" after which you will be able to get your tokens back. "),
            ],
            2
          ),
        ]
      ),
      _c(
        "popover",
        {
          staticClass: "new-nft",
          class: { "new-nft--mobile": _vm.GET_IS_MOBILE },
          attrs: {
            name: "skills",
            width: _vm.GET_IS_MOBILE ? 200 : 365,
            event: "hover",
          },
        },
        [
          _c("div", [
            _vm._v(
              " Get a FREE Active or Passive skill by staking your $MF tokens. The skill will be available for " +
                _vm._s(
                  _vm.convertToTime(_vm.stakingInfo.skill_active?.duration)
                ) +
                ", after which you will be able to get your tokens back. "
            ),
          ]),
        ]
      ),
      _c(
        "popover",
        {
          staticClass: "tier-popover",
          attrs: { name: "tier-common", event: "hover" },
        },
        [_c("span", [_vm._v(" COMMON ")])]
      ),
      _c(
        "popover",
        {
          staticClass: "tier-popover",
          attrs: { name: "tier-fighter", event: "hover" },
        },
        [_c("span", [_vm._v(" FIGHTER ")])]
      ),
      _c(
        "popover",
        {
          staticClass: "tier-popover",
          attrs: { name: "tier-ultimate", event: "hover" },
        },
        [_c("span", [_vm._v(" ULTIMATE ")])]
      ),
      _c(
        "popover",
        {
          staticClass: "tier-popover",
          attrs: { name: "tier-epic", event: "hover" },
        },
        [_c("span", [_vm._v(" EPIC ")])]
      ),
      _c(
        "popover",
        {
          staticClass: "tier-popover",
          attrs: { name: "tier-legendary", event: "hover" },
        },
        [_c("span", [_vm._v(" LEGENDARY ")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }