// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".question-popup {\n  max-width: 570px !important;\n  background-color: #C6CCD7 !important;\n  padding: 2em 3em !important;\n  border-radius: 15px;\n}\n.question-popup__content,\n.question-popup__desc {\n  margin: 1.5em 0;\n  text-align: center;\n  font: 400 16px Tektur;\n}\n.question-popup__title {\n  text-align: center;\n  text-transform: uppercase;\n  font: 800 38px Raleway;\n}\n.question-popup__text {\n  margin-top: 1em;\n}\n.question-popup__buttons {\n  margin-top: 20px;\n  display: flex;\n  justify-content: center;\n}\n.question-popup__buttons-item {\n  margin-right: 15px;\n}\n.question-popup__buttons-item:last-child {\n  margin-right: 0;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
