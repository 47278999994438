var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popup",
    {
      attrs: { "content-class": "stake-skill-popup" },
      on: { input: _vm.close },
      model: {
        value: _vm.popup,
        callback: function ($$v) {
          _vm.popup = $$v
        },
        expression: "popup",
      },
    },
    [
      _c("div", [
        _c("div", { staticClass: "stake-skill-popup__content" }, [
          _c("h3", { staticClass: "stake-skill-popup__title" }, [
            _vm._v(
              "Stake " +
                _vm._s(_vm.isActiveSkill ? "active" : "passive") +
                " skill"
            ),
          ]),
          _c(
            "div",
            { staticClass: "stake-skill-popup__desc" },
            [
              _vm.isActiveSkill
                ? [
                    _vm._v(" You can stake your active skill for "),
                    _c("b", [_vm._v(_vm._s(_vm.currentSkillPrice))]),
                    _vm._v(" MF. "),
                  ]
                : [
                    _vm._v(" You can stake your passive skill for "),
                    _c("b", [_vm._v(_vm._s(_vm.currentSkillPrice))]),
                    _vm._v(
                      " MF. Choose which characteristic will be affected by the passive skill. "
                    ),
                    _c("div", { staticClass: "stake-skill-popup__radios" }, [
                      _c(
                        "div",
                        { staticClass: "stake-skill-popup__radios-side" },
                        [
                          _c(
                            "div",
                            { staticClass: "stake-skill-popup__radios-item" },
                            [
                              _c("span", [_vm._v("Attack")]),
                              _c("radio", {
                                attrs: { name: "Attack", val: "Attack" },
                                model: {
                                  value: _vm.passiveType.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.passiveType, "value", $$v)
                                  },
                                  expression: "passiveType.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "stake-skill-popup__radios-item" },
                            [
                              _c("span", [_vm._v("Defense")]),
                              _c("radio", {
                                attrs: { name: "Defense", val: "Defense" },
                                model: {
                                  value: _vm.passiveType.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.passiveType, "value", $$v)
                                  },
                                  expression: "passiveType.value",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "stake-skill-popup__radios-side" },
                        [
                          _c(
                            "div",
                            { staticClass: "stake-skill-popup__radios-item" },
                            [
                              _c("span", [_vm._v("Stamina")]),
                              _c("radio", {
                                attrs: { name: "Stamina", val: "Stamina" },
                                model: {
                                  value: _vm.passiveType.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.passiveType, "value", $$v)
                                  },
                                  expression: "passiveType.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "stake-skill-popup__radios-item" },
                            [
                              _c("span", [_vm._v("Speed")]),
                              _c("radio", {
                                attrs: { name: "Speed", val: "Speed" },
                                model: {
                                  value: _vm.passiveType.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.passiveType, "value", $$v)
                                  },
                                  expression: "passiveType.value",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ]),
        _c(
          "div",
          { staticClass: "stake-skill-popup__buttons" },
          [
            _c("btn", {
              staticClass: "stake-skill-popup__buttons-item",
              attrs: { type: "bg-white", text: "Cancel" },
              on: { click: _vm.close },
            }),
            _c("btn", {
              staticClass: "stake-skill-popup__buttons-item",
              attrs: {
                type: "icon-gradient",
                text: " ? MF",
                selected: `${_vm.currentSkillPrice}`,
                rightText: `${_vm.currentSkillPrice} MF`,
                hoverText: `stake ${_vm.currentSkillPriceUsd} $`,
                altText: `stake ${_vm.currentSkillPrice} MF`,
              },
              on: { click: _vm.stakeSkill },
            }),
          ],
          1
        ),
        _c("p", { staticClass: "stake-skill-popup__warning-text" }, [
          _vm._v(
            "If you cancel staking, the next attempt can only be made after "
          ),
          _c("b", [_vm._v("15 minutes")]),
          _vm._v("."),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }