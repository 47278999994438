<template>
    <label class="checkbox">
        <input
            :id="name"
            type="checkbox"
            :checked="checked"
            @change="change"
            class="checkbox-input"
        >

        <span class="checkbox-checkmark" />

        <span class="checkbox-label">{{label}}</span>
    </label>
</template>

<script>
export default {
    name: "CheckBox",
    props: {
        name: { type: String, default: '' },
        label: { type: String, default: '' },
        value: { type: Boolean, default: false },
    },
    watch: {
        value(val) {
            this.checked = val;
        }
    },
    data() {
        return {
            checked: this.value
        };
    },
    methods: {
        change() {
            this.checked = !this.checked;
            this.$emit('input', this.checked);
        }
    },
}
</script>

<style lang="scss">

.checkbox {
    display: flex;
    gap: 0.75em;
    cursor: pointer;
    font: 500 12px Tektur;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkbox-checkmark {
            background-color: black;

            &:after {
                display: block;
            }
        }
    }

    &-checkmark {
        position: relative;
        height: 1em;
        width: 1em;
        border: 2px solid black;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 1px;
            top: -1px;
            width: 5px;
            height: 8px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    &:hover &-label {
        color: gray;
    }
}


</style>
