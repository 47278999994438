var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "app",
        {
          "app--top-paddings":
            _vm.canAddTopPaddings && _vm.$route.name !== "GameBody",
        },
      ],
      attrs: { id: "app" },
    },
    [
      _vm.$route.name !== "GameBody"
        ? [
            _c("Header", { attrs: { scrollY: _vm.scrollY } }),
            _c("fonts-loader", { on: { onload: _vm.onFontsLoad } }),
            _vm.loading
              ? _c("Preloader", { attrs: { black: _vm.isBlack } })
              : [
                  _c("Popovers"),
                  _c("cookie-alert"),
                  _c("tooltip"),
                  _c("router-view", { staticClass: "app__content" }),
                  _c("Footer"),
                  _c("all-popovers"),
                  _c("portal-target", {
                    attrs: { name: "main-popup", multiple: "" },
                  }),
                  _vm.isErrorShown
                    ? _c("ErrorPopup", {
                        attrs: {
                          title: _vm.errorTitle,
                          errorText: _vm.errorText,
                        },
                        on: { close: _vm.errorPopupClose },
                      })
                    : _vm._e(),
                ],
          ]
        : _c("router-view"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }