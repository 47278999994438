<template>
  <button type="button" class="hint-btn">
    <i class="hint-btn__arrows" />
    <span class="hint-btn__content">?</span>
  </button>
</template>

<script>
export default {
  name: "HintBtn",
}
</script>

<style lang="scss">

.hint-btn {
  position: relative;
  z-index: 1;

  margin: 10px;
  min-width: 36px;
  min-height: 31px;
  
  border: 2px solid #000;
  color: #000;

  &.active,
  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.hint-btn__content {
  display: block;
  
  text-transform: uppercase;
  font: 800 25px / 1 "Tektur";
}


.hint-btn__arrows {
  position: absolute;
  z-index: -1;
  top: -7px;
  left: -7px;
  right: -7px;
  bottom: -7px;

  border: 1px solid rgb(255, 255, 255);
  color: #000;
  border: 1px solid;
  pointer-events: none;

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: -1px;
    margin-top: -5px;

    border: 4px solid;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }

  &:after {
    left: unset;
    right: -1px;
    transform: scaleX(-1);
  }
}

</style>
