<template>
    <div v-popover.bottom="{ name: 'balance' }" class="balance">
        <div class="balance--selected">
            <span class="balance-label balance-label--arrow">Balance</span>

            <Loader v-if="isBalanceLoading" xs class="balance-loader"/>

            <template v-else>
                <span v-if="isCorrectNetwork" class="balance-span">{{ formattedBalance }} mf</span>
                <span v-else @click="switchNetwork" class="balance-btn">Incorrect network</span>
            </template>
        </div>

        <portal v-if="!isBalanceLoading" to="main-popup">
            <popover v-if="isCorrectNetwork" name="balance" event="hover" class="balance-popover">
                <div class="">
                    <span class="balance-label">Staked: </span>
                    <span>{{ getHoldFt }} mf</span>
                </div>

                <div class="">
                    <span class="balance-label">Credits: </span>
                    <span>{{ formattedGameBalance }} mf</span>
                </div>
            </popover>
        </portal>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Loader from '@/components/common/loader.vue'

export default {
    name: "Balance",
    components: {
        Loader,
    },
    computed: {
        ...mapGetters(['account']),
        ...mapGetters('contract', ['balance', 'isBalanceLoading', 'totalStaked', 'isCorrectNetwork']),
        ...mapGetters('wallet', ['gameBalance']),

        formattedBalance() {
            return this.toLocaleFormat(this.fromWei(this.balance).toFixed(2));
        },
        formattedGameBalance() {
            return this.toLocaleFormat(Number(this.gameBalance).toFixed(2));
        },
        getHoldFt() {
            return this.toLocaleFormat(Number(this.totalStaked).toFixed(2));
        },
    },
    methods: {
        ...mapActions(['switchNetwork']),

        click() {
            this.$emit('click')
        },
    }
}
</script>

<style lang="scss">

.balance {
    position: relative;
    z-index: 0;

    padding-right: 16px;

    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    display: flex;
    gap: 1.5em;

    text-shadow: 0 0 10px #000;

    &-loader {
        margin-top: 5px!important;
        display: flex;
        justify-content: center;
    }
    &-popover.vue-popover {
        min-width: 210px;
        background: #1F213A;
        border: 2px solid #BFBFBF;
        border-radius: 0;
        padding: 21px 15px;
        display: flex;
        flex-direction: column;
        gap: 11px;
        color: white;
        font-size: 14px;
    }
    &-label {
        text-transform: initial;
        color: #BFBFBF;
        font: 400 12px "Tektur";
        margin-bottom: 2px;

        &--arrow {
            display: flex;
            gap: 0.5em;
            align-items: center;

            &:after {
                content: '\25BC';
                font-size: 10px;
                margin-top: 2px;
            }
        }
    }

    &--selected {
        display: flex;
        flex-direction: column;
    }

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        width: 2px;
        background: linear-gradient(to top, #FF9509 45%, #FFCC13 55%);
    }
}

.balance__link {
    margin-top: 5px;
    text-shadow: 0 0 10px #000;
}

.balance-btn {
    cursor: pointer;
}

@media screen and (max-width: $bp_mb) {
    .balance {
        font-size: 20px;
        text-align: center;
        border: none;
        padding: 0;
    }
}


</style>
