import CONTRACTS_LIST from '@/service/smart-contract/contracts/';
import {SmartContractService} from '@/service/smart-contract/';
import {eventBus} from "@/plugins/eventBus";
import ApiStaking from "@/service/ApiStaking";
import {initWalletEvents} from "@/events/walletEvents";
import {EthereumProvider as WalletConnectProvider} from "@walletconnect/ethereum-provider";

const tokenManagmentAddress = process.env.VUE_APP_TOKEN_MANAGAMENT_CONTRACT_ADDRESS;
const spotTokenManagmentAddress = process.env.VUE_APP_SPOT_TOKEN_MANAGEMENT_ADDRESS;

export default {
    namespaced: true,
    state() {
        return {
            contractService: null,
            isBalanceLoading: false,
            chainId: '',
            balance: 0,
            fee: 0,
            totalStaked: 0,
            readyForUnstake: 0,
            gasValue: 0
        }
    },

    getters: {
        balance: (state) => state.balance,
        isBalanceLoading: (state) => state.isBalanceLoading,
        fee: (state) => state.fee,
        totalStaked: (state) => state.totalStaked,
        readyForUnstake: (state) => state.readyForUnstake,
        contractService: (state) => state.contractService,
        isCorrectNetwork: (state, getters, rootState) => {
            let result = false;

            if (rootState.smartContract.metamaskconnect) {
                result = state.chainId === process.env.VUE_APP_NETWORK_ID
            } else if (rootState.smartContract.walletconnect) {
                result = `0x${state.chainId.toString(16)}` === process.env.VUE_APP_NETWORK_ID
            }

            return result;
        },
        contract(state) {
            return (name) => state.contractService.contract(name, state.gasValue)
        },
        chainId: (state) =>  state.chainId
    },

    mutations: {
        setBalance(state, balance) {
            state.balance = balance;
        },
        setIsBalanceLoading(state, payload) {
            state.isBalanceLoading = payload;
        },
        setFee(state, payload) {
            state.fee = payload;
        },
        setTotalStaked(state, totalStaked) {
            state.totalStaked = totalStaked;
        },
        setReadyForUnstake(state, readyForUnstake) {
            state.readyForUnstake = readyForUnstake;
        },
        setContractService(state, payload) {
            state.contractService = payload;
        },
        setChainId(state, payload) {
            state.chainId = payload;
        },
        setGasValue(state, gasValue) {
            state.gasValue = gasValue
        }
    },

    actions: {
        async checkAccountsAccess({getters}) {
            if (!getters.contractService) {
                return [];
            }

            return await getters.contractService.web3.eth.getAccounts();
        },
        checkNetwork({getters, dispatch}) {
            if (!getters.isCorrectNetwork) {
                eventBus.$emit(
                    'errorPopup',
                    'WRONG NETWORK DETECTED',
                    'To continue, please switch network in your wallet to <span style="font-weight: 600;">Binance Smart Chain.</span>',
                );

                dispatch('switchNetwork', null, {root: true});
            }

            return getters.isCorrectNetwork;
        },
        async getBalance({commit, getters, dispatch, rootGetters}) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                commit('setBalance', 0);

                return 'error';
            }

            const token = getters.contract('MFToken')

            let balance = 0;

            commit('setIsBalanceLoading', true);

            // balance from contract
            try {
                balance = await token.methods.balanceOf(rootGetters.account).call()
            } catch (error) {
                console.error('error during balance update: ', error)
            } finally {
                commit('setIsBalanceLoading', false);
            }

            commit('setBalance', balance);

            let stakingBalance = 0;
            let readyForUnstake = 0;

            // balance from server
            try {
                const response = await ApiStaking.getBalance(rootGetters.account)

                stakingBalance = response.data.total;
                readyForUnstake = response.data.ready_for_unstake;
            } catch (error) {
                console.error('error during balance update: ', error)
            }

            // gameBalance from server
            dispatch('wallet/fetchGameBalance', null, {root: true})

            commit('setTotalStaked', stakingBalance);
            commit('setReadyForUnstake', readyForUnstake);
        },

        async getFee({commit, getters, dispatch}) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return;
            }

            const contract = getters.contract('Marketplace');
            const result = await contract.methods.tradingFee().call();

            commit('setFee', result ? result / 100 : 0);
        },

        async approve({getters, rootGetters}, {amount, isSpotWalletTransfer = false}) {
            const token = getters.contract('MFToken')

            const approve = await token.methods
                .approve(
                    isSpotWalletTransfer ? spotTokenManagmentAddress : tokenManagmentAddress,
                    amount
                )
                .send({from: rootGetters.account})
            return approve
        },

        async allowance({getters, dispatch, rootGetters}, isSpotWalletTransfer = false) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const token = getters.contract('MFToken')
            const allowance = await token.methods
                .allowance(
                    rootGetters.account,
                    isSpotWalletTransfer ? spotTokenManagmentAddress : tokenManagmentAddress
                )
                .call()

            // eslint-disable-next-line no-undef
            return BigInt(allowance).toString()
        },

        async safeMint({getters, dispatch, rootGetters}, {character_id, salt, price, vrs}) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const nft = getters.contract('MFNFT')

            const result = await nft.methods
                .safeMint(character_id, price, salt, vrs)
                .send({from: rootGetters.account})

            return result
        },

        async stakeWithoutNFTOwnerChecking({getters, dispatch, rootGetters}, props) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const nft = getters.contract('Staking');

            console.log(props)
            const result = await nft.methods
                .stakeWithoutNFTOwnerChecking(
                    props.stake_type,
                    props.id,
                    props.timelock,
                    props.price,
                    props.salt,
                    props.vrs,
                )
                .send({from: rootGetters.account})

            return result
        },

        async stakeWithNftOwnerChecking({getters, dispatch, rootGetters}, props) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const nft = getters.contract('Staking');

            console.log(props)
            const result = await nft.methods
                .stakeWithNFTOwnerChecking(
                    props.stake_type,
                    props.id,
                    props.idNft,
                    props.timelock,
                    props.price,
                    props.salt,
                    props.vrs,
                )
                .send({from: rootGetters.account})

            return result
        },

        async unstakeNft({getters, dispatch, rootGetters}, id) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const nft = getters.contract('Staking');

            const result = await nft.methods
                .unstakeNFT(id)
                .send({from: rootGetters.account})

            return result
        },
        
        async unstakeArray({getters, dispatch, rootGetters}, ids) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const contract = getters.contract('Staking');

            const result = await contract.methods
                .unstakeByIDsArray(ids)
                .send({from: rootGetters.account})

            return result
        },

        async unstakeAll({getters, dispatch, rootGetters}) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const nft = getters.contract('Staking');

            const result = await nft.methods
                .unstakeAllWithNFT()
                .send({from: rootGetters.account})

            return result
        },

        async tierUpNft({getters, dispatch, rootGetters}, props) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const nft = getters.contract('Staking');

            console.log(props)
            const result = await nft.methods
                .tierUp(
                    props.token_id,
                    props.tier,
                    props.price,
                    props.salt,
                    props.vrs,
                )
                .send({from: rootGetters.account})

            return result;
        },

        async spotTransferFromUser({getters, dispatch, rootGetters}, props) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const contract = getters.contract('SpotTokenManagement');

            console.log(props)
            const result = await contract.methods
                .spotTransfer(
                    props.amount,
                    props.salt,
                    props.vrs,
                )
                .send({from: rootGetters.account})

            return result;
        },

        async sendTokensToUser({getters, dispatch, rootGetters}, props) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const contract = getters.contract('SpotTokenManagement');

            console.log(props)
            const result = await contract.methods
                .sendTokens(
                    props.user,
                    props.amount,
                    props.expire_at,
                    props.salt,
                    props.vrs,
                )
                .send({from: rootGetters.account})

            return result;
        },

        async sell({getters, dispatch, rootGetters}, props) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const contract = getters.contract('Marketplace');
            const nftContract = getters.contract('MFNFT');

            const marketplaceAddress = process.env.VUE_APP_MARKETPLACE_ADDRESS;

            const approvedAddress = await nftContract.methods.getApproved(props.token_id).call();

            if (approvedAddress != marketplaceAddress) {
                const approveNft = await nftContract.methods
                    .approve(marketplaceAddress, props.token_id)
                    .send({from: rootGetters.account})

                if (!approveNft.status) {
                    return 'error';
                }
            }

            const result = await contract.methods
                .createListing(
                    props.token_id,
                    props.price,
                )
                .send({from: rootGetters.account})

            return result;
        },

        async buy({getters, dispatch, rootGetters}, tokenId) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const contract = getters.contract('Marketplace');

            const result = await contract.methods
                .buy(tokenId)
                .send({from: rootGetters.account})

            return result;
        },

        async buyBooster({getters, dispatch, rootGetters}, props) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const contract = getters.contract('TokenManagement');

            const result = await contract.methods
                .buyItem(
                    props.type,
                    props.price,
                    props.salt,
                    props.vrs,
                )
                .send({from: rootGetters.account})

            return result;
        },

        async cancelListing({getters, dispatch, rootGetters}, tokenId) {
            const isCorrectNetwork = await dispatch('checkNetwork');

            if (!isCorrectNetwork) {
                return 'error';
            }

            const contract = getters.contract('Marketplace');
            const result = await contract.methods
                .cancelListing(tokenId)
                .send({from: rootGetters.account})

            return result;
        },

        async contractServiceHandler({dispatch, rootGetters}) {
            if (rootGetters.metamaskconnect) {
                const {ethereum} = window;

                if (!ethereum) {
                    // в локалсторедже есть данные об акаунте но метамаск отключен, делаем дисконнект
                    dispatch('disconnectWallet', null, {root: true});

                    return;
                }

                dispatch('contractServiceProcess', ethereum);

                initWalletEvents(ethereum);
            }

        if (rootGetters.walletconnect) {
          const networkInt = parseInt(process.env.VUE_APP_NETWORK_ID_INT)
          const options = {
            projectId: process.env.VUE_APP_PROJECT_ID,
            chains: [networkInt],
            showQrModal: false
          }

          const provider = await WalletConnectProvider.init(options);
          await provider.enable()

                dispatch('contractServiceProcess', provider);

                initWalletEvents(provider);
            }
        },

        contractServiceProcess({commit, dispatch}, provider) {
            const contractService = new SmartContractService(CONTRACTS_LIST, provider);

            commit('setChainId', provider?.chainId || '');
            commit('setContractService', contractService);

            if (!provider) {
                dispatch('auth/clearData', null, {root: true});
            }


            if (provider?.chainId) {
                dispatch('getFee');

            }

            dispatch('getGasValue')
        },
        async getGasValue({commit, state}) {
            let gas
            try {
                gas = await state.contractService.web3.eth.getGasPrice()
            } catch (e) {
                const isDev = process.env.NODE_ENV === 'development';
                gas = isDev ? 10000000000 : 3000000000;
            }

            commit('setGasValue', gas)
        }
    },
}
