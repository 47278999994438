<template>
  <div class="close-btn">
    <div class="close-btn__inner" @click="$emit('click')">
      <div class="close-btn__element"></div>
      <div class="close-btn__element"></div>
      <div class="close-btn__element"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CloseBtn",
}
</script>

<style lang="scss">

.close-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;

  background-color: #000;
  transition: .3s;
  
  &__inner {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30px;
    transition: .3s;

    justify-content: center;
    transform: rotate(45deg);
  }
	
	&__element {
		width: 30px;
		height: 4px;

    background-color: #fff;
    
    &:nth-of-type(1) {
      transform: translateY(100%);
    }
    
    &:nth-of-type(3) {
      transform-origin: center center;
      transform: translateY(-100%) rotate(90deg);
    }
    
    &:nth-of-type(2) {
      opacity: 0;
    }
	}
}

</style>
