<template>
    <portal to="main-popup">
      <popup v-model="popup" @input="close" content-class="approve-popup">
        <div>
          <preloader dark text="Wait for the network to confirm the transaction" v-if="loading" />

          <div class="approve-popup__content">
            <div class="approve-popup__desc">
              <span v-html="text"></span>
            </div>
          </div>
          <div class="approve-popup__buttons">
            <btn
              :type="currentAllowance >= amount ? 'mint-dark': 'mint'"
              class="approve-popup__buttons-item"
              text="APPROVE"
              :disabled="currentAllowance >= amount"
              @click="onClickApprove"
            />
            <btn
              type="mint-dark"
              class="approve-popup__buttons-item"
              :text="actionBtnText"
              :disabled="currentAllowance < amount"
              @click="onActionBtnClick"
            />
          </div>
        </div>
      </popup>
    </portal>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "ApprovePopup",
  props: {
    actionBtnText: { type: String, default: 'MINT' },
    successMsg: {
        type: String,
        default: 'You have approved a sufficient number of MF tokens. Now you can mint the character.'
    },
  },
  data() {
    return {
      popup: false,
      data: null,
      amount: -1,
      currentAllowance: 0,
      approved: false,
      loading: false,
    }
  },
  computed: {
      text() {
        let textMsg = '';

        switch (true) {
            case this.approved && this.currentAllowance < this.amount:
                textMsg = `You have approved an insufficient number of tokens for this transaction.
                           Click on approve button again and allow the use of at least <b>${ this.amount }</b> MF tokens`;
                break;
            case this.currentAllowance >= this.amount:
                textMsg = this.successMsg;
                break;
            case !this.approved:
                textMsg = `To make any transactions on the metafighter platform, it is necessary to allow the use of smart contracts tokens from this platform.
                Make approve for further operations with tokens on the metafighter platform.`;
                break;
        }

        return textMsg;
      },
  },
  methods: {
    ...mapActions('contract', ['allowance', 'approve']),

    async open(amount, data){
      this.data = data;

      await this.setCurrentAllowance();

      this.popup = true;
      this.amount = amount;
    },
    close() {
      this.approved = false;
      this.popup = false;
      this.amount = -1;
      this.$emit('close');
    },

    async onClickApprove() {
      this.loading = true

      await this.setCurrentAllowance();

      try {
        const result = await this.approve({
            amount: this.toWei(this.amount * 10 ** 18),
            isSpotWalletTransfer: this.data.isSpotWalletTransfer,
        })

        await this.setCurrentAllowance(result?.events?.Approval?.returnValues?.value ?? this.currentAllowance);

        if (this.currentAllowance < this.amount) {
          this.approved = true
        }

      } catch (error) {
        this.approved = true
        // вывести алерт (или закрыть попап) ?
        console.log('Вы запретили...');
      }

      this.loading = false
    },

    onActionBtnClick() {
      if (this.currentAllowance >= this.amount) {
        this.$emit('action-btn-click', this.data)
        this.close()
      }
    },

    // TODO: переписать все эти костыли, пока пусть так
    async setCurrentAllowance(allowance = null) {
        if (!allowance) {
            allowance = await this.allowance(this.data.isSpotWalletTransfer);
        }

        this.currentAllowance = this.fromWei(allowance);
    },
  }
}
</script>

<style lang="scss">

.approve-popup {
  max-width: 570px!important;
  padding-bottom: 15px!important;;
}
.approve-popup__content {
  text-transform: uppercase;
  text-align: center;
}
.approve-popup__title {
  font: 800 21px Raleway;
  margin-bottom: 10px;
}
.approve-popup__desc {
  font-size: 14px;
}
.approve-popup__buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  &-item {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

</style>
