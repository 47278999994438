import endpoints from '@/constants/Endpoints';
import apiCall from '@/utils/apiCall';

export default class ApiEvents {
    static async getInfoBanner() {       
        return await apiCall(endpoints.events.getInfoBanner);
    }

    static async getEventsBanners() {
        return await apiCall(endpoints.events.getEventsBanners);
    }
}