var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "logo",
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("picture", [
      _c("source", {
        attrs: {
          srcset: require("@/assets/img/logo.webp"),
          type: "image/webp",
        },
      }),
      _c("source", {
        attrs: { srcset: require("@/assets/img/logo.png"), type: "image/png" },
      }),
      _c("img", {
        attrs: {
          src: require("@/assets/img/logo.png"),
          alt: "metafighter logo",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }