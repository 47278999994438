import api from '@/utils/BaseApi';

export default class ApiTournament {
  static checkValidSession(token) {
    return api.get('tournament2022/check_valid_session/', {
      headers: {
        Authorization: token
      },
    });
  }

  static signUp(address, sign) {
    const formData = new FormData();

    formData.append("address", address);
    formData.append("sign", sign);

    return api.post('tournament2022/signup/', formData);
  }

  static updateProfile(username, token) {
    const formData = new FormData();

    formData.append("username", username);

    return api.post('tournament2022/update_profile/', formData,
        {
          headers: {
            Authorization: token,
          }
        },
    );
  }
}
