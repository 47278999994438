import ApiTournament from "@/service/ApiTournament";

export default {
  namespaced: true,
  state() {
    return {
      token: null,
      username: null,
      address: null,
    }
  },

  getters: {
    token: (state) => state.token,
    username: (state) => state.username,
    address: (state) => state.address,
  },

  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setUsername(state, payload) {
      state.username = payload;
    },
    setAddress(state, payload) {
      state.address = payload;
    },
  },

  actions: {
    clearData({commit}) {
      commit('setToken', null);
      commit('setUsername', null);
      commit('setAddress', null);
    },
    async checkValidSession({commit, state, dispatch}) {
      if (!state.token) {
        dispatch('clearData');

        return;
      }

      try {
        const response = await ApiTournament.checkValidSession(state.token);

        commit('setUsername', response.data.username);
        commit('setAddress', response.data.address);
      } catch (e) {
        dispatch('clearData');
      }
    },
    async signUpTournament({commit}, {address, sign}) {
      const response = await ApiTournament.signUp(address, sign);

      commit('setToken', response.data.token);
      commit('setUsername', response.data.username);
      commit('setAddress', response.data.address);
    },
    async updateProfile({commit, state}, username) {
      await ApiTournament.updateProfile(username, state.token);

      commit('setUsername', username);
    },
  }
}
