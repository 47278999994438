var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    require(`../../assets/svg/${_vm.name}.svg`),
    _vm._b(
      { tag: "Component", on: { "v-on": _vm.$listeners } },
      "Component",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }