var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "popup",
        {
          on: { input: _vm.leave },
          model: {
            value: _vm.popup,
            callback: function ($$v) {
              _vm.popup = $$v
            },
            expression: "popup",
          },
        },
        [
          _c("div", { staticClass: "transaction-confirm-popup metamask" }, [
            _c(
              "button",
              {
                staticClass: "metamask__meta-btn",
                on: { click: _vm.metamaskConnect },
              },
              [
                _c("BaseIcon", { attrs: { name: "metamask", width: "40" } }),
                _vm.isMetamaskLoading
                  ? _c("Loader", {
                      staticClass: "metamask-loader",
                      attrs: { xs: "" },
                    })
                  : _c("span", { staticClass: "metamask-text" }, [
                      _vm._v("Connect via Metamask"),
                    ]),
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "metamask__wallet-btn",
                on: { click: _vm.walletConnect },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      viewBox: "0 0 300 185",
                      version: "1.1",
                      xmlns: "http://www.w3.org/2000/svg",
                      xmlnsXlink: "http://www.w3.org/1999/xlink",
                    },
                  },
                  [
                    _c("defs"),
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Page-1",
                          stroke: "none",
                          "stroke-width": "1",
                          fill: "none",
                          "fill-rule": "evenodd",
                        },
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "walletconnect-logo-alt",
                              fill: "#3B99FC",
                              "fill-rule": "nonzero",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M61.4385429,36.2562612 C110.349767,-11.6319051 189.65053,-11.6319051 238.561752,36.2562612 L244.448297,42.0196786 C246.893858,44.4140867 246.893858,48.2961898 244.448297,50.690599 L224.311602,70.406102 C223.088821,71.6033071 221.106302,71.6033071 219.883521,70.406102 L211.782937,62.4749541 C177.661245,29.0669724 122.339051,29.0669724 88.2173582,62.4749541 L79.542302,70.9685592 C78.3195204,72.1657633 76.337001,72.1657633 75.1142214,70.9685592 L54.9775265,51.2530561 C52.5319653,48.8586469 52.5319653,44.9765439 54.9775265,42.5821357 L61.4385429,36.2562612 Z M280.206339,77.0300061 L298.128036,94.5769031 C300.573585,96.9713 300.573599,100.85338 298.128067,103.247793 L217.317896,182.368927 C214.872352,184.763353 210.907314,184.76338 208.461736,182.368989 C208.461726,182.368979 208.461714,182.368967 208.461704,182.368957 L151.107561,126.214385 C150.496171,125.615783 149.504911,125.615783 148.893521,126.214385 C148.893517,126.214389 148.893514,126.214393 148.89351,126.214396 L91.5405888,182.368927 C89.095052,184.763359 85.1300133,184.763399 82.6844276,182.369014 C82.6844133,182.369 82.684398,182.368986 82.6843827,182.36897 L1.87196327,103.246785 C-0.573596939,100.852377 -0.573596939,96.9702735 1.87196327,94.5758653 L19.7936929,77.028998 C22.2392531,74.6345898 26.2042918,74.6345898 28.6498531,77.028998 L86.0048306,133.184355 C86.6162214,133.782957 87.6074796,133.782957 88.2188704,133.184355 C88.2188796,133.184346 88.2188878,133.184338 88.2188969,133.184331 L145.571,77.028998 C148.016505,74.6345347 151.981544,74.6344449 154.427161,77.028798 C154.427195,77.0288316 154.427229,77.0288653 154.427262,77.028899 L211.782164,133.184331 C212.393554,133.782932 213.384814,133.782932 213.996204,133.184331 L271.350179,77.0300061 C273.79574,74.6355969 277.760778,74.6355969 280.206339,77.0300061 Z",
                                id: "WalletConnect",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" Connect via walletConnect "),
              ]
            ),
          ]),
        ]
      ),
      _vm.isErrorShown
        ? _c(
            "ErrorPopup",
            {
              attrs: {
                title: _vm.isMetamaskExist
                  ? "There are some problems with Metamask extension"
                  : "Metamask was not found in your browser.",
              },
              on: { close: _vm.closeError },
            },
            [
              _vm.isMetamaskExist
                ? _c("div", [
                    _vm._v(
                      " Try to reload the page. If the error still occurs try to reinstall the "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "error-content-link",
                        attrs: {
                          href: "https://metamask.io/download/",
                          target: "_blank",
                        },
                        on: { click: _vm.closeError },
                      },
                      [_vm._v(" extension. ")]
                    ),
                    _c("p", [_vm._v("Or switch to a different browser")]),
                  ])
                : _c("div", { staticClass: "error-content" }, [
                    _vm._v(" To install the extension follow the "),
                    _c(
                      "a",
                      {
                        staticClass: "error-content-link",
                        attrs: {
                          href: "https://metamask.io/download/",
                          target: "_blank",
                        },
                        on: { click: _vm.closeError },
                      },
                      [_vm._v(" link ")]
                    ),
                  ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }