import ApiCharacters from '@/service/ApiCharacters';
import ApiStaking from '@/service/ApiStaking';
import ApiShop from '@/service/ApiShop';
import { nftsAttributesNormalizer } from "@/utils/helpers"
import Characters from '@/models/characters';
import Character from '@/models/character';

export default {
  state() {
    return {
      characters: [],
      myCharacters: new Characters(),
      character: null,
      myCharacter: null,
      stakingCharacters: [],
      shopCharacters: [],
      shopCharacter: null,
    }
  },

  getters: {
    GET_CHARACTERS: (state) => state.characters,
    GET_MY_CHARACTERS: (state) => state.myCharacters.results,
    MY_CHARACTERS_COUNT: (state) => state.myCharacters.count,
    GET_CHARACTER: (state) => state.character,
    GET_MY_CHARACTER: (state) => state.myCharacter,
    GET_STAKING_CHARACTERS: (state) => state.stakingCharacters,
    GET_SHOP_CHARACTERS: (state) => state.shopCharacters.results,
    SHOP_CHARACTERS_COUNT: (state) => state.shopCharacters.count,
    GET_SHOP_CHARACTER: (state) => state.shopCharacter,
  },

  mutations: {
    SET_CHARACTERS(state, payload) {
      state.characters = payload
    },

    SET_MY_CHARACTERS(state, payload) {
      state.myCharacters = Characters.produceCharacters(payload);
    },

    SET_CHARACTER(state, payload) {
      state.character = payload
    },

    SET_MY_CHARACTER(state, payload) {
      state.myCharacter = Character.produceCharacter(payload);
    },

    SET_STAKING_CHARACTERS(state, payload) {
      state.stakingCharacters = payload;
    },

    SET_SHOP_CHARACTERS(state, payload) {
      state.shopCharacters = Characters.produceCharacters(payload);
    },

    SET_SHOP_CHARACTER(state, payload) {
      state.shopCharacter = {
        ...payload,
        nft: Character.produceCharacter(payload.nft),
      }
    }
  },

  actions: {
    async REQUEST_CHARACTERS({ commit }) {
      try {
        const { data } = await ApiCharacters.characters()
        nftsAttributesNormalizer(data.nfts)
        commit('SET_CHARACTERS', data.nfts)
      } catch (e) {
        return e
      }
    },

    async REQUEST_CHARACTER({ commit }, type) {
      try {
        const { data } = await ApiCharacters.character(type)
        nftsAttributesNormalizer([data.nfts])
        commit('SET_CHARACTER', data.nfts)
      } catch (e) {
        return e
      }
    },

    async REQUEST_MY_CHARACTERS({ commit, rootGetters }, {page, pageSize}) {
      if (!rootGetters.account) {
        throw new Error('You have to be logged in');
      }

      try {
        const { data } = await ApiCharacters.myCharacters(
            rootGetters.account,
            page,
            pageSize,
        )

        commit('SET_MY_CHARACTERS', data)
      } catch (e) {
        console.log(e);

        throw e;
      }
    },

    async REQUEST_MY_CHARACTER({ commit }, id) {
      try {
        const { data } = await ApiCharacters.myCharacter(id)

        commit('SET_MY_CHARACTER', data)
      } catch (e) {
        console.log(e)
      }
    },

    async REQUEST_STAKING_CHARACTERS({ commit, rootGetters }) {
      try {
        const { data } = await ApiStaking.stakingCharacters(rootGetters.account)

        commit('SET_STAKING_CHARACTERS', data)
      } catch (e) {
        return e
      }
    },

    async REQUEST_SHOP_CHARACTERS({ commit }, props) {
        const { data } = await ApiShop.shopCharacters(props);

        commit('SET_SHOP_CHARACTERS', data)
    },

    async REQUEST_SHOP_CHARACTER({ commit }, id) {
      try {
        const { data } = await ApiShop.shopCharacter(id);

        commit('SET_SHOP_CHARACTER', data)
      } catch (e) {
        return e
      }
    },
  },
}
