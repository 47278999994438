import endpoints from '@/constants/Endpoints';
import apiCall from '@/utils/apiCall';

export default class ApiLeaderboards {
    static getLeaderboardNFT(tokenId) {
      const formData = new FormData();

      formData.append("token_id", tokenId);

      return apiCall(endpoints.leaderboards.getLeaderboardNFT, formData);
    }

    static getLeaderboardWallet(tokenId) {
      const formData = new FormData();

      formData.append("token_id", tokenId);

      return apiCall(endpoints.leaderboards.getLeaderboardWallet, formData);
    }

    static getLeaderboardEvents() {
      return apiCall(endpoints.leaderboards.getLeaderboardEvents);
    }

    static getLeaderboardEventTable(id, tokenId) {
      const formData = new FormData();

      formData.append("token_id", tokenId);
    
      return apiCall(endpoints.leaderboards.getLeaderboardEventTable, {}, `${id}`, formData);
    }
  }