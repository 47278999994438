import endpoints from '@/constants/Endpoints';
import apiCall from '@/utils/apiCall';

export default class ApiBoosters {
  static getBoosters() {
    return apiCall(endpoints.boosters.get);
  }
  static getBoostersRules() {
    return apiCall(endpoints.boosters.rules);
  }
  static getMyBoosters(address) {
    return apiCall(endpoints.boosters.myBoosters, { params: { owner: address } });
  }
  static activateBooster(boosterId, tokenId) {
    const formData = new FormData();

    formData.append("user_booster_id", boosterId);
    formData.append("token_id", tokenId);

    return apiCall(endpoints.boosters.activateBooster, formData);
  }
}
