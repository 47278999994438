var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "portal",
    { attrs: { to: "main-popup" } },
    [
      _c(
        "popup",
        {
          attrs: { "content-class": "error-popup" },
          on: {
            input: function ($event) {
              return _vm.$emit("close")
            },
          },
          model: {
            value: _vm.isShown,
            callback: function ($$v) {
              _vm.isShown = $$v
            },
            expression: "isShown",
          },
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "error-popup__title" }, [
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ]),
              _c("div", { staticClass: "error-popup__content" }, [
                _c("div", {
                  staticClass: "error-popup__desc",
                  domProps: { innerHTML: _vm._s(_vm.errorText) },
                }),
              ]),
              _vm._t("default"),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }