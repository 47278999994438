import axios from "axios"
import {eventBus} from "@/plugins/eventBus";
import store from "@/store";

const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}api/v1/`,
  headers: {
    Accept: "application/json",
    'Content-Type': 'application/json',
  }
})

api.interceptors.request.use(
    (config) => {
        if (config.method === 'post') {
            config.headers['Content-Type'] = 'multipart/form-data';
        }

        // if (!store.state.auth?.token) {
        //     return config;
        // }
        //
        // if (!config.headers['Authorization']) {
        //     config.headers['Authorization'] = `Token ${store.state.auth.token}`
        // }

        return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

api.interceptors.response.use((response) => response, (error) => {
  if (error.response?.status === 404) {
      return;
  }
  if (error.response?.status === 401) {
      store.dispatch('auth/clearData');

      return;
  }

  eventBus.$emit('errorPopup', '',
      error.response?.data?.error
      || error.response?.statusText
      || 'unexpected error'
  );

  return Promise.reject(error);
});

/*
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && localStorage.getItem('token')) {
      localStorage.removeItem('token')
      location.reload()
    }
    return Promise.reject(error)
  }
)
*/

export default api
