<template>
  <div v-if="GET_IS_COOKIE_ALERT_SHOW" data-name="cookie-alert" class="cookie-alert">
    <div class="cookie-alert__text">
      By clicking "Accept", you agree to the storing of
      cookies on your device to enhance site navigation,
      analyze site usage, and assist in our marketing efforts.
    </div>
    <div class="cookie-alert__buttons">
      <btn class="cookie-alert__buttons-item" type="icon" text="Hide" @click="onHide" />
      <btn class="cookie-alert__buttons-item" type="icon-gradient" text="Accept" @click="onAccept" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: "CookieAlert",

  computed: {
    ...mapGetters([
      'GET_IS_COOKIE_ALERT_SHOW',
    ]),
  },

  methods: {
    ...mapMutations([
      'SET_IS_COOKIE_ALERT_SHOW',
    ]),

    onHide() {
      this.SET_IS_COOKIE_ALERT_SHOW('hide')
    },

    onAccept() {
      this.SET_IS_COOKIE_ALERT_SHOW('accept')
    },
  },
}
</script>

<style lang="scss">

.cookie-alert {
  position: fixed;
  z-index: 999999999;

  left: 15px;
  right: 15px;
  bottom: 30px;

  margin: 0 auto;
  max-width: 860px;

  padding: 15px 30px;
  border: 2px solid #000;

  background-color: #fff;
  color: #000;

  display: flex;
  align-items: center;
}

.cookie-alert__text {
  flex-grow: 1;
  margin-right: 15px;
  font-size: 14px;
}

.cookie-alert__buttons {
  display: flex;
  align-items: center;
}

.cookie-alert__buttons-item {
  margin-right: 15px;
  min-width: 100px;

  &:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 777px) {
  .cookie-alert {
    left: 5px;
    right: 5px;
    bottom: 5px;

    padding: 10px;
    flex-direction: column;
    align-items: stretch;
  }

  .cookie-alert__text {
    margin: 0;
  }

  .cookie-alert__buttons {
    margin-top: 10px;
  }

  .cookie-alert__buttons-item {
    flex-grow: 1;
    max-width: 100%;

    &:last-child {
      margin-right: 0;
    }
  }
}

</style>
