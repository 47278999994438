// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".menu__list {\n  display: flex;\n  gap: 30px;\n}\n.menu__submenu {\n  position: absolute;\n  flex-direction: column;\n  gap: 0.75em;\n  min-width: 175px;\n  background: #1F213A;\n  padding: 0.75em;\n  margin-top: 10px;\n  overflow: hidden;\n  display: none;\n  z-index: 1;\n  box-shadow: 0 0 2px 0px #ffc812;\n}\n.menu__submenu .menu__link {\n  display: block;\n  margin: 0;\n}\n.menu__submenu .menu__link:after {\n  display: none;\n}\n.menu__link-inner {\n  display: flex;\n  gap: 6px;\n}\n.menu__submenu-icon {\n  width: 20px;\n  height: 20px;\n  transition: transform 300ms ease-in;\n}\n.menu__link {\n  text-shadow: 0 0 10px #000;\n}\n.menu__link--active .menu__submenu {\n  display: flex;\n}\n.menu__link--active:after {\n  display: none;\n}\n.menu__link--active .menu__submenu-icon {\n  transform: rotate(180deg);\n}\n.menu__link:last-child {\n  margin-right: 0;\n}\n@media screen and (max-width: 1350px) {\n.menu__list {\n    gap: 8px;\n}\n}\n@media screen and (max-width: 1115px) {\n.menu__list {\n    gap: 5px;\n}\n.menu__link.link {\n    font-size: 14px;\n}\n}\n@media screen and (max-width: 991px) {\n.menu__list {\n    flex-direction: column;\n    align-items: center;\n    gap: 23px;\n}\n}\n@media screen and (max-width: 639px) {\n.menu__list {\n    gap: 19px;\n}\n.menu__link:last-child {\n    margin-bottom: 0;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
