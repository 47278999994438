import endpoints from '@/constants/Endpoints';
import apiCall from '@/utils/apiCall';

export default class ApiStakes {
    static getStakes(page, pageSize, type) {
        return apiCall(endpoints.stakes.getStakes, {
            params: {
                page: page,
                page_size: pageSize,
                type: type
            }
        });
    }
}