<template>
  <popup v-model="popup" @input="close" content-class="question-popup">
    <div>
      <div class="question-popup__content">
        <div class="question-popup__title">
            Are you sure?
        </div>

        <div class="question-popup__text">
            When requesting a withdrawal, your funds will be blocked for {{convertToTime}}
            or until the transaction is confirmed by the blockchain. If you reject the transaction,
            your funds will remain blocked and you will not be able to use them in the game for {{convertToTime}}.
            This retention period allows you to ensure the reliability of the platform and prevents fraudulent actions.
        </div>
      </div>
      <div class="question-popup__buttons">
        <btn
          type="icon-gradient"
          class="question-popup__buttons-item"
          text="CONFIRM"
          @click="confirm"
        />
        <btn
          type="mint-dark"
          class="question-popup__buttons-item"
          text="CANCEL"
          @click="close"
        />
      </div>
    </div>
  </popup>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "QuestionWithdrawPopup",
    computed: {
        ...mapGetters('wallet', ['sendTokensTtl']),

        convertToTime() {
            const days = Math.floor(parseInt(this.sendTokensTtl, 10) / (3600*24));

            if (days > 0) {
                return `${days} days`;
            }

            const hours = Math.floor(parseInt(this.sendTokensTtl, 10) / 3600);

            if (hours > 0) {
                return `${hours} hours`;
            }

            const minutes = Math.floor(parseInt(this.sendTokensTtl, 10) / 60);

            if (minutes > 0) {
                return `${minutes} minutes`;
            }

            return `${this.sendTokensTtl} seconds`;
        },
    },
    data() {
        return {
            popup: false,
        }
    },
    methods: {
        open() {
            this.popup = true;
        },
        close() {
            this.popup = false;
            this.$emit('close');
        },
        confirm() {
            this.popup = false;
            this.$emit('confirm');
        },
    }
}
</script>

<style lang="scss">
.question-popup {
    max-width: 570px!important;
    background-color: #C6CCD7 !important;
    padding: 2em 3em !important;
    border-radius: 15px;
}
.question-popup__content,
.question-popup__desc {
    margin: 1.5em 0;
    text-align: center;
    font: 400 16px Tektur;
}
.question-popup__title {
    text-align: center;
    text-transform: uppercase;
    font: 800 38px Raleway;
}
.question-popup__text {
    margin-top: 1em;
}

.question-popup__buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  &-item {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

</style>
