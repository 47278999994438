import { render, staticRenderFns } from "./btn.vue?vue&type=template&id=205e8a48&"
import script from "./btn.vue?vue&type=script&lang=js&"
export * from "./btn.vue?vue&type=script&lang=js&"
import style0 from "./btn.vue?vue&type=style&index=0&id=205e8a48&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/fruktorum/frontend/meta-fighter/meta-fighter/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('205e8a48')) {
      api.createRecord('205e8a48', component.options)
    } else {
      api.reload('205e8a48', component.options)
    }
    module.hot.accept("./btn.vue?vue&type=template&id=205e8a48&", function () {
      api.rerender('205e8a48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/btn.vue"
export default component.exports