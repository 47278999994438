import endpoints from '@/constants/Endpoints';
import apiCall from '@/utils/apiCall';

export default class ApiWallet {
    static async getGameBalance(address) {
        return apiCall(endpoints.balance.getGameBalance, { params: { address } });
    }

    static async getSendTokensTtl() {
        return apiCall(endpoints.balance.getSendTokensTtl);
    }

    static async sendTokensGetVrs(address, amount) {
        const formData = new FormData();

        formData.append("user", address);
        formData.append("amount", amount);

        return apiCall(endpoints.balance.getSendTokensVrs, formData);
    }

    static async spotTransferGetVrs(amount) {
        const formData = new FormData();

        formData.append("amount", amount);

        return apiCall(endpoints.balance.spotTransferGetVrs, formData);
    }
}
