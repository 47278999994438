// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".close-btn {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 60px;\n  height: 60px;\n  background-color: #000;\n  transition: 0.3s;\n}\n.close-btn__inner {\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 30px;\n  transition: 0.3s;\n  justify-content: center;\n  transform: rotate(45deg);\n}\n.close-btn__element {\n  width: 30px;\n  height: 4px;\n  background-color: #fff;\n}\n.close-btn__element:nth-of-type(1) {\n  transform: translateY(100%);\n}\n.close-btn__element:nth-of-type(3) {\n  transform-origin: center center;\n  transform: translateY(-100%) rotate(90deg);\n}\n.close-btn__element:nth-of-type(2) {\n  opacity: 0;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
