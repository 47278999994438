import { SmartContract, SmartContractABI } from '..';

const contractName = 'Staking';
const contractABI = new SmartContractABI();
const contractAddress = process.env.VUE_APP_STAKING_CONTRACT_ADDRESS;

const stakeWithNFTOwnerCheckingABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_stakeType',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_id',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_idNFT',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_timelock',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256',
    },
    {
      internalType: 'bytes32',
      name: '_salt',
      type: 'bytes32',
    },
    {
      internalType: 'bytes',
      name: 'vrs',
      type: 'bytes',
    },
  ],
  name: 'stakeWithNFTOwnerChecking',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

const stakeWithoutNFTOwnerCheckingABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_stakeType',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_id',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_timelock',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256',
    },
    {
      internalType: 'bytes32',
      name: '_salt',
      type: 'bytes32',
    },
    {
      internalType: 'bytes',
      name: 'vrs',
      type: 'bytes',
    },
  ],
  name: 'stakeWithoutNFTOwnerChecking',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

const unstakeAllABI = {
  inputs: [],
  name: 'unstakeAll',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

const unstakeAllWithNFTABI = {
  inputs: [],
  name: 'unstakeAllWithNFT',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

const unstakeNFTABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_stakeID',
      type: 'uint256',
    },
  ],
  name: 'unstakeNFT',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

const unstakeByIdABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_stakeID',
      type: 'uint256',
    },
  ],
  name: 'unstakeById',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

const unstakeByIDsArrayABI = {
  inputs: [
    {
      internalType: 'uint256[]',
      name: '_stakeIDsArray',
      type: 'uint256[]',
    },
  ],
  name: 'unstakeByIDsArray',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};


const tierUpABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_tokenId',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_tier',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_tierPrice',
      type: 'uint256',
    },
    {
      internalType: 'bytes32',
      name: '_salt',
      type: 'bytes32',
    },
    {
      internalType: 'bytes',
      name: 'vrs',
      type: 'bytes',
    },
  ],
  name: 'tierUp',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

contractABI.add(stakeWithNFTOwnerCheckingABI);
contractABI.add(stakeWithoutNFTOwnerCheckingABI);
contractABI.add(unstakeAllABI);
contractABI.add(unstakeAllWithNFTABI);
contractABI.add(unstakeNFTABI);
contractABI.add(unstakeByIdABI);
contractABI.add(unstakeByIDsArrayABI);
contractABI.add(tierUpABI);

const STAKING_CONTRACT = new SmartContract(
  contractName,
  contractABI.ABI,
  contractAddress
);

export default STAKING_CONTRACT;
