var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["preloader", { "preloader--fixed": _vm.fixed }] },
    [
      _c("i", {
        class: [
          "preloader__bg",
          { translucent: _vm.translucent, dark: _vm.dark, black: _vm.black },
        ],
      }),
      _c("i", {
        class: ["preloader__circle", { dark: _vm.dark, black: _vm.black }],
      }),
      _c("div", { staticClass: "preloader__text" }, [_vm._v(_vm._s(_vm.text))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }