var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _vm.value
      ? _c("div", { staticClass: "popup" }, [
          _c("div", { staticClass: "popup__inner" }, [
            _c("i", { staticClass: "popup__bg", on: { click: _vm.close } }),
            _c(
              "div",
              { class: `popup__view ${_vm.contentClass}` },
              [
                _c("close-btn", {
                  staticClass: "popup__close-btn",
                  on: { click: _vm.close },
                }),
                _vm._t("default"),
              ],
              2
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }