<template>
  <button
    :title="altText"
    @mouseover="hoverHandler(true)"
    @mouseleave="hoverHandler(false)"
    type="button"
    :class="[
      'button',
      {
        [`button--${type}`]: type,
        [`button--has-icon`]: icon,
        [`button--disabled`]: isDisabled,
      }
    ]"
    @click.stop="click"
  >
    <i v-if="canShowArrows" class="button__arrows" />
    <img v-if="icon" :src="require('@/assets/img/' + icon)" alt="button icon" class="button__icon">
    <span
        :class="[
            {'button__content--arrow': arrow},
            {'button__content--flex': svgIcon}
        ]"
        class="button__content"
    >
      <span
          v-if="title"
          :style="{ fontSize: `${titleSize}px` }"
          class="button__title"
      >{{ title }}</span>

      <span
          v-if="!!hoverText && !isHovered && !GET_IS_MOBILE"
          class="button__text"
          :style="{ fontSize: `${textSize}px` }"
      >
          {{hoverText}}
      </span>

      <span
        v-else-if="getText"
        class="button__text"
        :style="{ fontSize: `${textSize}px` }"
        v-html="getText"
      />

      <BaseIcon v-if="svgIcon" :name="svgIcon"/>
    </span>
      <span
          v-if="subText"
          class="button__subtext"
          :style="{ fontSize: `${12}px` }"
      >{{subText}}</span>
    <slot></slot>
  </button>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Btn",
  props: {
    type: { type: String, default: 'mint' },
    icon: { type: String, default: '' },
    svgIcon: { type: String, default: '' },
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    altText: { type: String, default: '' },
    subText: { type: String, default: '' },
    textSize: { type: Number, default: 15 },
    titleSize: { type: Number, default: 20 },
    selected: { type: [Number, String], default: '' },
    hoverText: { type: String, default: '' },
    arrow: {type: Boolean, default: false},
    isDisabled: {type: Boolean, default: false},
  },
  data() {
      return {
          isHovered: false,
      }
  },
  computed: {
    ...mapGetters(['GET_IS_MOBILE']),

    canShowArrows() {
      return this.type.startsWith('play')
    },
    getText() {
      if (!this.selected) {
        return this.text
      }

      return this.text.split('?').join(
        `<span class="button__text--selected">${this.selected}</span>`
      )
    },
  },
  methods: {
    click() {
        this.$emit('click')
    },
    hoverHandler(val) {
        this.isHovered = val;
    },
  }
}
</script>

<style lang="scss">
.button {
  position: relative;
  font: 500 15px "Tektur";
  padding: 15px 15px;
  min-width: 131px;
  min-height: 44px;
  max-height: 70px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:disabled,
  &.disabled {
    pointer-events: none;
    filter: contrast(0.25);
  }

  &--disabled {
      filter: contrast(0.25);
      background: transparent!important;
      color: white!important;
  }
}

.button__icon {
  max-width: 30px;
  max-height: 30px;

  width: auto;
  height: auto;

  margin-right: 20px;
}

.button__content {
  text-align: left;

    &--arrow {
        display: flex;
        gap: 0.5em;
        align-items: center;

        &:after {
            content: '\203A';
            line-height: 100%;
            margin-bottom: 3px;
        }
    }

    &--flex {
        display: flex;
        gap: 0.5em;
    }
}
.button__title {
  display: block;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.5;
  white-space: pre;
}
.button__text {
  display: block;
  text-align: center;
}
.button__subtext {
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    color: #FFCC13;
}

.button__text--selected {
  color: #FFC010;
}

.button--text-gray,
.button--icon,
.button--icon-gradient,
.button--mint-white,
.button--mint-gold,
.button--mint-dark,
.button--mint {
  border: 1px solid #000;
  transition: background-color 300ms, color 300ms;
  text-transform: uppercase;

  &.active,
  &:hover {
    background-color: #000;
    color: #fff
  }
}

.button--icon,
.button--icon-gradient {
  padding: 15px 20px;
  &:has(.button--has-icon) {
    justify-content: flex-start;
  }
}

.button--icon-gradient {
  position: relative;
  z-index: 0;

  text-transform: uppercase;
  border: none;

  color: #000;

  transition: 300ms;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(to right, #FF9509, #FFCC13);

    transition: opacity 300ms;
  }

  &.active,
  &:hover {
    background-color: #fff;
    color: #000;

    &:after {
      opacity: 0;
    }
  }
}

.button--mint-white {
  border: 2px solid #fff;
  color: #fff;

  &.active,
  &:hover {
    background-color: #fff;
    color: #000;
  }
}

.button--mint-gold {
    border: 2px solid #FF9509;
    color: #fff;

    &.active,
    &:hover {
        border: 2px solid white;
        background-color: white;
        color: #000;
    }
}

.button--mint-dark {
  border: none;
  background-color: #000;
  color: #fff;

  &.active,
  &:hover {
    background-color: #fff;
    color: #000;
  }
}

.button--text-gray {
  border: none;
  background-color: transparent;
  color: #7F7F7F;

  &.active,
  &:hover {
    color: #444;
    border: none;
    background-color: transparent;
  }
}

.button--play-transparent-bg-white,
.button--play-dark-bg-black,
.button--play-dark-bg-white,
.button--play-white,
.button--play {
  position: relative;
  z-index: 1;
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  margin: 10px;

  .button__arrows {
    position: absolute;
    z-index: -1;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;

    border: 1px solid rgb(255, 255, 255);
    color: #000;
    border: 1px solid;
    pointer-events: none;

    &:before,
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 50%;
      left: -1px;
      margin-top: -5px;

      border: 5px solid;
      border-right-color: transparent;
      border-top-color: transparent;
      border-bottom-color: transparent;
    }

    &:after {
      left: unset;
      right: -1px;
      transform: scaleX(-1);
    }
  }

  .button__arrows {
    transition: transform 300ms, opacity 300ms;
    transform: scale(0.9, 0.7);
    opacity: 0;
  }

  &.active,
  &:hover {
    .button__arrows {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.button--play-white {
  background-color: #fff;
  color: #000;

  .button__arrows {
    color: #fff;
    border: 1px solid;
  }
}

.button--play-dark-bg-black {
  background-color: #000;
  border: none;
  color: #fff;
  transition: background-color 300ms, color 300ms;

  .button__arrows {
    display: none;
  }

  &:hover {
      background-color: white;
      color: black;
  }
}

.button--play-dark-bg-white {
  background-color: #fff;
  border: 2px solid #000;
  color: #000;

  .button__arrows {
    color: #000;
    border: 1px solid;
  }
}

.button--play-transparent-bg-white {
    background-color: transparent;
    border: 2px solid white;

    color: white;

    .button__arrows {
        color: #000;
        border: 1px solid;
    }

    .button__arrows {
        border: 1px solid white;
        color: white;
    }
}

.button--bg-white {
    background-color: #fff;
    color: #000;
    transition: background-color 300ms, color 300ms;

    &:hover {
        background-color: #000;
        color: #fff;
    }
}

.button--gradient {
  color: #fff;
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2;
  border-image-source: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);

  &:hover {
    color: #000;
    background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
  }
}

@media screen and (max-width: ($bp_desktop - 1px)) {
  .button {
    &--mint-white {
      min-height: 40px;
      padding: 8px 20px;
    }
  }
}

</style>
