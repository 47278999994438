export default {
	namespaced: true,
    state() {
        return {
			filters: {},
			sort: {
				value: 'Latest',
				options: [
					{
						key: '-created_at',
						name: 'Latest',
					},
					{
						key: 'created_at',
						name: 'Oldest',
					},
					{
						key: '-price',
						name: 'Highest price',
					},
					{
						key: 'price',
						name: 'Lowest price',
					},
					{
						key: '-level',
						name: 'Highest level',
					},
					{
						key: 'level',
						name: 'Lowest level',
					},
				],
			}
		}
    },

	mutations: {
		setFilters(state, payload) {
			state.filters = payload;
		},
		setSort(state, payload) {
			state.sort.value = payload;
		},
	},

	getters: {
		getFilters: (state) => state.filters,
		getSort: (state) => state.sort.value,
		getSortOptions: (state) => state.sort.options?.map((element) => element.name) || [],
		getSortOrdering: (state) => state.sort.options?.find((el) => el.name === state.sort.value)?.key || undefined,
	},

    actions: {
		setFilters({commit}, payload) {
			commit('setFilters', payload);
		},
		setSort({commit}, payload) {
			commit('setSort', payload);
		},
    },
}

