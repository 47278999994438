import { SmartContract, SmartContractABI } from '..';

const contractName = 'TokenManagement';
const contractABI = new SmartContractABI();
const contractAddress = process.env.VUE_APP_TOKEN_MANAGAMENT_CONTRACT_ADDRESS;

const buyItem = {
  inputs: [
    {
      internalType: 'uint256',
      name: '_type',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_amount',
      type: 'uint256',
    },
    {
      internalType: 'bytes32',
      name: '_salt',
      type: 'bytes32',
    },
    {
      internalType: 'bytes',
      name: 'vrs',
      type: 'bytes',
    },
  ],
  name: 'buyItem',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

contractABI.add(buyItem);

const TOKEN_MANAGEMENT_CONTRACT = new SmartContract(
  contractName,
  contractABI.ABI,
  contractAddress
);

export default TOKEN_MANAGEMENT_CONTRACT;
