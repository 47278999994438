<template>
  <div class="footer">
    <!--<img src="@/assets/img/footer-rock-bg.png" alt="footer image" class="footer__bg-img">-->

    <div class="footer__top">
      <div class="footer__top-inner container">
        <logo class="footer__logo" @click="goTo('/')" />

        <div class="footer__buttons">
          <div v-if="!isLoggedIn" class="footer__buttons-item">
            <btn type="mint-white" text="CONNECT" @click="connectToMeta"/>
          </div>

        </div>

        <socials type="tiny" />
      </div>
    </div>

    <div class="footer__bottom">
      <div class="footer__bottom-inner container">
        <div class="footer__info">
          <div class="footer__info-item">
            <a href="#">Privacy Policy</a>
          </div>
          <div class="footer__info-item">
            <a href="#">Terms & Conditions</a>
          </div>
          <!-- <div class="footer__info-item">
            <a href="#">presentation</a>
          </div>
          <div class="footer__info-item">
            <a href="#">Metafighter paper</a>
          </div> -->
          <div class="footer__info-item">
            Contact us: <a href="mailto:contact@metafighter.com">Contact@Metafighter.Com</a>
          </div>
          <!-- <div class="footer__info-item">
            Ask a question: <a href="mailto:hello@metafighter.com">Hello@Metafighter.Com</a>
          </div> -->
        </div>

        <div class="footer__copy">
          All rights reserved 2022 &copy;
        </div>
      </div>
    </div>

    <MetamaskPopup ref="metamaskPopup" />
  </div>
</template>

<script>
import Logo from '@/components/common/logo.vue'
import Socials from './socials.vue'
import { mapGetters, mapActions } from 'vuex'
import headerMixin from '@/mixins/headerMixin';
import MetamaskPopup from '@/components/popups/metamask-popup.vue'

export default {
  name: "Footer",
  mixins: [headerMixin],
  components: {
    Logo,
    Socials,
    MetamaskPopup,
  },
  data() {
    return {
      links: [],
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
    ]),
  },
  methods: {
    ...mapActions(['connect', 'disconnectWallet']),

    goTo(url) {
      switch(url) {
        case '/': {
          this.$router.push({name: 'Main'}).catch(()=>{});
          break;
        }
        case 'stake': {
          window.open('https://stake.metafighter.com', '_blank')
          break;
        }
        case 'Mint nft': {
          this.$router.push({name: 'Market'}).catch(()=>{});
          break;
        }
      }
    },
  }
}
</script>

<style lang="scss">

.footer {
  margin-top: auto;
  position: relative;
  background-color: #000;
  // padding: 0 10%;
  color: #fff;
}

.footer__bg-img {
  position: absolute;
  left: 0;
  bottom: 100%;
}

.footer__top {
  border-bottom: 1px solid #333;
  padding: 10px 0;
}

.footer__top-inner {
  width: 100%;
  display: flex;
  align-items: center;
}


.footer__logo {
  margin-right: 57px;
}

.footer__buttons {
  margin-right: 47px;
  margin-left: auto;
  display: flex;
  align-items: center;

  &-item {
    margin-right: 18px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.footer__bottom {
  padding: 15px 0;
  font-size: 12px;
}

.footer__bottom-inner {
  display: flex;
  justify-content: space-between;
}

.footer__info {
  margin-left: -40px;

  display: flex;
  flex-wrap: wrap;
}

.footer__info-item {
  margin-left: 40px;
}

.footer__copy {
  margin-left: 40px;
  opacity: .4;
}

@media screen and (min-width: $bp_mb + 1px) and (max-width: 1100px) {
  .footer__logo {
    margin-right: 30px;
  }
  .footer__buttons {
    margin-right: 20px;
  }
}
@media screen and (min-width: $bp_mb + 1px) and (max-width: 1030px) {
  .footer__top-inner {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (min-width: $bp_mb + 1px) and (max-width: 860px) {
  .footer__top-inner {
    flex-direction: column;
  }
  .footer__logo {
    margin: 0;
  }
  .footer__buttons {
    margin: 20px auto;
  }
}

@media screen and (max-width: $bp_mb) {
  .footer {
    padding: 17px 0 0;

    .menu__link {
      font-size: 20px;
    }
  }
  .footer__top {
    padding: 0 0 45px;
  }
  .footer__top-inner {
    flex-direction: column;
  }

  .footer__logo {
    margin: 0;
  }

  .footer__buttons {
    margin: 25px 0 51px;
  }

  .footer__bottom {
    padding: 30px 0;
    font-size: 18px;
  }

  .footer__bottom-inner {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer__info {
    margin: 0;
    flex-direction: column-reverse;
  }
  .footer__info-item {
    margin: 0 0 30px;
  }

  .footer__copy {
    margin: 40px 0 0;
  }
}

</style>
