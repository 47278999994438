var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popup",
    {
      attrs: { "content-class": "question-popup" },
      on: { input: _vm.close },
      model: {
        value: _vm.popup,
        callback: function ($$v) {
          _vm.popup = $$v
        },
        expression: "popup",
      },
    },
    [
      _c("div", [
        _c("div", { staticClass: "question-popup__content" }, [
          _c("div", { staticClass: "question-popup__title" }, [
            _vm._v(" Are you sure? "),
          ]),
          _c("div", { staticClass: "question-popup__text" }, [
            _vm._v(
              " When requesting a withdrawal, your funds will be blocked for " +
                _vm._s(_vm.convertToTime) +
                " or until the transaction is confirmed by the blockchain. If you reject the transaction, your funds will remain blocked and you will not be able to use them in the game for " +
                _vm._s(_vm.convertToTime) +
                ". This retention period allows you to ensure the reliability of the platform and prevents fraudulent actions. "
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "question-popup__buttons" },
          [
            _c("btn", {
              staticClass: "question-popup__buttons-item",
              attrs: { type: "icon-gradient", text: "CONFIRM" },
              on: { click: _vm.confirm },
            }),
            _c("btn", {
              staticClass: "question-popup__buttons-item",
              attrs: { type: "mint-dark", text: "CANCEL" },
              on: { click: _vm.close },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }