// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.balance {\n  position: relative;\n  z-index: 0;\n  padding-right: 16px;\n  font-size: 15px;\n  color: #fff;\n  text-transform: uppercase;\n  display: flex;\n  gap: 1.5em;\n  text-shadow: 0 0 10px #000;\n}\n.balance-loader {\n  margin-top: 5px !important;\n  display: flex;\n  justify-content: center;\n}\n.balance-popover.vue-popover {\n  min-width: 210px;\n  background: #1F213A;\n  border: 2px solid #BFBFBF;\n  border-radius: 0;\n  padding: 21px 15px;\n  display: flex;\n  flex-direction: column;\n  gap: 11px;\n  color: white;\n  font-size: 14px;\n}\n.balance-label {\n  text-transform: initial;\n  color: #BFBFBF;\n  font: 400 12px \"Tektur\";\n  margin-bottom: 2px;\n}\n.balance-label--arrow {\n  display: flex;\n  gap: 0.5em;\n  align-items: center;\n}\n.balance-label--arrow:after {\n  content: \"▼\";\n  font-size: 10px;\n  margin-top: 2px;\n}\n.balance--selected {\n  display: flex;\n  flex-direction: column;\n}\n.balance:after {\n  content: \"\";\n  position: absolute;\n  z-index: -1;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  width: 2px;\n  background: linear-gradient(to top, #FF9509 45%, #FFCC13 55%);\n}\n.balance__link {\n  margin-top: 5px;\n  text-shadow: 0 0 10px #000;\n}\n.balance-btn {\n  cursor: pointer;\n}\n@media screen and (max-width: 640px) {\n.balance {\n    font-size: 20px;\n    text-align: center;\n    border: none;\n    padding: 0;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
