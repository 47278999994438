import { SmartContract, SmartContractABI } from '..';

const contractName = 'MFToken';
const contractABI = new SmartContractABI();
const contractAddress = process.env.VUE_APP_MFTOKEN_CONTRACT_ADDRESS;

const balanceOfABI = {
  inputs: [
    {
      internalType: 'address',
      name: 'account',
      type: 'address',
    },
  ],
  name: 'balanceOf',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256',
    },
  ],
  stateMutability: 'view',
  type: 'function',
  constant: true,
};

const allowanceABI = {
  inputs: [
    {
      internalType: 'address',
      name: 'owner',
      type: 'address',
    },
    {
      internalType: 'address',
      name: 'spender',
      type: 'address',
    },
  ],
  name: 'allowance',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256',
    },
  ],
  stateMutability: 'view',
  type: 'function',
};

const approveABI = {
  inputs: [
    {
      internalType: 'address',
      name: 'spender',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: 'amount',
      type: 'uint256',
    },
  ],
  name: 'approve',
  outputs: [
    {
      internalType: 'bool',
      name: '',
      type: 'bool',
    },
  ],
  stateMutability: 'nonpayable',
  type: 'function',
};

const approvalEventABI = {
  anonymous: false,
  inputs: [
    {
      indexed: true,
      internalType: 'address',
      name: 'owner',
      type: 'address',
    },
    {
      indexed: true,
      internalType: 'address',
      name: 'spender',
      type: 'address',
    },
    {
      indexed: false,
      internalType: 'uint256',
      name: 'value',
      type: 'uint256',
    },
  ],
  name: 'Approval',
  type: 'event',
};

contractABI.add(balanceOfABI);
contractABI.add(allowanceABI);
contractABI.add(approveABI);
contractABI.add(approvalEventABI);

const MF_TOKEN_CONTRACT = new SmartContract(
  contractName,
  contractABI.ABI,
  contractAddress
);

export default MF_TOKEN_CONTRACT;
