<template>
  <div
    :class="[
      'menu-btn',
      `menu-btn--${color}`,
      { opened: value }
    ]"
  >
    <div class="menu-btn__inner" @click="onClick">
      <div class="menu-btn__element"></div>
      <div class="menu-btn__element"></div>
      <div class="menu-btn__element"></div>
    </div>
  </div>
</template>

<script>
export default {
    name: "MenuBtn",
    props: {
        value: { type: Boolean, required: true },
        color: { type: String, default: 'dark' },
    },
    mounted() {
        window.addEventListener("touchstart", this.onTouchHandler);
    },
    beforeDestroy() {
        window.removeEventListener("touchstart", this.onTouchHandler);
    },
    methods: {
        onTouchHandler({target}) {
            if (!this.value) {
                return;
            }

            const isMenuTarget = target.closest('.header--is-menu-show');

            if (!isMenuTarget) {
                this.onClick();
            }
        },
        onClick() {
          this.$emit('input', !this.value)
        },
    },
}
</script>

<style lang="scss">

.menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  padding: 7px 4px;

  transition: .3s;

  &__inner {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // width: 50px;
    height: 26px;
    transition: .3s;
  }

	&__element {
		width: 32px;
		height: 3px;
		transition: .3s;
	}

  &.opened &__inner {
    justify-content: center;
    transform: rotate(45deg);
  }

  &.opened &__element {
    &:nth-of-type(1) {
      transform: translateY(100%);
    }

    &:nth-of-type(3) {
      transform-origin: center center;
      transform: translateY(-100%) rotate(90deg);
    }

    &:nth-of-type(2) {
      opacity: 0;
    }
  }
}

.menu-btn {
  &--dark &__element,
  &--dark.opened &__element {
		background-color: #fff;
  }

  &--dark,
  &--dark.opened {
    background-color: #000;
  }
}

.menu-btn {
  // &--orange {
  //   background: linear-gradient(346deg, #FF9509, #FFCC13);
  // }
  // &--orange &__element {
  //   background-color: #fff;
  // }

  &--orange.opened {}
  &--orange.opened &__element {}
}

@media screen and (max-width: ($bp_mb - 1px)) {
  .menu-btn {
    width: 28px;
    height: 28px;
    padding: 5px 3px;
  
    &__inner {
      height: 18px;
    }

    &__element {
      width: 22px;
      height: 2px;
    }
  }
}

</style>
