<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div v-if="value" class="popup">
      <div class="popup__inner">
        <i class="popup__bg" @click="close" />
        <div :class="`popup__view ${ contentClass }`">
          <close-btn class="popup__close-btn" @click="close" />
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CloseBtn from '@/components/common/close-btn.vue'
export default {
  name: "Popup",
  components: {
    CloseBtn,
  },
  props: {
    value: { type: Boolean, default: false },
    contentClass: { type: String, default: '' },
  },
  methods: {
    close(){
      this.$emit('input', false);
    }
  }
}
</script>

<style lang="scss">

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 999;

  &__inner {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px 15px;

    min-height: 100%;
  }
  &__view {
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: center;

    padding: 70px 25px 70px 25px;

    min-width: 60px;
    width: max-content;
    max-width: 100%;

    background-color: #FFFFFF;
    color: #000;

    &.error-popup {
        max-width: 75%;
        overflow: hidden;
        word-break: break-word;
    }
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;

    user-select: none;

    background-color: rgba(0,0,0,.75);

    cursor: pointer;
  }

  &__close-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  &--trailer {
    .popup__view {
      padding: 40px 20px;
      background-color: transparent;
    }

    @media screen and (max-width: ($bp_mb - 1px)) {
      .popup__close-btn {
        width: 40px;
        height: 40px;
      }

      .popup__view {
        padding: 40px 0;
      }
    }
  }
}

</style>
