<template>
    <Component
        :is="require(`../../assets/svg/${name}.svg`)"
        v-bind="$attrs"
        @v-on="$listeners"
    />
</template>

<script>
export default {
    name: "BaseIcon",
    props: {
        name: {
            type: String,
            require: true,
        }
    },
}
</script>

<style scoped>

</style>
