<template>
    <div class="menu">
        <div class="menu__list">
            <template v-if="links.length">
                <a
                    v-for="(link, i) in links"
                    :key="i" class="menu__link link"
                    :href="link.href" target="_blank"
                >
                    {{ link.label }}
                </a>
            </template>
            <template v-else>
                <template v-for="menuItem in getMenu">
                    <div
                        v-if="menuItem.children"
                        :key="menuItem.name" 
                        class="menu__link menu__link--submenu link"
                        :class="{
                            'menu__link--active': menuItem.isActive,
                            'active': getActiveClasses(menuItem),
                        }"
                        @click.stop="setActiveSubMenu(menuItem)"
                    >
                        <div class="menu__link-inner">
                            <span>{{ menuItem.label }}</span>
                            <BaseIcon :name="'arrow-down'" class="menu__submenu-icon" />
                        </div>

                        <div @click.stop class="menu__submenu">
                            <template v-for="child in menuItem.children">
                                <a
                                    v-if="child.externalUrl"
                                    :key="child.name"
                                    :href="child.externalUrl"
                                    target="_blank"
                                    class="menu__link link"
                                >
                                    {{ child.label }}
                                </a>

                                <router-link
                                    v-else
                                    :key="child.label"
                                    :to="child.path"
                                    class="menu__link link"
                                    active-class="active"
                                >
                                    {{ child.label }}
                                </router-link>
                            </template>
                        </div>
                    </div>
                    <a
                        :key="menuItem.name"
                        v-else-if="menuItem.externalUrl"
                        :href="menuItem.externalUrl"
                        target="_blank"
                        class="menu__link link"
                    >
                        {{ menuItem.name }}
                    </a>

                    <router-link
                        v-else
                        :key="menuItem.name"
                        :to="menuItem.disabled ? '' : menuItem.path"
                        @click="!menuItem.disabled && $emit('link-clicked')"
                        class="menu__link link"
                        :class="{ 'active': getActiveClasses(menuItem) }"
                        v-popover="{ name: menuItem.popover }"
                    >
                        {{ menuItem.label }}
                    </router-link>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "Menu",
    props: {
        links: { type: Array, default: () => [] },
    },
    computed: {
        getPath() {
            return this.$route.path
        },
        getMenu() {
            return this.menu.filter(item => {
                if (!item.env) return true

                return item.env.includes(process.env.NODE_ENV)
            })
        },
    },
    data() {
        return {
            isSubMenuOpen: false,
            menu: [
                {
                    name: 'Game',
                    path: '/game',
                    label: 'Game',
                },
                {
                    name: 'nft',
                    label: 'nft',
                    isActive: false,
                    children: [
                        {
                            name: 'mintNft',
                            label: 'Mint NFT',
                            path: '/marketplace/mint',
                        },
                        {
                            name: 'MyNfts',
                            label: 'My NFTs',
                            path: '/marketplace/my_nfts',
                        },
                    ],
                },
                {
                    name: 'marketplace',
                    label: 'Marketplace',
                    path: '/marketplace/shop',
                },
                {
                    name: 'stake',
                    label: 'stake',
                    isActive: false,
                    children: [
                        {
                            name: 'stakeRorEarn',
                            label: 'Stake for earn',
                            externalUrl: 'https://stake.metafighter.com'
                        },
                        {
                            name: 'stakeForPlay',
                            path: '/staking',
                            label: 'Stake for play',
                        },
                        {
                            name: 'myStake',
                            path: '/user_stakes',
                            label: 'My stake',
                        },
                    ],
                },
                {
                    name: 'buy $MF',
                    label: 'buy $MF',
                    isActive: false,
                    children: [
                        {
                            name: 'pancake',
                            label: 'Pancake',
                            externalUrl: 'https://pancakeswap.finance/swap?outputCurrency=0xBb6cDedac5CaB4A420211a4A8e8B5DCA879B31De&chain=mainnet',
                        },
                        {
                            name: 'digifinex',
                            label: 'digifinex',
                            externalUrl: 'https://www.digifinex.com/en-ww/trade/USDT/MF',
                        },
                        {
                            name: 'dextools',
                            label: 'dextools',
                            externalUrl: 'https://www.dextools.io/app/bsc/pair-explorer/0xfa529983f31a56396098b60d0347e4db61d359b8',
                        },
                        {
                            name: 'Coinmarketcap',
                            label: 'Coinmarketcap',
                            externalUrl: 'https://coinmarketcap.com/de/currencies/metafighter',
                        },
                        {
                            name: 'Coingecko',
                            label: 'Coingecko',
                            externalUrl: 'https://www.coingecko.com/de/munze/metafighter',
                        },
                        {
                            name: 'Mexc',
                            label: 'Mexc',
                            externalUrl: 'https://www.mexc.com/de-DE/exchange/MF_USDT',
                        },
                    ],
                },
                {
                    name: 'leaderboard',
                    label: 'leaderboard',
                    path: '/leaderboard/wallet',
                },
                {
                    name: 'Claim',
                    label: 'Claim',
                    path: '/claim',
                },
            ],
        }
    },
    watch: {
        isSubMenuOpen(val) {
            if (val) {
                window.addEventListener('click', this.closeSubmenu);
            } else {
                window.removeEventListener('click', this.closeSubmenu);
            }
        },
        $route() {
            this.isSubMenuOpen && this.closeSubmenu();
            this.$emit('link-clicked');
        }
    },
    methods: {
        closeSubmenu() {
            this.menu.forEach((item) => item.isActive = false);
            this.isSubMenuOpen = false;
        },
        setActiveSubMenu(menuItem) {
            if (this.isSubMenuOpen) {
                this.closeSubmenu();
            }

            menuItem.isActive = true;
            this.isSubMenuOpen = true;
        },
        getActiveClasses(menuItem) {
            return this.$route.path.startsWith(`/${menuItem.name}`);
        },
    }
}
</script>

<style lang="scss">
.menu {
    &__list {
        display: flex;
        gap: 30px;
    }

    &__submenu {
        position: absolute;
        flex-direction: column;
        gap: 0.75em;
        min-width: 175px;
        background: #1F213A;
        padding: 0.75em;
        margin-top: 10px;
        overflow: hidden;
        display: none;
        z-index: 1;
        box-shadow: 0 0 2px 0px #ffc812;

        .menu__link {
            display: block;
            margin: 0;

            &:after {
                display: none;
            }
        }
    }

    &__link-inner {
        display: flex;
        gap: 6px;
    }

    &__submenu-icon {
        width: 20px;
        height: 20px;
        transition: transform 300ms ease-in;
    }
}

.menu__link {
    text-shadow: 0 0 10px #000;

    &--active {
        .menu__submenu {
            display: flex;
        }

        &:after {
            display: none;
        }

        .menu__submenu-icon {
            transform: rotate(180deg);
        }
    }

    &:last-child {
        margin-right: 0;
    }
}

@media screen and (max-width: 1350px) {
    .menu {
        &__list {
            gap: 8px;
        }
    }
}

@media screen and (max-width: 1115px) {
    .menu {
        &__list {
            gap: 5px;
        }
    }

    .menu__link {
        &.link {
            font-size: 14px;
        }
    }
}

@media screen and (max-width: ($bp_desktop - 1px)) {
    .menu {
        &__list {
            flex-direction: column;
            align-items: center;
            gap: 23px;
        }
    }
}

@media screen and (max-width: ($bp_mb - 1px)) {
    .menu {
        &__list {
            gap: 19px;
        }
    }

    .menu__link {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
