import web3 from "web3";

export default {
  methods: {
    goToUrl(url) {
      if (this.$route.fullPath != url) {
        this.$router.push(url)
      }
    },

    goToBlank(url) {
      window.open(url, '_blank')
    },

    copyText(text) {
      const el = document.createElement('textarea')
      el.contentEditable = true // safari
      el.readOnly = false // safari
      el.value = text
      document.body.appendChild(el)

      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },

    toWei(num) {
      // eslint-disable-next-line no-undef
      return BigInt(num * 10 ** 18).toString()
    },

    getBigInt(num) {
      // eslint-disable-next-line no-undef
      return BigInt(num).toString();
    },

    fromWei(num) {
      return num / 10 ** 18
    },

    toLocaleFormat(num) {
      return Number(num).toLocaleString(undefined, {maximumFractionDigits: 2});
    },

    web3ToWei: (val) => web3.utils.toWei(String(val)),

    setCarouselItemsCount() {
      let result = 0;

      switch (true) {
        case window.innerWidth > 1560:
          result = 6;
          break;
        case window.innerWidth > 1450:
          result = 5;
          break;
        case window.innerWidth > 1350:
          result = 5;
          break;
        case window.innerWidth > 1250:
          result = 4;
          break;
        case window.innerWidth > 1000:
          result = 4;
          break;
        case window.innerWidth > 695:
          result = 3;
          break;
        case window.innerWidth > 460:
          result = 2;
          break;
        default:
          result = 1;
          break;
      }

      if (this.isAsideShown) {
        result--;
      }

      this.carouselItemsPerPage = result;
    },
  },
}
