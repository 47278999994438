<template>
  <div :class="[
    'header',
    {
      'header--is-menu-show': isMenuShow,
    }
  ]">
    <div class="header__mob container">
      <menu-btn
        v-model="isMenuShow"
        :color="'dark'"
        class="header__menu-btn"
      />

      <logo class="header__logo" @click="goTo('/')"/>

      <div class="header__buttons">
        <div v-if="!isConnectBtnShow" class="header__buttons-item header__buttons-item--icon">
            <btn
                type="button button--mint-white"
                text="Get Credits"
                @click="onClickGetMfTokens"
            >
              <BaseIcon :name="'get-credits-btn-mob'"/>
            </btn>
        </div>
        <div class="header__buttons-item header__buttons-item--icon" v-if="isConnectBtnShow">
          <btn
            type="mint-white"
            text="CONNECT"
            @click="connectToMeta"
            data-coach-mark="main-connect-btn"
          >
            <BaseIcon :name="'connect-btn-mob'"/>
          </btn>
        </div>
      </div>
    </div>

    <div class="header__inner container">
      <logo class="header__logo" @click="goTo('/')"/>

      <Menu @link-clicked="isMenuShow = false" class="header__nav" />

      <div class="header__buttons header__buttons--nav">
        <div v-if="!isConnectBtnShow" class="header__buttons-item">
            <btn
                type="button button--mint-white"
                text="Get Credits"
                @click="onClickGetMfTokens"
            />
        </div>
        <div class="header__buttons-item" v-if="isConnectBtnShow">
          <btn
            type="mint-white"
            text="CONNECT"
            @click="connectToMeta"
            data-coach-mark="main-connect-btn"
          />
        </div>
      </div>

      <balance
        v-if="canShowBalanceInDesktop"
        class="header__balance"
        @click="disconnect"
      />

      <div
          v-if="!isConnectBtnShow"
          class="header__actions"
      >
          <button class="link" @click="disconnectWallet">Disconnect</button>
          <button
              @click="unstakeAllClick"
              class="link"
              :class="{'link--unstake': canShowHeaderBg}"
          >Unstake {{getReadyForUnstake}} MF</button>
      </div>

    </div>

    <preloader translucent black v-if="isProcessing" />
    <MetamaskPopup ref="metamaskPopup" />
    <GetTokensPopup ref="getTokensPopup" />
    <unstake-success-popup ref="unstakeSuccessPopup"/>
  </div>
</template>

<script>
import Menu from '@/components/common/menu.vue'
import Logo from '@/components/common/logo.vue'
import { mapGetters, mapActions } from 'vuex'
import MenuBtn from '@/components/common/menu-btn.vue'
import Balance from '@/components/common/balance.vue'
import MetamaskPopup from '@/components/popups/metamask-popup.vue'
import GetTokensPopup from '@/components/popups/get-tokens-popup';
import stakeMixin from '@/mixins/stakeMixin';
import headerMixin from '@/mixins/headerMixin';

export default {
    name: "Header",
    mixins: [stakeMixin, headerMixin],
    components: {
        GetTokensPopup,
        Menu,
        Logo,
        MenuBtn,
        Balance,
        MetamaskPopup
    },
    props: {
        scrollY: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            isMenuShow: false,
            isLoading: true,
            message: '',
            isCheckedConnection: false,
        }
    },
    computed: {
        ...mapGetters('contract', ['readyForUnstake']),
        ...mapGetters(['GET_IS_DEVELOPMENT']),

        getReadyForUnstake() {
            return this.toLocaleFormat(Number(this.readyForUnstake).toFixed(2));
        },
        canShowHeaderBg() {
            return this.scrollY > 110 || this.getPath !== '/'
        },
        getPath() {
            return this.$route.path
        },
        canShowBalanceInMobile() {
            return !this.isLoading && this.GET_IS_MOBILE && this.isLoggedIn && this.canShowHeaderBg
        },
        canShowBalanceInDesktop() {
            return !this.isLoading && this.isLoggedIn
        },
        isConnectBtnShow() {
            return !this.isCheckedConnection || !this.isLoggedIn;
        },
    },
    watch: {
        canShowHeaderBg: {
            immediate: true,
            handler(state) {
                const app = this.$root.$el;

                app.classList[state ? 'add' : 'remove']('is-header-bg')
            },
        },
        $route(route) {
            if (route.hash === '#get-credits') {
                this.$refs.getTokensPopup.open();
            }
        }
    },
    async created() {
        await this.checkConnection();

        this.isLoading = false;
        this.isCheckedConnection = true;
    },
    methods: {
        ...mapActions([
            'checkConnection',
            'getSmartContract',
            'getMFToken',
            'connect',
            'disconnectWallet',
        ]),

        goTo(url) {
            switch(url) {
                case '/': {
                    this.$router.push({name: 'Main'}).catch(()=>{});
                    break;
                }
                case 'stake': {
                    window.open('https://stake.metafighter.com', '_blank')
                    break;
                }
                case 'Mint nft': {
                    this.$router.push({name: 'Market'}).catch(()=>{});
                    break;
                }
            }
        },

        async disconnect() {
            await this.disconnectWallet();
        },

        onClickGetMfTokens() {
          this.$refs.getTokensPopup.open();
        },
        unstakeAllClick() {
            if (Number(this.readyForUnstake) === 0) {
                return;
            }

            this.onUnstakeAllClick();
        },
    }
}
</script>

<style lang="scss">

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: linear-gradient(90deg, #000 20.16%, #04091B 53.24%);

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, #100E26 40%, #FFAE0D);
    opacity: 0;
    transition: opacity 300ms;
  }
}

// .header--bg {
//   min-height: 92px;
//   background-color: #000;
//   //border-bottom: 3px solid #333;

//   &:after {
//     opacity: 1;
//     background: #000;
//   }
// }

.header__mob {
  display: none;
}

.header__inner {
  display: flex;
  align-items: center;
}

.header__menu-btn {
  position: absolute;
  left: 16px;
  top: 20px;
  // position: relative;
  z-index: 3;
}

.header__logo {
  margin-right: 61px;
}

.header__buttons {
  margin-left: auto;

  display: flex;
  align-items: center;
  z-index: 2;

  &-item {
    margin-right: 18px;
  }
}

.header__balance {
  margin: 0 10px 0 5px;
}

.header__actions {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    .link {
        text-align: left;

        &--unstake {
            color: #ffc010;
        }

        &:after {
            display: none;
        }
    }
}

.header__buttons-item--icon {
  svg {
    display: none;
    margin-right: 0;
  }
}

@media screen and (max-width: 1820px) {
  .header__logo {
    height: 100px;
  }
}

@media screen and (max-width: 1679px) {
  .header__logo {
    height: 90px;
  }
}

@media screen and (max-width: 1439px) {
  .header__logo {
    height: 80px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 5px;
  }

  .header__buttons-item {
    .button {
      padding: 13px 5px;
      min-width: 110px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .header__buttons-item {
    margin-right: 5px;
  }
}

@media screen and (max-width: 1115px) {
  .header__logo {
    height: 60px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .header__actions {
    .link {
      font-size: 13px;
    }
  }

  .header__balance {
    padding-right: 10px;

    .balance-span {
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .header__logo {
    height: 45px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 5px;
    margin-left: 0;
  }
}

@media screen and (max-width: ($bp_desktop - 1px)) {
  .header {
    &:after {
      background: #000;
      opacity: 0;
    }
  }

  .header__logo {
    height: 80px;
    margin: 0;
  }

  .header__mob {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header .header__inner {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    // height: 100vh;
    flex-direction: column;
    padding: 0 15px 35px;

    background: linear-gradient(90deg, #000 20.16%, #04091B 53.24%);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);

    transition: 300ms;
  }

  .header--is-menu-show {
    .header__inner {
      opacity: 1;
      visibility: visible;
      transform: none;
      pointer-events: auto;

      // .header__buttons {
      //   position: absolute;
      // }
    }
  }

  .header__nav {
    padding-top: 25px;
  }

  .header__buttons {
    position: absolute;
    top: 20px;
    right: 16px;
    margin: 0 0 0;

    &-item {
        margin: 0;
    }

    &:empty {
      display: none;
    }
  }

  .header__buttons-item {
    .button {
      padding: 8px 13px;
      min-width: 122px;
    }
  }

  .header__actions {
      padding-top: 20px;
      gap: 10px;

      .link {
          font-size: 15px;
          text-align: center;
      }
  }

  .header__balance {
    padding: 0;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 25px;

    .balance-label {
      justify-content: center;
      gap: 6px;
      margin-bottom: 10px;
      font-size: 14px;
      color: #fff;
    }

    .balance-span {
      font-size: 15px;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: unset;
      left: 50%;
      right: unset;
      bottom: 0;
      width: 60px;
      height: 2px;
      background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
      transform: translateX(-50%);
    }
  }
}

@media screen and (max-width: ($bp_mb - 1px)) {
  .header__logo {
    height: 60px;
  }

  .header__buttons {
    top: 15px;
    right: 20px;

    &--nav {
      position: static;
      z-index: 0;
    }
  }

  .header__menu-btn {
    left: 20px;
    top: 16px;
  }

  .header__nav {
    padding-top: 20px;
    margin-bottom: 25px;
  }

  .header__buttons-item--icon {
    .button {
      min-width: unset;
      min-height: 30px;
      padding: 0 7px;
    }

    svg {
      display: flex;
    }

    .button__content {
      display: none;
    }
  }
}

</style>
