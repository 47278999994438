var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["menu-btn", `menu-btn--${_vm.color}`, { opened: _vm.value }] },
    [
      _c(
        "div",
        { staticClass: "menu-btn__inner", on: { click: _vm.onClick } },
        [
          _c("div", { staticClass: "menu-btn__element" }),
          _c("div", { staticClass: "menu-btn__element" }),
          _c("div", { staticClass: "menu-btn__element" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }